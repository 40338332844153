<template>
    <v-container>
        <v-row>
            <v-breadcrumbs  divider="-">
                <v-breadcrumbs-item href="home">&lt; terug naar Home</v-breadcrumbs-item>
            </v-breadcrumbs>
        </v-row>
        <v-row> 
            <v-col cols="5">
                <v-card-text v-if="errorMessage">
                    {{errorMessage}}
                </v-card-text>
                </v-col>
                <v-col class="mt-8">
                <v-progress-circular
                    v-if="loading"
                    indeterminate
                    color="primary">
                </v-progress-circular>
            </v-col>
        </v-row>
        <v-row shaped sticky tile>
            <h1 class="ml-3">Je hebt gezocht op: "{{searchCriteria}}"</h1>
        </v-row>
        <v-row justify="center" class="mb-4">
            <v-col v-if="publishedBooks.books && !publishedBooks.totalCount">
                <v-card>
                    <v-card-title class="pb-8">
                        <v-row justify="center">
                            <v-img src="../assets/img/nothingfound.jpg" max-width="300"></v-img>
                        </v-row>
                    </v-card-title>
                    <v-card-title class="pa-9">
                        Helaas, we kunnen geen boeken vinden met deze zoekterm. Probeer het eventueel hierboven opnieuw.
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col cols="12" md="6" v-for="(publishedBook, index) in publishedBooks.books" :key="index">
                <v-card>
                    <v-row class="ma-1">
                        <v-col cols="12" md="3">
                            <v-img
                                :src="publishedBook.imageFileName"
                                max-width="150">
                            </v-img>
                        </v-col>
                        <v-col>
                            <v-row class="ma-0">
                                <v-col class="pa-0" cols="3">
                                    <label class="font-weight-bold">Naam:</label>
                                </v-col>
                                <v-col class="ma-0 pa-0">
                                        {{publishedBook.title}}
                                </v-col>      
                            </v-row>
                            <v-row class="ma-0">
                                <v-col class="ma-0 pa-0" cols="3">
                                    <label class="font-weight-bold">Auteur:</label>
                                </v-col>
                                <v-col class="ma-0 pa-0">
                                    {{publishedBook.author}}
                                </v-col>
                            </v-row>
                            <v-row class="ma-0">
                                <v-col class="ma-0 pa-0" cols="3">
                                    <label class="font-weight-bold">ISBN:</label>
                                </v-col>
                                <v-col class="ma-0 pa-0">
                                    {{publishedBook.isbn}}
                                </v-col>
                                
                            </v-row>
                            <v-row class="ma-0">
                                <v-col class="ma-0 pa-0" cols="3">
                                    <label class="font-weight-bold">Druk:</label>
                                </v-col>
                                <v-col class="ma-0 pa-0">
                                    {{publishedBook.edition}}
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" class="pr-2" >
                            <v-row justify="end">
                                <v-card-actions cols="3" class="ma-6">
                                    <v-btn color="primary" @click="showPBDetails(publishedBook)">
                                            BEKIJK HET AANBOD
                                    </v-btn>
                                </v-card-actions>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-pagination
                v-if="totalPages >=0"
                style="padding-bottom: 3%; padding-top: 3%"
                v-model="pageNumber"
                :length="totalPages"
                @input="next"
                :total-visible="4">
                </v-pagination>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PublishedBookService from "../services/PublishedBookService"

export default {
    props:['searchCriteria'],
    data: ()=>({
        publishedBooks:[],
        pageNumber: 1,
        totalPages:0,
        loading: false,
        pageSize: 10,
        errorMessage: null,
    }),
    async created(){
        await this.search();
    },
    methods:{
        ...mapGetters(['getNavigationSearch']),
        ...mapActions(['saveNavigationSearchValue']),
        ...mapActions(['saveStateOfPublishedBookOverview']),
        showPBDetails(publishedBook){
            this.saveNavigationSearchValue(publishedBook);
            this.saveStateOfPublishedBookOverview({publishedBook:publishedBook});
            this.$router.push({name:'PublishedBookOverview', query:{publishedbook:publishedBook.id}});
        },
        async search(){
            try{
                this.errorMessage = null;
                this.loading = true;
                
                let result = await PublishedBookService.searchPublishedBook(this.pageNumber, this.pageSize, this.searchCriteria);
                this.publishedBooks = result;
                this.totalCount = result.totalCount;
                this.totalPages = Math.round((this.totalCount / this.pageSize) + 1);
                
            } catch {
                this.errorMessage = 'Es is helaas een fout opgetreden.';
            }

            this.loading = false;
        },
        async next(p) {
            window.scrollTo(0,0);
             await this.search();
        },
    }
}
</script>