<template>
    <v-container>
        <v-row class="pt-md-4">
            <v-col class="pt-md-0" cols="5">
                <v-card flat class="py-0">
                    <v-card-text class="py-md-0">
                        <v-text-field id="discountNumberId" v-model="discountNumber" :error-messages="errorMessage" @keyup="resetPage()" dense placeholder="Kortingscode"></v-text-field>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col class="pt-md-0" cols="1">
                <v-card flat class="py-md-0">
                    <v-card-text class="mt-md-2 pl-md-0 py-md-0">
                        <v-btn id="discountNumberApplyButtonId" color="secondary" x-small class="black--text" @click="validateDiscount()">
                            <v-icon> mdi-chevron-right</v-icon>
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="6">
                <v-card flat class="py-md-0" v-if="totalDiscount != null">
                    <v-card-text class="pl-md-4 py-md-2 green--text">
                        <v-row>
                            Je korting is: 
                            <div id="discountAppliedFixedAmountId" v-if="typeOfDiscount === 'Fixed'">€</div>
                            {{totalDiscount}}
                            <div id="discountAppliedPercentageId" v-if="typeOfDiscount === 'Percentage'">%</div>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import { mapGetters } from 'vuex'
import DiscountService from '../services/DiscountService'

export default {
    data () {
        return {
            discountNumber: '',
            errorMessage: null,
            discount: null,
            totalDiscount: null,
            number: null,
            typeOfDiscount: '',
        }
    },
    methods: {
        ...mapGetters(['getAccessToken']),
        async validateDiscount(){
            this.errorMessage = null;
            this.totalDiscount = null;
            this.discountId = null;

            let result = await DiscountService.getDiscount(this.getAccessToken(), this.discountNumber, 1); // 1 is checkout
            this.receivedResult = result;
            if(result !== null){
                if(result.fixedAmount === null && result.percentage === null){
                    this.errorMessage = 'Kortingscode niet gevonden.';
                    this.receivedResult = null;
                    this.$emit('discount', { number: null, totalDiscount: 0.0});
                } else {
                    if(this.isPercentageFilled(result)){
                        this.errorMessage = '';
                        this.totalDiscount = result.percentage.percentage;
                        this.number = result.percentage.uniqueNumber;
                        this.typeOfDiscount = "Percentage";
                    }
                    
                    if(this.isFixedAmountFilled(result)){
                        this.errorMessage = '';
                        this.totalDiscount = result.fixedAmount.discount;
                        this.number = result.fixedAmount.uniqueNumber;
                        this.typeOfDiscount = "Fixed";
                    } 
                    
                    if(this.totalDiscount == null){
                        this.errorMessage = 'Kortingscode niet gevonden.';
                    }

                    this.$emit('discount', { number: this.number, totalDiscount: this.totalDiscount, typeOfDiscount: this.typeOfDiscount});
                }
            } else {
                this.receivedResult = null;
                this.$emit('discount', {number: null, totalDiscount: 0.0});
            }
        },

        discountEvent(){
            this.$emit();
        },
        
        isFixedAmountFilled(result){
            if(result != null){
                if(result.fixedAmount != null){
                   return true;
                }
            }
            return false;
        },

        isPercentageFilled(result){
            if(result != null){
                if(result.percentage != null){
                    return true;
                }
            }
            return false;
        },
        
        resetPage(){
            this.errorMessage = null;
        }
    }
}
</script>