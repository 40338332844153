<template>
    <v-container class="py-0">
        <v-row class="mt-3">
            <v-col class="py-0" cols="12" md="8">
                <v-row>
                    <v-col cols="12">
                        <v-card outlined flat class="mb-4 pb-6" width="100%">
                            <v-row v-if="publishedBook != null && publishedBook.title != null"> 
                                <v-col class="pb-0" cols="4" md="4">
                                    <v-card-text class="pb-0">
                                        Titel:
                                    </v-card-text>
                                </v-col>
                                <v-col class="pb-0">
                                    <v-card-text class="pb-0">
                                        {{publishedBook.title}}
                                    </v-card-text>
                                </v-col>
                            </v-row>
                            <v-row v-if="publishedBook != null && publishedBook.author != null">
                                <v-col class="py-0" cols="4" md="4">
                                    <v-card-text  class="py-0">
                                        Auteur:
                                    </v-card-text>
                                </v-col>
                                <v-col class="py-0">
                                    <v-card-text class="py-0" >
                                        {{publishedBook.author}}
                                    </v-card-text>
                                </v-col>
                            </v-row>
                             <v-row v-if="publishedBook != null && publishedBook.edition != null">
                                <v-col class="py-0" cols="4" md="4">
                                    <v-card-text  class="py-0">
                                        Druk:
                                    </v-card-text>
                                </v-col>
                                <v-col class="py-0">
                                    <v-card-text class="py-0" >
                                         {{publishedBook.edition}}
                                    </v-card-text>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col cols="12" class="my-0 py-0" >
                        <v-row class="py-0" >  
                            <v-col class="py-0" cols="12" md="6">
                                <v-select 
                                    class="my-0 py-0"
                                    :items="sellOptions"
                                    v-model="bookState"
                                    :item-text="'value'"
                                    :item-value="'id'"
                                    dense
                                    ref="sellRef"
                                    label="Verkopen"
                                    outlined>
                                </v-select>
                            </v-col>
                            <v-col cols="6" class="mb-0 pb-0">
                                <v-menu class="my-0 py-0"
                                    v-if="bookState == 2"
                                    v-model="menuReadyForSaleDate"
                                    :close-on-content-click="true"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            class="my-0 py-0"
                                            v-model="book.readyForSaleDate"
                                            label="Vanaf datum:"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            outlined
                                            dense
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker 
                                        v-model="book.readyForSaleDate" 
                                        no-title 
                                        dense
                                        scrollable
                                        @input="menuReadyForSaleDate = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row class="my-0 py-0">
                            <v-col class="py-0" cols="12" md="6">
                                <v-select 
                                    id="myBookSettingConditionId"
                                    :items="conditions"
                                    v-model="book.condition"
                                    :item-text="'option'"
                                    dense
                                    ref="stateRef"
                                    :item-value="'id'"
                                    label="Staat" outlined>
                                </v-select> 
                            </v-col>
                            <v-col cols="12" md="6" class="mb-0 py-0"> 
                                <v-text-field 
                                    id="myBookSettingPriceId"
                                    outlined
                                    v-model="book.price"
                                    name="input"
                                    dense
                                    :rules="[rules.required, rules.number]"
                                    ref="priceRef"
                                    placeholder="bijv. 23,99"
                                    label="Prijs in €"
                                    class="py-0 mb-0">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="my-0 py-0">
                            <v-col class="my-0 py-0">
                                <v-textarea
                                    id="myBookSettingOwnersNoteId" 
                                    outlined
                                    v-model="book.ownersNote"
                                    name="input"
                                    dense
                                    label="Omschrijving">
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
            
            <v-col class="py-0" cols="12" md="4">
                <v-row class="pb-0 mt-0">
                        <v-col class="mt-0 ml-0 pl-0 pt-0">
                            <v-card flat class="mt-n3">
                                <v-col class="mt-0 pt-md-3">
                                    <vue-upload-multiple-image
                                            idUpload="image-step2"
                                            @upload-success="uploadImageSuccess"
                                            @before-remove="beforeRemove"
                                            @edit-image="editImage"
                                            :data-images="images"
                                            dragText="Nieuwe foto"
                                            browseText="toevoegen"
                                            primaryText="Hoofdfoto"
                                            @mark-is-primary="markAsPrimary"
                                            markIsPrimaryText="Instellen als hoofdfoto"
                                            popupText="Afbeeldingen van je boek">
                                    </vue-upload-multiple-image>
                                </v-col>
                            </v-card>
                        </v-col>
                    </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="pl-0" cols="12" md="6">
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-btn
                                :disabled="loading"
                                :loading="loading"
                                block
                                @click="cancel()">
                                TERUG
                            </v-btn>
                        </v-col>      
                        <v-col cols="12" md="6">
                            <v-btn
                                id="uploadMyBookId"
                                color="primary" 
                                :disabled="!formValid || loading"
                                :loading="loading"
                                block
                                @click="uploadBook">
                                UPLOAD MIJN BOEK
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3" md="12"></v-col>
                        <v-col>
                            <h4 class="red--text" v-if="errorMessage">{{errorMessage}}</h4>
                        </v-col>
                    </v-row>             
                </v-col>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import BookService from "../../services/BookService"
import VueUploadMultipleImage from 'vue-upload-multiple-image'
import { mapGetters } from 'vuex'

  export default {
    props:['publishedBook'],
    data () {
      return {
        purchaseData: '',
        book:{},
        imagePreview: null,
        isbn: '',
        images: [],
        imagesFormData: [],
        loading: false,
        errorMessage: null,
        publishedBookNotFound: false,
        conditions: [
            { id: 0, option: 'Zo goed als nieuw' },
            { id: 1, option: 'Goed' },
            { id: 2, option: 'Redelijk' },
        ],
        deliveryOptions: [
            { id: 0, option: 'Verzenden' },
        ],
        sellOptions: [
            { id: 0, value: 'Ja' },
            { id: 1, value: 'Nee' },
            { id: 2, value: 'Vanaf datum:' },
        ],
        bookState: 0,
        menuAvailableFrom: false,
        menuReadyForSaleDate: false,
        rules: {
          required: value => !!value || 'Vereist',
          min: value => !!value && (value <= 0.0) || 'Mag niet lager dan 0',
          number: value => {
                const regExp = /^-?\d*([.,]\d+)?$/;
                return !!value && regExp.test(value) || 'Moet een bedrag zijn';
            },
        },
      }
    },
    created() {
        this.book = { };
        this.book.readyForSaleDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
        this.book.deliveryOption=0;
        this.book.state = this.bookState;
    },
    computed: {
        formValid() {

            return (this.book.condition != null &&
                this.book.readyForSaleDate &&
                this.book.deliveryOption != null&&
                this.sellOptions != null &&
                this.book.price != null &&
                this.publishedBook.id != null &&
                this.$refs.sellRef.valid && 
                this.$refs.stateRef.valid && 
                this.$refs.priceRef.valid);
        },
    },
    watch:{
        isbn:function(val){
            this.findBookByIsbn(val);
        },

        bookState:function(val){
            this.book.state = val;
        },
    },
    
    methods: {
        ...mapGetters(['getAccessToken']),
        ...mapGetters(['hasValidAddress']),
        async uploadImageSuccess(formData, index, fileList) {
            this.images = fileList;
            this.imagesFormData = formData;
        },

        beforeRemove (index, done, fileList) {
            done();
            this.images = fileList;
        },
        editImage (formData, index, fileList) {
            this.images = fileList;
        },

        foundPublishedBook(event){
            this.book.publishedBookId = event.id;
            this.publishedBook = event.publishedBook;
        },

        markAsPrimary(index, fileList) {
            this.images = fileList;
        },
        async uploadImages(book){
            for(const image of this.images){
                await BookService.addImage(this.getAccessToken(), book.id, image.path);
            }
        },    
        async uploadBook() {
            try
            {
                this.errorMessage = null;
                this.loading = true;
                this.book.publishedBookId = this.publishedBook.id;
                let book = await BookService.addBook(this.getAccessToken(), this.book);
                if(book){
                    this.resetVariables();
                    await this.uploadImages(book);
                }
                this.$emit('bookUploaded');
            }
            catch{
                this.errorMessage = 'Uploaden is mislukt.';
            }

            this.loading = false;
        },

        cancel(){
            this.resetVariables();
            this.$emit('cancel');
        },

        resetVariables(){
            this.book = {};
            this.imagePreview = null;
            this.isbn = '';
            this.images= [],
            this.imagesFormData= [],
            this.loading = false;
            this.book.deliveryOption=0;
            this.book.readyForSaleDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
        }
    },
    components: {
        VueUploadMultipleImage,
    }
  }
</script>