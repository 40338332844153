<template>
    <v-card class="pt-4">
       <v-row justify="end">
            <v-card-actions>
                <v-btn text @click="closeButton(true)" >
                    <v-icon>
                        mdi-close
                    </v-icon>
                </v-btn>
            </v-card-actions>
        </v-row>
        <v-row justify="center">
             <v-col cols="10">
                <h1 class="h4">{{selectedJob.name}}</h1>
             </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="11" md="10">
                <v-row class="py-0 my-0">
                    <v-col cols="12" md="6" class="py-0 my-0">
                        <v-text-field placeholder="Naam" v-model="name" :rules="[rules.required, rules.min2]"></v-text-field>
                    </v-col>
                    <v-col class="py-0 my-0">
                        <v-text-field placeholder="Achternaam" v-model="surname" :rules="[rules.required, rules.min2]"></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="py-0 my-0">
                    <v-col class="py-0 my-0" cols="12" md="6">
                        <v-text-field placeholder="Email" v-model="email" :rules="[rules.required, rules.email]"></v-text-field>
                    </v-col>
                    <v-col class="py-0 my-0">
                        <v-text-field placeholder="Telefoonnumer" v-model="phone" :rules="[rules.required, rules.phone]"></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="py-0 my-0">
                    <v-col cols="12" md="6">
                        <v-menu
                            class="pb-0 mb-0"
                            v-model="menuDateFrom"
                            :close-on-content-click="true"
                            transition="scale-transition"
                            offset-y
                            min-width="auto">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="dateFrom"
                                    label="Beschikbaar vanaf"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    outlined
                                    dense
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker 
                                v-model="dateFrom" 
                                no-title 
                                dense
                                scrollable>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                     <v-col class="pb-0 mb-0">
                        <v-menu class="pb-0 mb-0"
                            v-model="menuDateTo"
                            :close-on-content-click="true"
                            transition="scale-transition"
                            offset-y
                            min-width="auto">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="dateTo"
                                    label="Beschikbaar tot"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    outlined
                                    dense
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker 
                                v-model="dateTo" 
                                no-title 
                                dense
                                scrollable>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
                <v-row class="py-0 my-0">
                    <v-col cols="12" md="6" class="py-0 my-0">
                        <v-text-field class="py-0 my-0" placeholder="Aantal uren per week" v-model="hoursPerWeek" :rules="[rules.required, rules.hoursPerWeek]"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-textarea v-model="coverLetter" outlined placeholder="Motivatie" :rules="[rules.required]" ></v-textarea>
                    </v-col>
                </v-row>
                
                <v-row class="py-0 my-0">
                    <v-col class="py-0 my-0">
                        <v-file-input
                            accept="application/pdf"
                            truncate-length="15" 
                            show-size 
                            v-model="file"
                            @change="onFileSelected"
                            :rules="[rules.required]"
                            placeholder="Upload je cv (maximaal 2 MB)">
                        </v-file-input>
                    </v-col>
                </v-row>
                
                <v-row justify="end" class="mr-0 mb-4">
                    <p class="mr-4 mt-1 red--text" v-if="showErrorMessage">Helaas is er een fout opgetreden</p>
                    <v-btn class="py-0 my-0" @click="apply()" :loading="loading" :disabled="!formValid || loading" color="primary">INDIENEN</v-btn>
                </v-row>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import JobService from '../services/JobService';

export default {
    props:['selectedJob'],
    data(){
        return {
            loading:false,
            name: '',
            surname: '',
            hoursPerWeek: '',
            email: '',
            phone: '',
            coverLetter: '',
            menuDateTo: false,
            dateTo: null, 
            menuDateFrom: false,
            dateFrom: null,
            showErrorMessage:false, 
            phoneRegex: /^(\+31|0|0031)\d{9}$/,
            emailRegex: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
            hoursPerWeekRegex: /^(?:[0-9]|[1-3][0-9]|40)$/,
            rules: {
                required: v => !!v || 'Verplicht.',
                min8: v => v?.length >= 8 || 'Ten minste 8 tekens',
                phone: v => !v || this.phoneRegex.test(v) || 'Telefoonnummer is niet geldig',
                min2: v => v?.length >= 2 || 'Ten minste 2 tekens',
                email: v => !v || this.emailRegex.test(v) || 'E-mail is niet geldig',
                hoursPerWeek: v => !v || this.hoursPerWeekRegex.test(v) || 'Aantal uren moet tussen de 1 en 40 zijn'
            },
            file: null,
            base64: null,
        }
    },

    computed: {
        formValid(){
            return (
                this.name && this.name?.length >= 2 &&
                this.surname && this.surname?.length >= 2 &&
                this.email &&
                this.phone &&
                this.hoursPerWeek &&
                this.coverLetter &&
                this.file &&
                this.hoursPerWeekRegex.test(this.hoursPerWeek) &&
                this.emailRegex.test(this.email) && 
                this.phoneRegex.test(this.phone));
        }
    },

    created(){
        let student = this.getStudent();
        if(student){
            this.name = student.firstName;
            this.surname = student.lastName;
            this.email = student.email;
            this.phone = student.phone;
        }
    },

    methods: {
        ...mapGetters(['getAccessToken']),
        ...mapGetters(['getStudent']),
        closeButton(success){
            this.$emit('closeDialog', success);
        },

        onFileSelected() {
            this.createBase64Image(this.file);
        },

        createBase64Image(file) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.base64 = e.target.result;
            };
            reader.readAsDataURL(file);
        },

        async apply() {
            this.loading = true;
            this.showErrorMessage = false;

            if (this.file && this.base64) {
                try {
                    await JobService.applyToJob(
                    this.getAccessToken(), 
                    this.selectedJob.id, 
                    this.name, 
                    this.surname, 
                    this.email,
                    this.phone, 
                    this.coverLetter, 
                    this.dateFrom,
                    this.dateTo,
                    this.hoursPerWeek,
                    this.base64);

                    this.closeButton();

                } catch(ex) {
                    this.loading = false;
                    this.showErrorMessage = true;
                }
            }

            this.loading = false;
        },
    },
}
</script>