<template>
    <v-container>
        <v-row justify="center" align="center" class="mb-4">
            <v-col cols="10" md="6">
                <v-card flat>
                    <v-row justify="center" align="center">
                        <v-col cols="4">
                            <v-img max-width="250" src="../assets/img/happy.svg"></v-img>
                        </v-col>
                    </v-row>
                    <v-row justify="center" align="center">
                        <v-col cols="10">
                            <p>Je registratie is gelukt. Check de inbox van je e-mail. Activeer je account, login en je kunt aan de slag!
                            We wensen je veel plezier op ons platform.</p>
                            <p>Heb je nog geen e-mail ontvangen? Check de spambox van je e-mail account.</p>
                            Ga naar de <router-link to="/login">login</router-link> pagina.
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data: () => ({
       
    }),
}
</script>