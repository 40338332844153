<template>
    <v-form class="mb-4">
        <v-row justify="center">
            <v-col cols="11" md="4">
                <v-card v-if="isResultAvailable" class="pb-4" justify="center">
                    <v-row justify="center">
                        <v-card-title>Je wachtwoord resetten</v-card-title>
                        <v-card-text>{{resultText}}</v-card-text>
                        <v-card-text>Terug naar <router-link class="mx-1" to="/">Home</router-link></v-card-text>
                     </v-row>
                </v-card>
                 <v-card v-if="!isResultAvailable" class="pb-4" justify="center">
                    <v-row justify="center">
                        <v-card-title>Je wachtwoord resetten</v-card-title>
                        <v-col class="text-h2" cols="10" md="10">
                            <v-text-field v-model="password"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required, rules.min]"
                            :type="showPassword ? 'text' : 'password'"
                            name="input-10-2"
                            label="Wachtwoord"
                            hint="Ten minste 8 tekens"
                            outlined
                            @click:append="showPassword = !showPassword">
                            </v-text-field>
                            <v-text-field v-model="confirmPassword"
                            :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required, rules.min, passwordConfirmationRule]"
                            :type="showConfirmPassword ? 'text' : 'password'"
                            label="Bevestig wachtwoord"
                            hint="Ten minste 8 tekens"
                            outlined
                            @click:append="showConfirmPassword = !showConfirmPassword">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="mx-6 mb-4">       
                        <v-col cols="12">
                            <v-btn
                                    block
                                    color="primary"
                                    :loading="loading"
                                    :disabled="!formValid || loading"
                                    @click="resetPassword()">
                                    RESET WACHTWOORD
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-form>
</template>
<script>

import AccountService from "../services/AccountService"
export default {

    data () {
      return {
        showPassword: false,
        showConfirmPassword: false,
        loading: false,
        rules: {
          required: value => !!value || 'Required.',
          min: v => v != null && v.length >= 8 || 'Minimaal 8 tekens',
        },
        confirmPassword: null,
        password: null,
        resultAvailable: false,
        resultText: ""
      }
    },
    computed:{
        formValid() {
            return (this.password && this.confirmPassword &&
            (this.password === this.confirmPassword));
        },
        passwordConfirmationRule() {
            return () => (this.password === this.confirmPassword) || 'Wachtwoorden moeten overeenkomen';
        },
        isResultAvailable(){
            return this.resultAvailable;
        }
    },
    methods:{
        async resetPassword() {
            try{
                this.loading = true;
                this.resultText = "";
                let result = await AccountService.resetPassword(this.$route.query.email, encodeURIComponent(this.$route.query.token), this.password);
                this.resultAvailable = true;
                if(result && result.result === "1"){
                    this.resultText = "Je wachtwoord is succesvol gewijzigd.";
                } else {
                    this.resultText = "Er is iets misgegaan met het wijzigen van je wachtwoord.";
                }
            }
            catch{
                this.resultText = "Er is iets misgegaan met het wijzigen van je wachtwoord.";
            }
            this.loading = false;
        }
    }
}
</script>
