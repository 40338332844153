<template>
    <v-container>
        <v-card flat height="550">
            <v-card-title primary-title class="justify-center title">
                <h2>Registreren</h2>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text> 
                <v-form class="mb-2">
                    <v-row justify="center">
                        <v-col class="text-h2 pb-0 pb-0" cols="10">
                            <!-- <v-text-field 
                                id="nameId"
                                v-model="student.firstName"
                                :rules="[rules.required]"
                                label="Voornaam"
                                placeholder="Voornaam"
                                dense
                                outlined>
                            </v-text-field>
                            <v-text-field
                                id="surnameId"
                                v-model="student.lastName"
                                :rules="[rules.required]"
                                label="Achternaam"
                                dense
                                placeholder="Achternaam"
                                outlined>
                            </v-text-field>
                            <v-autocomplete
                                id="universityId"
                                v-model="selecteduniversity"
                                :items="universities"
                                :item-text="'name'"
                                label="Onderwijsinstituut"
                                return-object
                                dense
                                outlined>
                            </v-autocomplete> 
                            <v-autocomplete
                                id="studyId"
                                v-model="selectedStudy"
                                dense
                                :items="studies"
                                :item-text="'name'"
                                label="Studie"
                                return-object
                                outlined>
                            </v-autocomplete>   -->
                            <v-text-field
                                id="usernameId"
                                v-model="student.userName"
                                :rules="[rules.required, rules.minUserName, rules.userNameContainsNoSpaces, rules.userNameUnique]"
                                label="Gebruikersnaam"
                                dense
                                ref="userNameRef"
                                placeholder="Gebruikersnaam"
                                :error-messages="userNameErrorMessage"
                                outlined>
                            </v-text-field>
                            <v-text-field 
                                id="emailId"
                                v-model="student.email"
                                :rules="[rules.required, rules.email]"
                                label="E-mail"
                                ref="emailRef"
                                @keyup="emailExists()"
                                :error-messages="emailErrorMessage"
                                dense
                                placeholder="Email"
                                outlined>
                            </v-text-field>
                            <v-text-field
                                id="passwordId" 
                                v-model="student.password"
                                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[rules.required, rules.minPassword, rules.numeric, rules.uppercase]"
                                :type="showPassword ? 'text' : 'password'"
                                name="input-10-2"
                                dense
                                label="Wachtwoord"
                                hint="Ten minste 8 tekens"
                                outlined
                                @click:append="showPassword = !showPassword">
                            </v-text-field>
                            <v-text-field
                                id="passwordVerifyId"
                                v-model="confirmPassword"
                                :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[rules.required, rules.minPassword, passwordConfirmationRule]"
                                :type="showConfirmPassword ? 'text' : 'password'"
                                label="Bevestig wachtwoord"
                                hint="Ten minste 8 tekens"
                                outlined
                                dense
                                @click:append="showConfirmPassword = !showConfirmPassword">
                            </v-text-field>
                            <v-checkbox
                                id="legalTermId"
                                dense
                                class="mt-0 pt-0"
                                v-model="legalTerm"
                                :label="`Ik ga akkoord met de algemene voorwaarden.`">
                            </v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        <v-col cols="10">
                            <v-btn
                                id="registerId"
                                block
                                :loading="loading"
                                color="primary"
                                :disabled="!formValid || loading"
                                @click="register">
                                JOIN DE COMMUNITY
                            </v-btn>
                        </v-col>
                        <v-col cols="10">
                            <router-link to="/" tag="span">
                                <v-btn
                                    id="cancelRegistrationId"
                                    block
                                    color="primary"
                                    @click="cancel()"
                                    outlined>
                                    CANCEL
                                </v-btn>
                            </router-link>  
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="2" md="3"></v-col>
                        <v-col>
                            <h4 class="red--text ml-3" v-if="errorMessage">{{errorMessage}}</h4>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import uploadPicture from "../assets/img/upload-picture.png"
import UniversityService from "../services/UniversityService"
import CourseService from "../services/CourseService"
import StudentService from "../services/StudentService"
import AccountService from '../services/AccountService'
import ConversationService from '../services/ConversationService'

import { mapActions, mapGetters } from 'vuex'

export default {
    data () {
      return {
        student: {},
        universities: [],
        studies: [],
        selecteduniversity: null,
        selectedStudy:null,
        menu: false,
        showPassword: false,
        showConfirmPassword: false,
        loading:false,
        errorMessage: null,
        defaultImagePreview: uploadPicture,
        userNameErrorMessage: null,
        emailErrorMessage: null,
        email: null,
        createSubscription: false,
        rules: {
          required: value => !!value || 'Vereist.',
          minPassword: v => v && v.length >= 8 || 'Minimaal 8 tekens',
          minUserName: v => v && v.length >= 5 || 'Minimaal 5 tekens',
          userNameContainsNoSpaces: v => v && /^[a-zA-Z0-9.\-_$@*!]{3,30}$/.test(v) || 'Gebruikersnaam mag geen spatie bevatten',
          userNameUnique: v => v && this.userNameExists(),
          emailUnique: v => v && this.emailExists(),
          uppercase: v => v && /^(?=.*[A-Z]).*$/m.test(v) || 'Gebruik minimaal één hoofdletter',
          numeric: v => v && /^(?=.*[0-9])/.test(v) || 'Gebruik minimaal één cijfer',
          email: v => v && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail moet geldig zijn'
        },
        legalTerm: false,
        confirmPassword: null
      }
    },
    created() {
        this.student = {
            firstName: '',
            lastName: '',
            phone: '',
            unversityId: null,
            courseId: null,

            userName: '',
            email: '',
            password: '',
        };
          
        this.loadUniversities();
        this.loadCourses();
    },
    
    computed: {
        passwordConfirmationRule() {
            return () => (this.student.password === this.confirmPassword) || 'Wachtwoorden moeten overeenkomen'
        },
        imagePreview() {
            if (!this.student.photo) return uploadPicture;
            return URL.createObjectURL(this.student.photo);
        },

        formValid() {

            let result = (
            //this.student.firstName &&
            //this.student.lastName &&
            this.student.password &&
            this.student.password.length >= 8 &&
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.student.email) &&
            /^[a-zA-Z0-9.\-_$@*!]{3,30}$/.test(this.student.userName) &&
            /^(?=.*[A-Z]).*$/m.test(this.student.password) &&
            /^(?=.*[0-9])/.test(this.student.password) && 
            this.$refs.userNameRef.valid &&
            this.legalTerm);
            
            return result;
        },
    },
    methods: {
        ...mapActions(['setSuccessfulLogin']),
        ...mapActions(['setStudentsAccount']),
        ...mapActions(['saveNumberOfUnreadMessages']),
        ...mapGetters(['getAccountOfStudent']),
        ...mapGetters(['getAccessToken']),
        ...mapGetters(['getNumberofUnreadMessages']),
        async loadUniversities() {
            this.universities = await UniversityService.getUniversities();
        },

        async loadCourses() {
            this.studies = await CourseService.getCourses();
        },
        
        async emailExists() {
            this.emailErrorMessage = null;
            if( this.student != null && 
                this.student.email != null && 
                this.$refs.emailRef.valid){
                
                let result = await AccountService.emailExists(this.student.email);
                if(result.exists) this.emailErrorMessage = 'E-mail is al in gebruik.';
                this.$refs.emailRef.valid = !result.exists;
                return result.exists;
            } 
            this.$refs.emailRef.valid = false;
            return true;
        },

        async userNameExists(){
            this.userNameErrorMessage = null;

            if(this.student != null && 
                this.student.userName != null &&
                this.student.userName.length >= 5){
                
                let result = await AccountService.userNameExists(this.student.userName);
                if(result.exists) this.userNameErrorMessage = 'Gebruikersnaam is al in gebruik.';

                this.$refs.userNameRef.valid = !result.exists;
            } else{
                if(this.student.userName.length >= 5){
                    this.$refs.userNameRef.valid = false; 
                }
            }

            return true;
        },
      
        async register() {
            this.loading = true;

            try{
            
            var response = await AccountService.register(
                this.student?.firstName, 
                this.student?.lastName,
                this.student.userName,
                this.student.email,
                this.student.password,
                this.selecteduniversity?.id,
                this.selectedStudy?.id);

                if(response){
                    let result = await StudentService.sendActivationAccountEmail(
                    this.student.email, 
                    this.student.firstName, 
                    this.student.lastName, 
                    this.student.userName);

                    let authentication = await AccountService.loginUser(this.student.userName, this.student.password);
                    if(authentication.accessToken != null){
                        this.setSuccessfulLogin(authentication);
                        let student = await AccountService.getAccountOfStudent(authentication.accessToken);
                        this.setStudentsAccount(student);

                        let result = await ConversationService.getNumberofUnreadMessages(authentication.accessToken);
                        this.saveNumberOfUnreadMessages(result.totalUnreadMessages);
                        if (this.$navigationHistory.historyLength >= 2) {
                            this.$router.go(-2);
                        } else if (this.$navigationHistory.historyLength === 1) {
                            this.$router.go(-1);
                        } else {
                            if (this.$router.currentRoute.path !== '/') {
                            this.$router.push('/');
                            }
                        }
                    } else{
                        this.errorMessage = "Inloggen is mislukt.";
                    }
                }         
            }
            catch{
                this.loading = false;
                this.errorMessage = "Helaas is het registreren niet gelukt."
            }
        },
        cancel(){
            this.$emit('onCancel');
        }
    },
    template: '',
    components: {
    }
  }
</script>