import axios from "../plugins/axios";

export default {
    async getCourses() {
        let courses;
        await axios.get(`${process.env.VUE_APP_BOOKSHARE_URL}/courses`)
                .then(response =>
                    courses = response.data.courses);
        return courses;
    }
}
