import axios from "../plugins/axios";

export default {
    async getLanguages() {
        let languages;
        await axios.get(`${process.env.VUE_APP_BOOKSHARE_URL}/languages`)
                .then(response =>
                    languages = response.data.languages);
        return languages;
    }
}
