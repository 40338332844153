<template>
    <v-container>
        <v-row>
            <v-col cols="3" class="hidden-sm-and-down">
                <UserMenuCard></UserMenuCard>
            </v-col>
   
            <v-col>
              <template>

                  <v-card v-if="loading">
                    <div class="loadingData d-flex justify-center mb-6">
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </div>
                  </v-card>
                  
                  <v-card outlined v-if="!loading">
                      <div v-if="studentBooks != null && studentBooks.length > 0">
                        <div v-for="item in studentBooks" :key="item.value">
                            <BookDetail :studentBook="item" :key="componentKey" :searched="true"/>
                        </div>
                        </div>
                        <div v-if="studentBooks != null && studentBooks.length == 0">
                            <v-card-text>
                            <div>Er zijn geen boeken gevonden.</div>
                            </v-card-text>
                        </div>

                        <v-pagination
                          style="padding-bottom: 3%; padding-top: 3%"
                          v-model="pageNumber"
                          :length="totalPages"
                          @input="next"
                          :total-visible="7">
                      </v-pagination>
                    </v-card>
                </template>
              </v-col>
        </v-row>
    </v-container>
</template>

<script>
import UserMenuCard from '../components/UserMenuCard.vue';
import BookDetail from '../components/BookDetails.vue';
import PublishedBookService from "../services/PublishedBookService"

export default {
    props: {
        studentBook: {
            type: Object,
            default: (()=>{})
        },
    },
    data () {
      return {
        pageNumber: 1,
        totalPages: 0,
        pageSize: 20,
        searchedCriteria: '',
        studentBooks: [
            {  },
            {  },
            {  }
        ],
        page: 1,
        loading: true
      }
    },
    async created() {
        const params = this.$route.params;
        await this.loadSearchedStudentBooks(params.searchedCriteria);
    },
     methods: {
        async loadSearchedStudentBooks(search) {
            const booksResponse = await PublishedBookService.searchPublishedBook(1, this.pageSize, search);
            this.searchedCriteria = search;
            this.studentBooks = booksResponse.books;
            this.totalPages = Number(booksResponse.totalCount / this.pageSize) + 1;
            this.forceRerender();
            this.loading = false;
        },
        async next(page) {
            this.loading = true;
            const booksResponse = await PublishedBookService.searchPublishedBook(page, this.pageSize, this.searchedCriteria);
            this.studentBooks = booksResponse.books;
            this.totalPages = Number(booksResponse.totalCount / this.pageSize) + 1;
            this.forceRerender();
            this.loading = false;
        },
        async getStudentBooks() {
          await this.loadStudentBooks();
          this.forceRerender();
        },
        forceRerender() {
          // This is necessary to force the child component (StudentBookDetail) to re-render
          // the component, in order to display the image correctly (https://michaelnthiessen.com/force-re-render/);
            this.componentKey += 1;
        }
    },
    components: {
        UserMenuCard,
        BookDetail
    },
  }
</script>

<style scoped>
.loadingData {
  min-height: 200px;
  padding-top: 9%;
}
</style>