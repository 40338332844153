import axios from "../plugins/axios";

const baseUrl = `${process.env.VUE_APP_BOOKSHARE_URL}/books/`;

export default {

    async addImage(token, bookId, imageData) {
        let booksResponse;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        let data = {
          "bookId":  bookId,
          "imageData": imageData
        }

        await axios.post(`${baseUrl}add-image`, data, config)
                .then(response =>
                  booksResponse = response.data);

        return booksResponse;
    },

    async getBooksOverview(token, pageNumber, pageSize) {      
      let booksResponse;
      const config = {
          headers: { Authorization: `Bearer ${token}` }
      };

      let data = {
        "pageNumber":  pageNumber,
        "pageSize":  pageSize,
      }

      await axios.post(`${baseUrl}admin/booksoverview`, data, config)
              .then(response =>
                booksResponse = response.data);

      return booksResponse;
    },

    async removeImage(token, bookId, imageId) {
      let booksResponse;
      const config = {
          headers: { Authorization: `Bearer ${token}` }
      };

      let data = {
        "bookId":  bookId,
        "imageId": imageId
      }

      await axios.post(`${baseUrl}remove-image`, data, config)
              .then(response =>
                booksResponse = response.data);

      return booksResponse;
    },

    async claimBook(token, bookId) {
        let booksResponse;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        let data = {
          "bookId":  Number(bookId)
        }
        await axios.post(`${baseUrl}claim`, data, config)
                .then(response =>
                  booksResponse = response.data);

        return booksResponse;
    },

    async addBook(token, book) {
      book.price = book.price.replace(',', '.');
      
      let bookResponse;
      const config = {
          headers: { Authorization: `Bearer ${token}` }
      };
      let data = {
        "book":  book
      }
      await axios.post(`${baseUrl}add`, data, config)
              .then(response =>
                bookResponse = response.data.book);

      return bookResponse;
  },

  async editBook(token, book) {
    let bookRequest = {};
    bookRequest.bookId = book.id;
    bookRequest.purchasedSince = book.purchasedSince;
    bookRequest.readyForSaleDate = book.readyForSaleDate;
    bookRequest.ownersNote = book.ownersNote;
    bookRequest.condition = book.condition;
    bookRequest.deliveryOption = book.deliveryOption;
    bookRequest.sellOption = book.sellOption;
    bookRequest.price = book.price.replace(',', '.');

    let booksResponse;
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let data = {
      "book":  bookRequest
    }

    await axios.post(`${baseUrl}edit`, data, config)
            .then(response =>
              booksResponse = response.data);

    return booksResponse;
  },

  async releaseBook(token, bookId) {
    let booksResponse;
      const config = {
          headers: { Authorization: `Bearer ${token}` }
      };
      let data = {
        "bookId":  Number(bookId)
      }
      await axios.post(`${baseUrl}release`, data, config)
              .then(response =>
                booksResponse = response.data);

      return booksResponse;
  },

  async getBooks(token, pageNumber, pageSize) {
      let booksResponse;
      const config = {
          headers: { Authorization: `Bearer ${token}` }
      };
      let data = {
        "pageNumber": pageNumber,
        "pageSize": pageSize
      }
      await axios.post(`${baseUrl}books`, data, config)
              .then(response =>
                booksResponse = response.data);

      return booksResponse;
  },
  
  async getBooksOfPublishedBook(token, publishedBookId, pageNumber, pageSize) {
    let booksResponse;
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    let data = {
      "publishedBookId": publishedBookId,
      "pageNumber": pageNumber,
      "pageSize": pageSize
    }
    await axios.post(`${baseUrl}books-of-published-book`, data)
            .then(response =>
              booksResponse = response.data.books);

    return booksResponse;
  },
  async getBooksOfStudent(token, studentId, pageNumber, pageSize, sellingBooksOnly) {
    let booksResponse;
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    let data = {
      "studentId": studentId,
      "pageNumber": pageNumber,
      "pageSize": pageSize,
      "sellingBooksOnly": sellingBooksOnly
    }
    await axios.post(`${baseUrl}books-of-student`, data)
            .then(response =>
              booksResponse = response.data.books);
    return booksResponse;
  },

  async removeBook(token, bookId){
    let result;
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    let data={bookId:bookId};
    await axios.post(`${baseUrl}remove`, data,config)
            .then(response =>
              result = response.data);
    return result;
  },
}
