<template>
   <v-form>
        <v-container> 
            <v-row>
                <v-col cols="3" class="hidden-md-and-down">
                    <UserMenuCard></UserMenuCard>   
                </v-col>
                <v-col>
                    <v-tabs center-active>
                        <v-tab ref="#advertisementoverview">Overzicht</v-tab>
                        <v-tab ref="#addadvertisement">Teoevoegen</v-tab>
                        <v-tab-item key="advertisementoverview">
                          <AdvertisementOverviewComponent></AdvertisementOverviewComponent>
                        </v-tab-item>
                         <v-tab-item key="addadvertisement">
                            <AddAdvertisementComponent></AddAdvertisementComponent>
                        </v-tab-item>
                        <v-tab-item key="topsold">
                            
                        </v-tab-item>
                        <v-tab-item key="users">
                            
                        </v-tab-item>
                    </v-tabs>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
import { mapGetters } from 'vuex';
import UserMenuCard from '../../../components/UserMenuCard.vue';
import AddAdvertisementComponent from '../../../components/admin/AddAdvertisementComponent.vue'
import AdvertisementOverviewComponent from '../../../components/admin/AdvertisementOverviewComponent.vue'

export default {
    data () {
      return {
        
      }
    },
    
    methods:{
        ...mapGetters(['getAccessToken']),
    },
    components:{
        UserMenuCard,
        AddAdvertisementComponent,
        AdvertisementOverviewComponent
    },
}
</script>