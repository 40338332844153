<template>
    <div>
        <v-row class="my-4" justify="center" >
           <h1 class="pt-4 mx-8 text-md-h3 font-weight-bold mb-3 title-bookshare title-description">
                <div class="primary--text">Kies het lidmaatschap dat bij je past</div>
            </h1>
        </v-row>
        <v-row class="pa-6">
            <v-dialog
                v-model="showPaymentScreen"
                transition="dialog-bottom-transition"
                max-width="400">
                <template>
                    <v-card>
                        <v-card-text>
                            <v-row class="mt-4">
                                <v-col cols="12">
                                    <p class="mt-4 text-h6">Super tof dat je gekozen hebt om een Boekshare lidmaatschap te nemen.</p>
                                </v-col>
                            </v-row>
                            <v-row justify="center">
                                <v-img max-width="200" src="../assets/img/happy.svg"></v-img>
                            </v-row>
                            <v-row>
                                <v-col cols="8" class="py-0">
                                Gekozen:
                                </v-col>
                                <v-col class="py-0">
                                {{selectedSubscription.name}}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="8" class="py-0">
                                Prijs:
                                </v-col>
                                <v-col class="py-0">
                                €{{selectedSubscription.price}}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="8" class="py-0">
                                Betaling:
                                </v-col>
                                <v-col class="py-0">
                                iDeal
                                </v-col>
                            </v-row>
                        
                            <v-row v-if="discountAmount && discountAmount !== '0.0'">
                                <v-col class="py-0 red--text" cols="8">
                                Korting:
                                </v-col>
                                <v-col class="py-0 red--text">
                                €{{discountAmount}}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-divider></v-divider>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="8" class="py-0">
                                <span class="font-weight-bold">Totaal:</span>
                                </v-col>
                                <v-col class="py-0">
                                <span class="font-weight-bold">€{{(parseFloat(selectedSubscription.price) - parseFloat(discountAmount)).toFixed(2)}}</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="py-0">
                                    <v-checkbox v-model="legalTerm" :label="`Ik ga akkoord met de algemene voorwaarden.`"></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="pa-0">
                                    <v-col class="py-0">
                                        <v-text-field
                                        class="mr-n4" 
                                        v-model="discountNumber"  
                                        label="Kortingscode" 
                                        dense 
                                        outlined></v-text-field>
                                    </v-col>
                                    <v-col class="py-0 mt-n4">
                                        <v-btn 
                                        block 
                                        color="secondary" 
                                        @click="getDiscount()">
                                        TOEVOEGEN
                                        </v-btn>
                                    </v-col>
                                    <v-card-text class="red--text" v-if="errorMessageDiscount">
                                        {{errorMessageDiscount}}
                                    </v-card-text>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions class="justify-end py-6">
                            <v-row justify="center">
                                <v-col cols="10" md="9">
                                    <v-btn class="mr-4" @click="showPaymentScreen = false">
                                        ANNULEREN
                                    </v-btn>
                                    <v-btn 
                                    color="primary" 
                                    :loading="loading" 
                                    :disabled="!formValid" 
                                    @click="paySubscription()">
                                        <div v-if="selectedSubscription.id != 6">BETALEN</div>
                                        <div v-if="selectedSubscription.id === 6">VERDER</div>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </template>
            </v-dialog>
            <v-row class="mx-md-8 ma-2">
                <v-row justify="center">
                    <v-col md="3" cols="12">
                        <v-card flat class="rounded-tl-xl" outlined>
                            <v-row justify="center">
                                <v-img src="../assets/img/sub_header_3.svg" class="mt-4 mb-3" max-width="320"></v-img>
                            </v-row>
                             <v-card-title>
                                <v-row class="ml-5">
                                    Gratis
                                </v-row>
                            </v-card-title>
                            <v-card-text>
                                 <v-list disabled>
                                    <v-list-item-group>
                                        <v-list-item>
                                            <v-list-item-icon>
                                                <v-icon>mdi-magnify</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                Onbeperkt zoeken
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-icon>
                                                <v-icon>mdi-message-arrow-right-outline</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                Maximaal 5 berichten versturen (per maand)
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-icon>
                                                <v-icon>mdi-message-check-outline</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                               Onbeperkt berichten openen
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-icon>
                                                <v-icon>mdi-repeat</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                Wordt niet automatisch verlengd
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-icon>
                                                <v-icon>mdi-cash</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                10% administratiekosten
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col md="3" cols="12">
                        <v-card class="rounded-tl-xl" flat outlined style="border: 2px solid #644fab;">
                        <v-row justify="center">
                                <v-img src="../assets/img/sub_header_2.svg" class="mt-4" max-width="320"></v-img>
                            </v-row>
                            <v-card-title>
                                <v-row class="ml-5 mt-1">
                                    Jaar<div class="mx-3">€9,99</div>
                                </v-row>
                            </v-card-title>
                            <v-card-text>
                                <v-list disabled>
                                    <v-list-item-group>
                                        <v-list-item>
                                            <v-list-item-icon>
                                                <v-icon>mdi-magnify</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                Onbeperkt zoeken
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item class="mt-md-n1">
                                            <v-list-item-icon>
                                                <v-icon>mdi-message-arrow-right-outline</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                Maximaal 25 berichten versturen (per maand)
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item class="mt-md-n1">
                                            <v-list-item-icon>
                                                <v-icon>mdi-message-check-outline</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                               Onbeperkt berichten openen
                                            </v-list-item-content>
                                        </v-list-item>
                                         <v-list-item>
                                            <v-list-item-icon>
                                                <v-icon>mdi-repeat</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                Wordt niet automatisch verlengd
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-icon>
                                                <v-icon>mdi-cash</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                Geen administratiekosten
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                                <v-row justify="end">
                                    <v-card-actions>
                                        <v-btn v-if="!this.hasValidSubscription()" class="mt-4" color="primary" @click="selectSubscription(4,'Jaar', '9.99')">
                                            WORD LID
                                        </v-btn>
                                        <router-link to="/Account">
                                            <v-btn v-if="this.hasValidSubscription()" class="mt-4" color="primary">
                                                <div>Mijn lidmaatschap</div>
                                            </v-btn>
                                        </router-link>
                                    </v-card-actions>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col md="3" cols="12">
                        <v-card flat class="rounded-tl-xl" outlined style="border: 2px solid #644fab;">
                            <v-row justify="center">
                                <v-img src="../assets/img/sub_header_4.svg" class="mt-4 mb-3" max-width="320"></v-img>
                            </v-row>
                             <v-card-title>
                                <v-row class="ml-5">
                                    Studententijd<div class="mx-3">€29,99</div>
                                </v-row>
                            </v-card-title>
                            <v-card-text>
                                 <v-list disabled>
                                    <v-list-item-group>
                                        <v-list-item>
                                            <v-list-item-icon>
                                                <v-icon>mdi-magnify</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                Onbeperkt zoeken
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item class="mb-md-2">
                                            <v-list-item-icon>
                                                <v-icon>mdi-message-arrow-right-outline</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                Onbeperkt berichten versturen
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-icon>
                                                <v-icon>mdi-message-check-outline</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                               Onbeperkt berichten openen
                                            </v-list-item-content>
                                        </v-list-item>
                                         <v-list-item>
                                            <v-list-item-icon>
                                                <v-icon>mdi-repeat</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                Wordt niet automatisch verlengd
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-icon>
                                                <v-icon>mdi-cash</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                Geen administratiekosten
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                                <v-row justify="end">
                                    <v-card-actions>
                                        <v-btn v-if="!this.hasValidSubscription()" class="mt-lg-4 mt-xl-1" color="primary" @click="selectSubscription(2,'Studententijd', '29.99')">
                                            <div>WORD LID</div>
                                        </v-btn>
                                        <router-link to="/Account">
                                            <v-btn v-if="this.hasValidSubscription()" class="mt-lg-4 mt-xl-1" color="primary">
                                                <div>Mijn lidmaatschap</div>
                                            </v-btn>
                                        </router-link>
                                    </v-card-actions>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>            
                </v-row>
            </v-row>
        </v-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SubscriptionService from '../services/SubscriptionService'
import DiscountService from '../services/DiscountService'

export default {
    data () {
      return {
        showPaymentScreen: false,
        legalTerm: false,
        selectedSubscription:[],
        discountNumber:'',
        discountAmount:'0.0',
        loading: false,
        resultMessage: null,
        errorMessageDiscount:null,
        rules: {
          required: value => !!value || 'Required.',
        },
      }
    },
    props: {
        studentBook: {
            type: Object,
            default: (()=>{})
        },
        searched: Boolean,
    },
    computed:{
        formValid() {
            return (this.legalTerm);
        }
    },
    methods: {
         ...mapGetters(['getAccessToken']),
         ...mapGetters(['isUserLoggedIn']),
         ...mapGetters(['hasValidSubscription']),
         selectSubscription(id, name, price){
            if(this.isUserLoggedIn()){
                if(!this.hasValidSubscription()){
                    this.selectedSubscription = {id:id, name:name, price:price};
                    this.showPaymentScreen = true;
                }
            } else{
                this.$router.push("/login");
            }
         },
         paySubscription(){
             this.loading = true;
             try
             {
                if(this.selectedSubscription){
                    this.register(this.selectedSubscription.id);
                }
             }
             catch{
                this.resultMessage = 'Er is een fout opgetreden.'; 
             }

             this.loading = false;
         },
        isiPhoneDevice(){
            var ua = window.navigator.userAgent;
            // var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
            // var webkit = !!ua.match(/WebKit/i);
            // var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
            return (window.navigator.vendor && window.navigator.vendor.indexOf('Apple') > -1 &&
                   window.navigator.userAgent &&
                   window.navigator.userAgent.indexOf('CriOS') == -1 &&
                   window.navigator.userAgent.indexOf('FxiOS') == -1);
            //return isSafari;
        },
         async getDiscount(){
            var result = await DiscountService.getDiscount(this.getAccessToken(), this.discountNumber);
            if(result){
                if(result.fixedAmount){
                    this.discountAmount = result.fixedAmount.discount;
                    this.errorMessageDiscount = null; 
                    return;
                } 
                else if(result.precentage){
                    this.discountAmount = ((this.selectedSubscription.price/100.00) * parseFloat(result.precentage.precentage)).toFixed(2);
                    this.errorMessageDiscount = null;
                    return;
                } 
            }
            this.errorMessageDiscount = 'De ingevoerde kortingscode is helaas niet geldig.';
            this.discountAmount = '0.0';
            
         },
         
         register(registrationSubscriptionType) {
             if(this.isiPhoneDevice()) {
                var windowReference = window.open();
                if(this.isUserLoggedIn()){
                    SubscriptionService.register(this.getAccessToken(), registrationSubscriptionType, this.discountNumber).then((subscription)=>{
                    if(subscription){
                        if(subscription.price > 0.0){
                                this.showPaymentScreen = false;
                                SubscriptionService.pay(this.getAccessToken(), subscription.id).then((payment)=>{
                                
                                const url = payment.checkoutLink;
                                setTimeout(function(){ // async
                                    windowReference.location = url;
                                }, 200);  
                            });
                        }
                        else{
                            this.$router.push("/account");
                        }
                        
                    }});
                }
                else{
                    this.$router.push("/login");
                }
             }
             else {
                  if(this.isUserLoggedIn()){
                    SubscriptionService.register(this.getAccessToken(), registrationSubscriptionType, this.discountNumber).then((subscription)=>{
                        if(subscription){
                            this.showPaymentScreen = false;
                            if(subscription.price > 0.0){
                                    SubscriptionService.pay(this.getAccessToken(), subscription.id).then((payment)=>{
                                    if(this.isiPhoneDevice()) {
                                        var url = payment.checkoutLink;
                                        setTimeout(function() {
                                            window.open(url, "_self");
                                        },500);
                                    } else{
                                        window.open(payment.checkoutLink, "_self");
                                    }
                                })
                            }
                            else{
                                this.$router.push("/account");
                            }
                        };
                    })
                }
            }
        }
    },
  }
</script>

<style scoped>
.title-card {
    font-size: 25px;
}
.text-subscription {
    color: black;
    min-height: 75px;
}
.subscription-card {
    min-height: 300px;
}
@media(min-width:1362px) and (max-width:1553px) {
   .row-subscription {
      padding-left: 1%;
      padding-right: 1%;
      padding-bottom: 1%;
   }
   .subscription-card {
        min-height: 320px;
    }
}
@media(max-width:434px) {
    .main-title {
        font-size: 28px !important;
    }
}
@media(min-width:690px){
   .row-subscription {
      padding-left: 4%;
      padding-right: 4%;
      padding-bottom: 5%;
   }
}
.money-text {
    font-size: 35px;
    vertical-align: -webkit-baseline-middle;
}
.lower-size {
    font-size: 19px;
}
.value-subscription {
    min-height: 70px;
}
@media(min-width: 270px) and (max-width:349px) {
    .main-title {
        font-size: 20px !important;
    }
    .title-card {
        font-size: 19px !important;
        font-weight: bold;
    }
}
@media(min-width: 1px) and (max-width:269px) {
    .main-title {
        font-size: 15px !important;
    }
    .title-card {
        font-size: 15px !important;
    }
}
.coins-label {
    padding-top: 10%;
}
</style>