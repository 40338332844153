<template>
    <v-row justify="center" class="mb-4">
        <v-card class="pa-4" max-width="600">
             <div v-if="statusLoad === 0">
                <v-card-text>
                    <v-row justify="center" class="my-4">
                        <v-img src="../assets/img/Loading.png" max-width="400"></v-img>
                    </v-row>
                </v-card-text>     
                <v-card-text>Neem wat drinken terwijl we je betaling verwerken.</v-card-text>
                <v-card-text>Voor het vinden van je studieboeken kun je naar de <router-link class="mx-1" to="/">home</router-link> pagina.</v-card-text>
            </div>
            <div v-if="statusLoad==1">
                <v-card-text>
                    <v-row justify="center" class="my-4">
                        <v-img src="../assets/img/party.jpg" max-width="400"></v-img>
                    </v-row>
                </v-card-text>
                <v-card-title>Hi {{accountName}},</v-card-title>
                <v-card-text id="successfulPayment">Je betaling is successvol afgerond. We gaan direct aan de slag met je bestelling. Je ontvangt van ons per e-mail meer informatie over je bestelling.
                    Ga naar je <router-link class="mx-1" to="/account">account</router-link> pagina om de status van je bestelling te bekijken.</v-card-text>
                <v-card-text></v-card-text>
            </div>
            <div v-if="statusLoad==2">
                <v-card-text>
                    <v-row justify="center" class="my-4">
                        <v-img src="../assets/img/questions.jpg" max-width="400"></v-img>
                    </v-row>
                </v-card-text>
                <v-card-title>Hi {{accountName}},</v-card-title>
                <v-card-text id="unsuccessfulPayment">Je betaling is helaas niet gelukt. Probeer het opnieuw
                    en als het nog steeds niet lukt, neem dan contact met ons op.</v-card-text>
                <v-card-actions><v-btn class="primary" @click="pay">BETALEN</v-btn></v-card-actions>
            </div>
        </v-card>
    </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CheckoutService from "../services/CheckoutService"
import AccountService from "../services/AccountService"

 export default {
    props:['checkoutId', 'studentId'],
    data: () => ({
        accountName:'',
        paymentResult: '',
        statusLoad: 1
    }),
    created() {
        this.handlePayment();
    },
     methods: {
         ...mapGetters(['getAccessToken']),
         ...mapActions(['emptyCart']),
          ...mapActions(['setStudentsAccount']),
         async handlePayment(){
             this.statusLoad = 0;
             let result=[];

            try{
                if(this.checkoutId && this.studentId){
                    result = await CheckoutService.update(this.studentId, this.checkoutId);
                } else{
                    result = await CheckoutService.update(this.$route.query.account, this.$route.query.checkout);
                }
                if(result){
                    this.accountName = result.accountName;
                    this.paymentResult = result.status;
                    if(this.paymentResult === "paid"){
                        this.statusLoad = 1;
                        this.emptyCart();
                        let student = await AccountService.getAccountOfStudent(this.getAccessToken());
                        this.setStudentsAccount(student);
                    }
                    else {
                        this.statusLoad = 2;
                    }
                } else{
                    this.statusLoad = 2;
                } 
            }
            catch {
                this.statusLoad = 2;
            }
         },
         async pay(){
            this.statusLoad = 0;
            let result = await CheckoutService.pay(this.getAccessToken(), this.$route.query.checkout);
            if(this.isiPhoneDevice()) {
                var url = result.checkout.payment.checkoutLink;
                setTimeout(function() {
                    window.open(url, "_self");
                },500);  
            } else {
                window.open(result.checkout.payment.checkoutLink,"_self");
            }
         },
         
        isiPhoneDevice(){
            var ua = window.navigator.userAgent;
            // var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
            // var webkit = !!ua.match(/WebKit/i);
            // var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
            return (window.navigator.vendor && window.navigator.vendor.indexOf('Apple') > -1 &&
                   window.navigator.userAgent &&
                   window.navigator.userAgent.indexOf('CriOS') == -1 &&
                   window.navigator.userAgent.indexOf('FxiOS') == -1);
            //return isSafari;
        },
    },
  }
</script>