import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueLazyload from 'vue-lazyload'
import axios from './plugins/axios';
import VueGtag from "vue-gtag";
import Vue2Editor from "vue2-editor";

Vue.use(VueGtag, {
  config: { id: "G-YDB6GNM60R" },
  Vue2Editor
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  VueLazyload,
  axios,
  render: (h) => h(App),
}).$mount("#app");

