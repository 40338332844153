<template>
     <v-container>
        <v-row>
            <v-col class="mt-3">
                <v-row>
                    <v-col cols="12" md="4">
                         <v-menu
                            v-model="menuAdvertisementFromDate"
                            :close-on-content-click="true"
                            transition="scale-transition"
                            offset-y
                            min-width="auto">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="dateFrom"
                                    label="Startdatum"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    outlined
                                    v-on="on"
                                    dense
                                ></v-text-field>
                            </template>
                            <v-date-picker 
                                v-model="dateFrom" 
                                no-title 
                                scrollable
                                dense
                                @input="menuAdvertisementFromDate = false;">
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" md="4">
                         <v-menu
                            v-model="menuAdvertisementToDate"
                            :close-on-content-click="true"
                            transition="scale-transition"
                            offset-y
                            min-width="auto">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="dateTo"
                                    label="Startdatum"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    outlined
                                    v-on="on"
                                    dense
                                ></v-text-field>
                            </template>
                            <v-date-picker 
                                v-model="dateTo" 
                                no-title 
                                scrollable
                                dense
                                @input="menuAdvertisementToDate = false;">
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="4">
                         <v-btn color="primary" @click="load()">
                            LADEN
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row v-if="advertisements && advertisements.length > 0">
                    <v-card width="100%" class="ma-4 pb-2" v-for="(advertisement, index) in advertisements" :key="index">
                        <v-card-title>{{advertisement.title}}</v-card-title>
                        <v-card-subtitle class="py-2">{{advertisement.subtitle}}</v-card-subtitle>
                        <v-card-subtitle class="py-2">Klant: {{advertisement.customerName}}</v-card-subtitle>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" md="3" class="ma-0 py-0">
                                    {{advertisement.redirectUrl}}
                                </v-col>
                                <v-col cols="12" md="3" class="ma-0 py-0">
                                    Views: {{advertisement.numberOfViews}}
                                </v-col>
                                 <v-col cols="12" md="3" class="ma-0 py-0">
                                    Clicks: {{advertisement.numberOfClicks}}
                                </v-col>
                                <v-col cols="12" md="3" class="ma-0 py-0">
                                    Active: {{advertisement.active}}
                                </v-col>
                            </v-row>
                            <v-row v-if="images.find((element) => element.id === advertisement.id)">
                                <v-col>
                                     <v-img
                                        contain 
                                        :src="images.find((element) => element.id === advertisement.id).data"
                                        max-width="450"/>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-row>
            </v-col>
        </v-row>
         <v-row justify="center" v-if="errorMessage || loading"> 
            <v-col cols="6" v-if="errorMessage">
                <v-card-text>
                {{errorMessage}}
                </v-card-text>
            </v-col>
            <v-col class="my-8" cols="6">
                <v-progress-circular
                v-if="loading"
                indeterminate
                color="primary">
                </v-progress-circular>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="3"></v-col>
            <v-col>
                <v-row>
                    <v-pagination
                        v-if="totalPages >0"
                        style="padding-bottom: 3%; padding-top: 3%"
                        v-model="pageNumber"
                        :length="totalPages"
                        @input="load()"
                        :total-visible="7">
                    </v-pagination>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import AdvertisementService from '../../services/AdvertisementService';
import upload from "../../assets/img/upload.png"

export default {
    data () {
      return {
        loading: false,
        pageSize: 5,
        pageNumber: 1,
        totalPages:0,
        dateFrom: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        dateTo: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menuDateFrom:false,
        menuDateTo:false,
        tab: null,
        advertisements: [],
        menuAdvertisementFromDate: false,
        menuAdvertisementToDate: false,
        imagePreview: null,
        errorMessage: null,
        images:[{}]
      }
    },
    methods:{
        ...mapGetters(['getAccessToken']),
        async load(){
           try{
                this.loading = true;
                let data = await AdvertisementService.getAdminAdvertisements(this.getAccessToken(), this.dateFrom, this.dateTo, this.pageNumber, this.pageSize);
                this.advertisements = data.advertisements;
                this.totalPages = Math.ceil((data.totalCount /this.pageSize));
                this.loading = false;

                this.advertisements.forEach(async advertisement => {
                    await this.loadImage(advertisement);
                });

           }catch{}
           this.loading = false;
        },
        async loadImage(advertisement){
            if(advertisement.image){
                const base64Response = await fetch(advertisement.image);
                const blob = await base64Response.blob();
                let imageData = URL.createObjectURL(blob);
                advertisement.imageData = imageData;
                this.images.push({id:advertisement.id, data:imageData});
            } else {
                let imageData = upload;
                advertisement.imageData = imageData;
                this.images.push({id:advertisement.id, data:imageData});
            } 
        }
    },
}
</script>