import axios from "../plugins/axios";

const baseUrl = `${process.env.VUE_APP_BOOKSHARE_URL}/reviews/`;

export default {

    async getRatings(token) {
        let result;

        const config = {
        headers: { Authorization: `Bearer ${token}` }
        };

        let data = {
        }

        await axios.post(`${baseUrl}ratings`, data, config)
            .then(response =>
                result = response.data);

        return result;
    },

    async giveReview(token, 
                    transaction, 
                    ratings, 
                    additionalInformation) {
        let result;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        let givenRatings = [];
        ratings.forEach(r => {
            givenRatings.push({ratingId:r.id, givenNumber:r.givenNumber})
        });

        let data = {
            transactionId:transaction,
            givenRatings:givenRatings,
            additionalInformation:additionalInformation,
        }
        
        await axios.post(`${baseUrl}give-review`, data, config)
                .then(response =>
                    result = response.data);

        return result;
    },

    async getReviewsOfSeller(sellerId) {
        let result;
       
        let data = {
            sellerId:sellerId,
        }

        await axios.post(`${baseUrl}get-reviews-of-seller`, data)
            .then(response =>
                result = response.data);

        return result;
    },
}
