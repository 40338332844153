<template>
   <v-form>
        <v-container> 
            <v-row>
                <v-col class="mt-3">
                     <v-row>
                         <v-col>
                            <h3>UPDATE</h3>
                         </v-col>
                    </v-row>
                    <v-row>
                         <v-col>
                            <v-btn id="updateShipmentButtonId" @click="updateShipments()" :loading="loading" color="primary">
                                Update Status
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
   </v-form>
</template>

<script>
import ShipmentService from '../../services/ShipmentService';
import { mapGetters } from 'vuex';

export default {
    data () {
      return {
        loading: false,
        shipments:[],
      }
    },
    methods:{
        ...mapGetters(['getAccessToken']),
        async updateShipments() {
            this.loading = true;
            try{
                await ShipmentService.updateOpenShipments(this.getAccessToken());
            } catch{

            }
            this.loading = false;
        }
    },
    components:{
    },
}
</script>