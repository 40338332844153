import axios from "../plugins/axios";

const baseUrl = `${process.env.VUE_APP_BOOKSHARE_URL}/jobs/`;

export default {

    async getCategories(token) {
        let result;

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        let data = {};

        await axios.post(`${baseUrl}admin/get-job-categories`, data, config)
                .then(response =>
                    result = response.data);

        return result;
    },

    async getJobs(pageSize, pageNumber) {
        let result;

        let data = {
          pageSize:  pageSize,
          pageNumber: pageNumber
        }

        await axios.post(`${baseUrl}jobs`, data)
                .then(response =>
                    result = response.data);

        return result;
    },

    async addJob(   token, 
                    name, 
                    description, 
                    from, 
                    end, 
                    company, 
                    skills, 
                    jobType, 
                    categories, 
                    workLocation,
                    nameContactPerson,
                    surnameContactPerson,
                    emailContactPerson,
                    reference) {

        let result;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        let data = { 
            job: {
                name:name,
                description:description,
                from:from,
                end:end,
                company:company,
                skills:skills,
                jobType:jobType,
                categories: categories,
                workLocation:workLocation,
                contactPerson: {
                    name:nameContactPerson,
                    surname:surnameContactPerson,
                    email:emailContactPerson
                },
                reference:reference
            }
        }
        
        await axios.post(`${baseUrl}admin/add-job`, data, config)
                .then(response =>
                    result = response.data);

        return result;
    },

    async applyToJob(token, 
        job, 
        name, 
        surname, 
        email, 
        phone, 
        coverLetter,
        availableFrom,
        availableUntil, 
        numberOfHoursAvailable,
        attachment) {
        
            let result;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        let data = { 
            applyData: {
                job:job,
                name:name,
                surname:surname,
                email:email,
                phoneNumber: phone,
                coverLetter:coverLetter,
                availableFrom:availableFrom,
                availableUntil:availableUntil,
                numberOfHoursAvailable:numberOfHoursAvailable,
                attachment:attachment
            }
        }
        
        await axios.post(`${baseUrl}apply`, data, config)
                .then(response =>
                    result = response.data);

        return result;
    },
}
