import axios from "../plugins/axios";

export default {
    async getUniversities() {
        let universities;
        await axios.get(`${process.env.VUE_APP_BOOKSHARE_URL}/universities`)
                .then(response =>
                    universities = response.data.universities);
        return universities;
        
    }
   
}
