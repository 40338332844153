<template>
   <v-form>
        <v-container>
            <v-row>
                <v-col cols="3" class="hidden-md-and-down">
                    <UserMenuCard></UserMenuCard>   
                </v-col>
                <v-col>
                    <v-row>
                        <AllShipmentsComponent></AllShipmentsComponent>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
   </v-form>
</template>

<script>
import UserMenuCard from '../../../components/UserMenuCard.vue';
import AllShipmentsComponent from '../../../components/admin/AllShipmentsComponent.vue';

export default {

    data () {
      return {
      }
    },
       
    components:{
        UserMenuCard,
        AllShipmentsComponent,
    },
}
</script>