<template>
    <v-container>
        <v-row justify="center" >
            <v-col cols="12">
                <v-card color="transparent" v-for="(company, index) in companies" :key="index" class="pa-4 my-4">
                    <v-row>
                        <v-col cols="12" md="2">
                            {{company.name}}
                        </v-col>
                        <v-col cols="12" md="2">
                            {{company.numberOfEmployees}}
                        </v-col>
                        <v-col cols="12" md="2">
                            {{company.address}}
                        </v-col>
                        <v-col cols="12" md="2">
                            {{company.about}}
                        </v-col>
                        <v-col cols="12" md="2">
                            {{company.logoUrl}}
                        </v-col>
                        <v-col cols="12" md="2">
                            {{company.url}}
                        </v-col>
                    </v-row>
                </v-card>

                <v-row justify="end" class="mb-4">
                    <a>
                        <button class="mr-4" @click="loadMore()">>> (meer laden)</button>
                    </a>
                </v-row>

                <v-row justify="end">
                    <v-btn @click="openDialog()" color="primary" class="mb-4">
                        TOEVOEGEN
                    </v-btn>
                </v-row>
                    
                <v-row>
                    <v-dialog v-model="showDialog" width="600">
                        <template>
                            <v-card class="pa-8" >
                                <v-row justify="end" class="ma-2">
                                    <v-card-actions>
                                        <v-btn text @click="showDialog = false">
                                            <v-icon>
                                                mdi-close
                                            </v-icon>
                                        </v-btn>
                                    </v-card-actions>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-text-field v-model="name" placeholder="Name of the job"></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field v-model="numberOfEmployees" placeholder="Number of employees"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field v-model="urlLogo" placeholder="logo of the company"></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="url" placeholder="Url of the company"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field v-model="address" placeholder="Address of the company"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-textarea v-model="about" placeholder="About the company"></v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row class="mb-6">
                                    <v-col>
                                        <v-btn block color="primary" @click="addCompany()" :loading="loading">
                                            ADD
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </template>
                    </v-dialog>
                </v-row>               
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import CompanyService from '../../../services/CompanyService'

export default {
    data () {
        return {
            loading:false,
            errorMessage: null,
            companies:[],
            name:'',
            address:'',
            about: '',
            urlLogo:'',
            url:'',
            numberOfEmployees: '',
            pageNumber: 0,
            pageSize:5,
            showDialog: false,
        }
    },

    async created() {
        await this.getCompanies();
    },

    methods:{
        ...mapGetters(['getAccessToken']),
        async getCompanies(){
            try{
                this.errorMessage = null;
                this.loading = true;

                var result = await CompanyService.getCompanies(this.getAccessToken(), this.pageSize, this.pageNumber);
                this.companies = result.companies;
                this.$emit('reloadedCompanies', {companies:this.companies});

            } catch {
                this.errorMessage = 'Er is helaas een fout opgetreden.';
                console.error('getCompanies failed.');
            }
            this.loading = false;
        },

        async openDialog(){
            this.showDialog = true;
        },

        async loadMore(){
            var newPageSize = this.pageSize + 10;
            this.pageSize = newPageSize;
            await this.getCompanies();
        },

        async addCompany(){
            try{
                this.errorMessage = null;
                this.loading = true;

                await CompanyService.addCompany(
                    this.getAccessToken(), 
                    this.name,
                    this.address, 
                    this.about, 
                    this.numberOfEmployees, 
                    this.urlLogo, 
                    this.url);

                await this.getCompanies(this.pageSize, this.pageNumber);

                this.showDialog = false;

            } catch (ex){
                this.errorMessage = 'Er is helaas een fout opgetreden. Ex = ' + ex;
                console.error('addCompany failed. Ex = ' + ex);
            }
            this.loading = false;
        },
    }
}
</script>