<template>
     <v-row class="text-md-center block">
        <v-col cols="12">
          <h1 class="text-md-h3 font-weight-bold title-bookshare title-description">
            <div>Hoe werkt Boekshare?</div>
          </h1>
        </v-col>
        <v-col cols="12">
          <v-row align="stretch" class="mobile-carousel text-center hidden-md-and-up" justify="center">
            <v-carousel v-model="model"
              light
              cycle
              continuous
              height="200"
              hide-delimiter-background>
              <!-- show-arrows-on-hover -->
              <v-carousel-item
                v-for="(menuItem, i) in menuItems"
                :key="i"
              >
                <v-sheet color="#F5F8FD" height="100%">
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center"
                  >
                    <div class="text-h2">
                      <v-layout justify-center align-center>
                            <v-img  class="img" v-bind:src="require(`../../assets/ico/${menuItem.img}`)"
                            max-height="100" max-width="80" min-height="100" min-width="160"></v-img>
                        </v-layout>
                      <v-card-text class="carousel-items">{{ menuItem.text }} </v-card-text>
                    </div>
                  </v-row>
                </v-sheet>
              </v-carousel-item>
            </v-carousel>
          </v-row>

          <v-row align="stretch" class="text-center hidden-sm-and-down" justify="center">
              <v-col class="hidden-md-and-down"></v-col>
              <v-col cols="12" md="2" class="d-flex flex-column">
                <p style="min-height:100px;" class="subheading font-weight-regular text-center">
                  <v-layout justify-center align-center>
                      <v-img src="../../assets/ico/home-join-our-community.svg" max-height="100" max-width="80" min-height="100" min-width="160"></v-img>
                  </v-layout>
                </p>
                  Join onze community
              </v-col>
              <v-col cols="12" md="2" class="d-flex flex-column">
                <p style="min-height:100px" class="subheading font-weight-regular">
                  <v-layout justify-center align-center>
                      <v-img src="../../assets/ico/home-books.svg" max-height="100" max-width="80" min-height="100" min-width="160"></v-img>
                  </v-layout>
                </p>
                  Bied jouw boeken aan
              </v-col>
              <v-col cols="12" md="2" class="d-flex flex-column">
                <p style="min-height:100px" class="subheading font-weight-regular">
                  <v-layout justify-center align-center>
                      <v-img src="../../assets/ico/home-coins.svg" max-height="100" max-width="80" min-height="100" min-width="160"></v-img>
                  </v-layout>
                </p>
                  Verdien money
              </v-col>
              <v-col cols="12" md="2">
                <p style="min-height:100px" class="subheading font-weight-regular">
                  <v-layout justify-center align-center>
                      <v-img src="../../assets/ico/home-hand-coins.svg" max-height="100" max-width="80" min-height="100" min-width="160"></v-img>
                  </v-layout>
                </p>
                  Ruil de boeken die je wilt
              </v-col>
              <v-col cols="12" md="2" class="d-flex flex-column">
                <p style="min-height:100px;" class="subheading font-weight-regular">
                  <v-layout justify-center align-center>
                      <v-img src="../../assets/ico/home-money.svg" max-height="100" max-width="84" min-height="100" min-width="160"></v-img>
                  </v-layout>
                </p>
                  Of laat je money uitbetalen
              </v-col>            
              <v-col class="hidden-md-and-down" cols="1"> </v-col>
          </v-row>
        </v-col>
        <v-row class="btn-upload">
          <v-layout justify-center>
            <v-col cols="auto">
                <v-btn
                  color="primary"
                  href="/mybooks"
                >UPLOAD JE EERST BOEK</v-btn>
              </v-col>
          </v-layout>
        </v-row>
      </v-row>
</template>

<script>
  export default {
    data: () => ({
      model: 0,
      menuItems: [
        { img: 'home-join-our-community.svg', text: 'Join onze community' },
        { img: 'home-books.svg', text: 'Bied jouw boeken aan' },
        { img: 'home-coins.svg', text: 'Verdien money' },
        { img: 'home-hand-coins.svg', text: 'Ruil de boeken die je wilt' },
        { img: 'home-money.svg', text: 'Of laat je money uitbetalen' },
      ],
    }),
  }
</script>

<style scoped>
.title-description {
  padding-top: 5%;
}
.img-size {
  padding-top: 10%;
}
.carousel-items {
   font-family: 'Varela Round', sans-serif !important;
   font-size: 25px;
}
.v-card__text carousel-items {
 color: "#644FAB" !important;
}
.mobile-carousel {
  padding-bottom: 5%;
}
.btn-upload {
  padding-top: 2%;
}
/* .img-icons {
  max-height:"100";
  max-width:"80";
  min-height:"100";
  min-width:"150";
} */
</style>
