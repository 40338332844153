import axios from "../plugins/axios";

const baseUrl = `${process.env.VUE_APP_BOOKSHARE_URL}/intervene/`;

export default {

    async save(token, intervene, transactionId) {
        let result;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        let data = {
            transactionId: transactionId,
            interveneData:{
                id: intervene.id,
                boughtFor: intervene.boughtFor,
                soldFor: intervene.soldFor,
                barcode: intervene.barcode,
                url: intervene.url,
                platform: intervene.platform
            },
        }

        await axios.post(`${baseUrl}admin/save-intervene`, data, config)
                .then(response =>
                    result = response.data);

        return result;
    },

    async changeState(token, transactionId, state) {
        let result;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        let data = {
            transactionId: transactionId,
            state: state,
        }

        await axios.post(`${baseUrl}admin/change-intervene-state`, data, config)
                .then(response =>
                    result = response.data);

        return result;
    },

    async sendMessage(token, transactionId, interveneId, message) {
        let result;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        let data = {
            transactionId: transactionId,
            interveneId: interveneId,
            message: message
        }

        await axios.post(`${baseUrl}admin/send-intervene-message`, data, config)
                .then(response =>
                    result = response.data);

        return result;
    },

    async sendNoteMessage(token, transactionId, interveneId, message) {
        let result;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        let data = {
            transactionId:transactionId,
            interveneId: interveneId,
            message: message
        }

        await axios.post(`${baseUrl}admin/add-note`, data, config)
                .then(response =>
                    result = response.data);

        return result;
    },

    async getMessage(token, messageId) {
        let result;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        await axios.get(`${baseUrl}admin/get-intervene-message/`+messageId, config)
                .then(response =>
                    result = response.data);

        return result;
    },
}