<template>
    <v-container>
        <v-row>
            <v-col cols="3">
            </v-col>
            <v-col>
                 <v-card flat outlined width="500">
                     <v-row class="mt-2">
                        <v-col cols="4">
                        </v-col>
                        <v-col>
                            <v-img src="../assets/ico/boekshare_100x120px.svg" width="100"></v-img>
                        </v-col>
                     </v-row>
                     <v-row>
                         <v-col>
                        <v-img src="../assets/img/nothingfound.jpg"></v-img>
                        <v-card-text>
                            <p class="">Hé daar!<br/> 
                                <br/>
                                Oops! 🛠️ We zijn even onze site aan het tunen. We zorgen ervoor dat alles straks weer smooth 
                                loopt voor jou. Check ons snel weer en scoor die toffe boeken! 📚✨</p>
                        </v-card-text>
                         </v-col>
                     </v-row>
                 </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data () {
      return {
      }
    },
}
</script>
                