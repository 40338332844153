<template>
    <v-container>
        <v-row>
            <v-col cols="3" class="hidden-sm-and-down pt-0">
                <UserMenuCard></UserMenuCard>
            </v-col>
            <v-col class="pt-0">
                <v-row class="mb-4">
                    <v-col cols="11" md="7" class="pb-0">
                        <v-btn color="primary" :disabled="getMoney() == 0.0" @click="newCashout()">
                                NIEUWE CASHOUT
                        </v-btn>
                    </v-col>            
                </v-row>
                 <v-row v-if="loading"> 
                    <v-col cols="5" md="3">
                        <v-card-text v-if="errorMessage">
                            {{errorMessage}}
                        </v-card-text>
                    </v-col>
                    <v-col class="my-8">
                        <v-progress-circular
                        v-if="loading"
                        indeterminate
                        color="primary">
                        </v-progress-circular>
                    </v-col>
                </v-row>
                <v-row v-if="!loading && (cashouts == null || cashouts.length ==0)">
                    <v-col cols="12" md="12">
                         <v-card>
                            <v-card-title>Overzicht van je cashouts</v-card-title>
                            <v-card-subtitle>
                                Je hebt nog geen cashouts.
                            </v-card-subtitle>
                         </v-card>
                    </v-col>
                </v-row>
                <v-row class="mb-4">
                    <v-col cols="12" md="12">
                        <v-card class="mb-4" v-for="(cashout, index) in cashouts" :key="index">
                            <v-card-title>Mijn cashouts</v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <v-row>
                                            <v-col class="py-0">
                                                Geld in je wallet
                                            </v-col>
                                            <v-col class="py-0">
                                                €{{parseFloat(cashout.cashedOutAmount).toFixed(2)}}
                                            </v-col>
                                        </v-row>

                                        <v-row class="py-0">
                                            <v-col class="py-0">
                                                Administratiekosten voor deze cashout
                                            </v-col>
                                            <v-col class="py-0">
                                                €{{parseFloat(cashout.totalAdministrationCost).toFixed(2)}}
                                            </v-col>
                                        </v-row>
                                        
                                        <v-divider class="my-5"></v-divider>

                                        <v-row>
                                            <v-col class="py-0">
                                                Totaal ontvangen
                                            </v-col>
                                            <v-col class="py-0">
                                                €{{parseFloat(Number(cashout.cashedOutAmount) - Number(cashout.totalAdministrationCost)).toFixed(2)}}
                                            </v-col>
                                        </v-row>

                                        <v-row>
                                            <v-col class="pt-0">
                                                Datum
                                            </v-col>
                                            <v-col class="pt-0">
                                                 {{getFriendlyDate(cashout.requestedAt)}} {{getFriendlyTime(cashout.requestedAt)}}
                                            </v-col>
                                        </v-row>

                                        <v-row class="mt-0">
                                            <v-col class="pt-0">
                                                Status
                                            </v-col>
                                            <v-col class="pt-0">
                                                {{translateStatus(cashout.status)}}
                                            </v-col>
                                        </v-row>

                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import CashoutService from '../services/CashoutsService'
import UserMenuCard from '../components/UserMenuCard.vue'

export default {
    props: { },
    data () {
        return {
            cashouts: null,
            loading:false,
            errorMessage: null,
        }
    },
    async created() {
        await this.loadMyCashouts();
    },
    components:{
        UserMenuCard
    },
    methods: {
        ...mapGetters(['getAccessToken']),
        ...mapGetters(['getMoney']),
        async loadMyCashouts(){
            this.loading = true;
            try{
                let data =  await CashoutService.getMyCashouts(this.getAccessToken(), 1, 20);
                this.cashouts = data.cashouts;
            } catch {
                this.errorMessage = 'Er is helaas een fout opgetreden.'
            }
            this.loading = false;
        },

        newCashout(){
            this.$router.push({ name: 'RequestCashouts'})
        },

        translateStatus(status){
            if(status===0) return "Ingediend";
            else if(status===1) return "Ingediend";
            else if(status===2) return "Wordt verwerkt";
            else if(status===3) return "Afgewezen";
            else if(status===4) return "Afgerond";
            else return "Onbekend";
        },
        getFriendlyDate(val){
            var date = new Date(val);
            return date.getDate()  + "-" + (date.getMonth()+1) + "-" + date.getFullYear();
        },
        getFriendlyTime(val){
            var date = new Date(val);
            return date.getHours() + ":" + String(date.getMinutes()).padStart(2, "0");
        },   
    }
}
</script>