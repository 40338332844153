<template>
   <v-form>
        <v-container>
            <v-row>
                <v-col cols="3" class="hidden-md-and-down">
                    <UserMenuCard></UserMenuCard>   
                </v-col>
                <v-col>
                    <v-tabs center-active>
                        <v-tab ref="#conversations">Conversations</v-tab>
                        <v-tab-item key="conversations">
                            <Conversations></Conversations>
                        </v-tab-item>
                    </v-tabs>
                </v-col>
            </v-row>
        </v-container>
   </v-form>
</template>

<script>
import UserMenuCard from '../../../components/UserMenuCard.vue';
import Conversations from '../../../components/admin/Conversations.vue';

export default {

    data () {
      return {
      }
    },
       
    components:{
        UserMenuCard,
        Conversations
    },
}
</script>