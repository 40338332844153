<template>
    <stack-item>
        <a @click="showPBDetails()">
            <v-card>
                <!-- <v-img v-for="(image, indexImages) in book.images" :key="indexImages" :src="getImage(image.imageFileName)"/> -->
                <!-- <v-img :src="getPublishedBookImage(book)" max-width="300"/> -->
                <v-card-text v-if="item.subtitle" class="py-2">
                    <v-row>
                        <v-col class="font-weight-bold pb-0" cols="12">
                            {{item.title}} 
                        </v-col>
                        <v-col class="mt-0 pt-0"><span class="font-italic font-weight-thin">sponsored</span></v-col>
                    </v-row>
                </v-card-text>
                <v-img :src="image" max-width="400"/>
                <v-card-text v-if="!item.subtitle">{{item.title}}</v-card-text>
                <v-card-text v-if="item.subtitle">{{item.subtitle}}</v-card-text>
            </v-card>
        </a>
    </stack-item>
</template>

<script>
import { StackItem } from 'vue-stack-grid';
import { mapActions } from 'vuex';
import AdvertisementService from '../../services/AdvertisementService';

export default {
    props:['item'],
    data() {
		return {
            image: null,
        }
	},
    async mounted(){
        await this.loadImage();
    },
    watch:{
    },
    components: {
        StackItem
    },
    methods:{
        ...mapActions(['saveNavigationSearchValue']),
        ...mapActions(['saveStateOfPublishedBookOverview']),
        async loadImage() {  
            const img = new Image();
            img.src = this.item.imageFileName;
            img.onerror =() =>{
                this.image = require('@/assets/img/No-Image-Placeholder.svg.png');
            }
            img.onload = () => {
                this.image = this.item.imageFileName;
            }
        },
        async showPBDetails(){
            if(this.item.isBook){
                this.$gtag.event("click-top-trader", {
                'event_category': "top-trader",
                'event_label': "User selected a top trader book.",
                'value': this.item.isbn
                });
                this.saveNavigationSearchValue(this.item);
                this.saveStateOfPublishedBookOverview({publishedBook:this.item});
                this.$router.push({name:'PublishedBookOverview', query:{publishedbook:this.item.id}}); 
            }else{
                await AdvertisementService.advertisementClicked(this.item.id);
                if(this.isiPhoneDevice()) {
                    var url = this.item.redirectUrl;
                    setTimeout(function() {
                        window.open(url, "_self");
                    },500); 
                } else{
                    window.open(this.item.redirectUrl, "_self");
                }
            }
        },
        isiPhoneDevice(){
            var ua = window.navigator.userAgent;
            // var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
            // var webkit = !!ua.match(/WebKit/i);
            // var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
            return (window.navigator.vendor && window.navigator.vendor.indexOf('Apple') > -1 &&
                   window.navigator.userAgent &&
                   window.navigator.userAgent.indexOf('CriOS') == -1 &&
                   window.navigator.userAgent.indexOf('FxiOS') == -1);
            //return isSafari;
        },
    },
    
}
</script>