import axios from "../plugins/axios";

const baseUrl = `${process.env.VUE_APP_BOOKSHARE_URL}/annunci`;

export default {
    async getAdvertisements(count) {
        let result;
        
        let data = { count: count };

        await axios.post(`${baseUrl}/get-annuncio`, data)
                .then(response =>
                    result = response.data);
        return result;
    },

    async getAdminAdvertisements(token, from, to, pageNumber, pageSize) {
        let result;
        
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        let data = { from: from, to:to, pageNumber:pageNumber, pageSize:pageSize };

        await axios.post(`${baseUrl}/admin/get-advertisements`, data, config)
                .then(response =>
                    result = response.data);
        return result;
    },
    async advertisementClicked(id){
        let result;
        
        let data = { 
            advertisementId:id
        };
    
        await axios.post(`${baseUrl}/update-annuncio`, data)
                .then(response =>
                    result = response.data);
        return result;
    },
    async addAdvertisement(token, advertisement) {
        let result;
    
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
    
        let data = { 
            advertisement:{
                title: advertisement.title,
                subtitle: advertisement.subtitle,
                customerName: advertisement.customerName,
                redirectUrl: advertisement.redirectUrl,
                image: advertisement.image,
                from: advertisement.startDate,
                end: advertisement.endDate,
                active: advertisement.active
            }
        };
    
        await axios.post(`${baseUrl}/admin/add-advertisement`, data, config)
                .then(response =>
                    result = response.data);
        return result;
    },
}