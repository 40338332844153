<template>
 <v-container>
        <v-row>
            <v-col cols="3" class="hidden-sm-and-down pt-0">
                <UserMenuCard></UserMenuCard>
            </v-col>
            <v-col class="pt-0">
                <v-list three-line>
                    <v-card flat v-if="(!alerts || alerts.length == 0) && !loading">
                        <v-card-text v-if="!errorMessage">
                            Je verlanglijstje is nog leeg.
                        </v-card-text>
                        <v-card-text v-if="errorMessage">
                            {{errorMessage}}
                        </v-card-text>
                    </v-card>
                     <v-card flat v-if="loading" class="mt-3">
                        <v-row>
                        <v-col cols="6">
                        </v-col>
                        <v-col>
                            <v-progress-circular
                            indeterminate
                            color="primary">
                            </v-progress-circular>
                        </v-col>
                    </v-row>
                    </v-card>
                    <!-- <div v-if="!alerts || alerts.length == 0" class="ma-4">Je boekenlijst is nog leeg.</div> -->
                    <template v-for="alert in alerts">
                        <v-list-item v-if="!alert.removed" :key="alert.publishedBook.title">
                            <v-col cols="2">
                                <v-img max-width="100"
                                    :src="alert.publishedBook.imageFileName">
                                </v-img>
                            </v-col>
                            <v-col cols="8">
                                <v-list-item-content>
                                    <v-list-item-title v-html="alert.publishedBook.title"></v-list-item-title>
                                    <v-list-item-subtitle v-html="alert.publishedBook.isbn"></v-list-item-subtitle>
                                    <v-list-item-subtitle v-html="alert.publishedBook.edition"></v-list-item-subtitle>
                                </v-list-item-content>
                            </v-col>
                            <v-col>
                                <v-btn small class="primary" @click="removeAlert(alert)">
                                    <v-icon small>
                                        mdi-delete
                                    </v-icon>
                                </v-btn>
                            </v-col>
                        </v-list-item>
                    </template>
                </v-list>
            </v-col>
        </v-row>
        <v-row>
                <v-col cols="3"></v-col>
                <v-col>
                    <v-row>
                        <v-pagination
                            v-if="totalPages >0"
                            style="padding-bottom: 3%; padding-top: 3%"
                            v-model="pageNumber"
                            :length="totalPages"
                            @input="loadAlerts()"
                            :total-visible="10">
                        </v-pagination>
                    </v-row>
                </v-col>
            </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import UserMenuCard from '../components/UserMenuCard.vue';
import StudentService from '../services/StudentService'

  export default {
    data: () => ({
        alerts:[],
        errorMessage:null,
        loading:false,
        pageSize: 10,
        pageNumber: 1,
        totalPages:0
    }),
    async created() {
        await this.loadAlerts();
    },
    methods:{
        ...mapGetters(['getStudent']),
        ...mapGetters(['getAccessToken']),
        async removeAlert(alert){
            try{
                this.errorMessage = null;
                this.loading = true;
                this.alerts = await StudentService.removeAlert(this.getAccessToken(), alert.id);
            }
            catch{
                this.errorMessage = 'Er is een fout opgetreden';
            }
            this.loading = false;
        },
        async loadAlerts(){
             try{
                this.errorMessage = null;
                this.loading = true;
                let result = await StudentService.getAlerts(this.getAccessToken(), this.pageSize, this.pageNumber);
                this.alerts = result.alerts;
                this.totalPages = Math.floor((result.totalCount / this.pageSize) + 1);
                window.scrollTo(0,0);
            }
            catch{
                this.errorMessage = 'Er is een fout opgetreden';
            }
            this.loading = false;
        }
    },
    components: {
         UserMenuCard
    },
  }
</script>