<template>
   <v-container>
        <v-row justify="end" v-if="!disableClosingDialog">
            <v-card-actions>
                <v-btn text @click="closeButton(true)" >
                    <v-icon>
                        mdi-close
                    </v-icon>
                </v-btn>
            </v-card-actions>
        </v-row>
        <v-row  justify="center" v-if="loading">
            <v-progress-circular
                v-if="loading"
                indeterminate
                color="primary">
            </v-progress-circular>
        </v-row>
        <v-row v-if="!reviewIsGiven">
            <v-card flat width="600" v-if="transaction">
                <v-card-title primary-title class="justify-center title">
                    <h2>Review {{transaction.id}}</h2>
                </v-card-title>
                
                <v-divider></v-divider>
                
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <h2 class="font-weight-bold">{{transaction.seller.firstName + ' ' + transaction.seller.lastName}}</h2>
                        </v-col>
                        <v-col>
                            <v-row>
                                 <v-col>
                                    {{transaction.book.publishedBook.title}}
                                </v-col>
                                <v-col cols="3">
                                    <v-img max-width="70" :src="transaction.book.publishedBook.imageFileName"></v-img>
                                </v-col>
                               
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" v-for="(rating, index) in ratings" :key="index">
                            {{rating.name}}
                                <v-rating
                                    v-model="givenRatings[index].givenNumber"
                                    color="yellow darken-3"
                                    background-color="grey darken-1"
                                    empty-icon="$ratingFull"
                                    half-increments
                                    hover>
                                </v-rating>
                        </v-col>
                        <v-col cols="12" class="mb-0 pb-0" >
                            Of iets anders
                            <v-textarea outlined v-model="additionalInformation" placeholder="Wat zou je willen doorgeven?"></v-textarea>
                        </v-col>
                        <v-col cols="12" class="mb-5 py-0">
                            <v-btn color="primary" @click="giveReview()" :loading="loading">
                                Versturen
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-row>
        <v-row justify="center">
             <v-card flat width="600" color="transparent">
                 <v-card-text>
                     <v-col class="red--text mb-5 py-0" v-if="errorMessage">
                            {{errorMessage}}
                    </v-col>
                </v-card-text>
            </v-card>
        </v-row>

        <v-row justify="center" v-if="reviewIsGiven">
             <v-card flat color="transparent" width="600">
                 <v-card-text>
                     <v-col class="mb-5 py-0">
                            Bedankt voor je feedback. Terug naar <router-link class="mx-1" to="/">Home</router-link>
                    </v-col>
                </v-card-text>
            </v-card>
        </v-row>
   </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import ReviewService from "../services/ReviewService"
import TransactionService from '../services/TransactionService';
import { EventBus } from '../services/EventBus'

export default {
    props:['selectedTransaction', 'disableClosingDialog'],
    data(){
        return {
            loading:false,
            ratings:[],
            givenRatings:[],
            additionalInformation: '',
            errorMessage: null,
            reviewIsGiven:false,
            transaction:null,
        }
    },
    
    watch: {
      selectedTransaction(newValue, oldValue) {
      }
    },

    async created() {
        EventBus.$on('loadTransactionById', this.loadTransactionById);
        EventBus.$on('loadRatings', this.loadRatings);
    },

    beforeDestroy() {
        EventBus.$off('loadTransactionById', this.loadTransactionById);
        EventBus.$off('loadRatings', this.loadRatings);
    },

    methods: {
        ...mapGetters(['getAccessToken']),
        
        async loadRatings() {
            try{
                this.errorMessage = null;
                this.loading = true;
                let result = await ReviewService.getRatings(this.getAccessToken());
                this.ratings = result.ratings;
                this.givenRatings = result.ratings;
            } catch{
                this.errorMessage = 'Er is helaas iets mis gegaan.'
            }
            
            this.loading = false;
        },

        async loadTransactionById(transactionId) {
            try{
                this.reviewIsGiven = false;
                this.errorMessage = null;
                this.loading = true;
                let result = await TransactionService.getTransactionById(this.getAccessToken(), transactionId);
                this.transaction = result.transaction;
            } catch{
                this.errorMessage = 'Er is helaas iets mis gegaan.'
            }
            
            this.loading = false;
        },

        async giveReview(){
             try {
                this.errorMessage = null;
                this.loading = true;
                
                let result = await ReviewService.giveReview(
                    this.getAccessToken(), 
                    this.transaction.id, 
                    this.givenRatings, 
                    this.additionalInformation);

                this.ratings = result.ratings;
                this.reviewIsGiven = true;
                this.$emit('reviewGiven');
                
            } catch{
                this.errorMessage = 'Er is helaas iets mis gegaan.'
            }
            
            this.loading = false;
        },

        closeButton(){
            this.$emit('closeDialog');
        }
     }
}
</script>