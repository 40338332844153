<template>
    <v-row justify="center" class="mb-4">
        <v-card class="pa-4" max-width="600">
            
             <div v-if="statusLoad === 0">
                <v-card-text>
                    <v-row justify="center" class="my-4">
                        <v-img src="../assets/img/Loading.png" max-width="400"></v-img>
                    </v-row>
                </v-card-text>     
                <v-card-text>Neem wat drinken terwijl we je account activeren.</v-card-text>
                <v-card-text>Voor het vinden van je studieboeken kun je naar de <router-link class="mx-1" to="/">home</router-link> pagina.</v-card-text>
            </div>
            <div v-if="statusLoad==1">
                <v-card-text>
                    <v-row justify="center" class="my-4">
                        <v-img src="../assets/img/party.jpg" max-width="400"></v-img>
                    </v-row>
                </v-card-text>
                <v-card-title>Hi {{firstname}} {{lastname}},</v-card-title>
                <v-card-text>Je account is geactiveerd. Je kunt direct gebruik maken van je account.</v-card-text>
                <v-card-text>Voor het vinden van je studieboeken kun je naar de <router-link class="mx-1" to="/">home</router-link> pagina.</v-card-text>
                <v-card-text></v-card-text>
            </div>
            <div v-if="statusLoad==2">
                <v-card-text>
                    <v-row justify="center" class="my-4">
                        <v-img src="../assets/img/questions.jpg" max-width="400"></v-img>
                    </v-row>
                </v-card-text>
                <v-card-title>Hi {{firstname}} {{lastname}},</v-card-title>
                <v-card-text>Helaas is het activeren van je account niet gelukt. Neem contact met ons op en we helpen je graag verder.</v-card-text>
                <v-card-text>Voor het vinden van je studieboeken kun je naar de <router-link class="mx-1" to="/">home</router-link> pagina.</v-card-text>
            </div>
        </v-card>
    </v-row>
</template>

<script>
import AccountService from '../services/AccountService';

 export default {
    data: () => ({
        statusLoad: 0,
        firstname: '',
        lastname: ''
    }),
    created() {
        this.handleActivateAccount();
    },
    mounted() {
       const recaptchaScript = document.createElement("script");
        recaptchaScript.setAttribute("src","<!-- Event snippet for Abonneren conversion page --> <script> gtag('event', 'activateaccount', {'send_to': 'AW-10926706475/iUYICNXvrsIDEKueodoo'}); <\/script>");
        document.head.appendChild(recaptchaScript);
    },
    methods: {
         async handleActivateAccount(){
            this.statusLoad = 0;
            let result=[];

            this.firstname = this.$route.query.firstname;
            this.lastname = this.$route.query.lastname;
            
            if(this.$route.query.token && this.$route.query.email){
                result = await AccountService.confirmEmail(encodeURIComponent(this.$route.query.token),this.$route.query.email);
             }
             
             if(result && result.result && result.result === "1"){
                this.statusLoad = 1;
             }else{
                 this.statusLoad = 2;
             }
         },
    },
  }
</script>