<template>
    <v-form>
        <v-row justify="center">
            <v-col cols="12" md="4">
                <CreateAccount></CreateAccount>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import CreateAccount from "../components/CreateAccount.vue";

export default {
    components: {
        CreateAccount
    }
  }
</script>