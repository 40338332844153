<template>
    <v-row justify="center" class="mb-4">
        <v-col cols="12" md="6">
            <v-card class="pa-4 mx-sm-4">
                <div v-if="statusLoad === 0">
                    <v-card-text>
                        <v-row justify="center" class="my-4">
                            <v-img src="../assets/img/Loading.png" max-width="400"></v-img>
                        </v-row>
                    </v-card-text>     
                    <v-card-text>Neem wat drinken terwijl we je betaling verwerken.</v-card-text>
                    <v-card-text>Voor het vinden van je studieboeken kun je naar de <router-link class="mx-1" to="/">home</router-link> pagina.</v-card-text>
                </div>
                <div v-if="statusLoad==1">
                    <v-card-text>
                        <v-row justify="center" class="my-4">
                            <v-img src="../assets/img/party.jpg" max-width="400"></v-img>
                        </v-row>
                    </v-card-text>
                    <v-card-title>Hi {{accountName}},</v-card-title>
                    <v-card-text>Je betaling is successvol afgerond. Je kunt nu gebruik maken van je lidmaatschap. We wensen je veel plezier op ons platform.
                        Ga naar de <router-link class="mx-1" to="/">home</router-link> pagina om de boeken die je nodig hebt te vinden.</v-card-text>
                    <v-card-text></v-card-text>
                </div>
                <div v-if="statusLoad==2">
                    <v-card-text>
                        <v-row justify="center" class="my-4">
                            <v-img src="../assets/img/questions.jpg" max-width="400"></v-img>
                        </v-row>
                    </v-card-text>
                    <v-card-title>Hi {{accountName}},</v-card-title>
                    <v-card-text>Je betaling is helaas niet gelukt. Probeer het opnieuw en als het nog steeds niet lukt, 
                        neem dan contact met ons op.</v-card-text>
                    <v-card-actions><v-btn class="primary" @click="pay">BETALEN</v-btn></v-card-actions>
                </div>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SubscriptionService from "../services/SubscriptionService"
import AccountService from "../services/AccountService"

 export default {
    props: ['studentId', 'paymentId'], 
    data: () => ({
        accountName:'',
        paymentResult: '',
        statusLoad: 1
    }),
    created() {
        this.handlePayment();
    },
     methods: {
         ...mapGetters(['getAccessToken']),
         ...mapActions(['setStudentsAccount']),
         async handlePayment(){
             this.statusLoad = 0;
             let result = await SubscriptionService.update(this.$route.query.account, this.$route.query.subscription);
             if(result){
                this.accountName = result.accountName;
                this.paymentResult = result.status;
                if(this.paymentResult === "paid"){
                    this.statusLoad = 1;
                    let student = await AccountService.getAccountOfStudent(this.getAccessToken());
                    this.setStudentsAccount(student);
                }
                else {
                    this.statusLoad = 2;
                }
             }else{
                 this.statusLoad = 2;
             }
         },
         async pay(){
            this.statusLoad = 0;
            let payment = await SubscriptionService.pay(this.getAccessToken(), this.$route.query.subscription);
            if(this.isiPhoneDevice()) {
                var url = payment.checkoutLink;
                setTimeout(function() {
                    window.open(url, "_self");
                },500);
            } else {
                window.open(payment.checkoutLink);
            }
         },
        isiPhoneDevice(){
            var ua = window.navigator.userAgent;
            // var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
            // var webkit = !!ua.match(/WebKit/i);
            // var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
            return (window.navigator.vendor && window.navigator.vendor.indexOf('Apple') > -1 &&
                   window.navigator.userAgent &&
                   window.navigator.userAgent.indexOf('CriOS') == -1 &&
                   window.navigator.userAgent.indexOf('FxiOS') == -1);
            //return isSafari;
        },
    },
  }
</script>
