<template>
   <v-form>
        <v-container> 
            <v-row>
                <v-col cols="3" class="hidden-md-and-down">
                    <UserMenuCard></UserMenuCard>   
                </v-col>
                <v-col>
                    <v-row justify="center" v-if="loading">
                        <v-progress-circular
                        indeterminate
                        color="primary">
                        </v-progress-circular>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-card class="pa-4">
                                <v-row>
                                    <v-col cols="12" md="4">
                                        <v-text-field v-model="discount.name" label="Naam"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                         <v-text-field v-model="discount.amount" :label="discount.type === 0 ? 'Percentage' : 'Fixed'"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-text-field v-model="discount.uniqueNumber" label="Unieke nummer"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-select
                                            label="Discount is voor pagina: "
                                            v-model="discount.applyTo"
                                            :items="[{ text: 'Abonnement', value: 0 }, { text: 'Checkout', value: 1 }]"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-select
                                            label="Selecteer type discount"
                                            v-model="discount.type"
                                            :items="[{ text: 'Percentage', value: 0 }, { text: 'Fixed', value: 1 }]"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                                 <v-row>
                                    <v-col cols="12" md="6">
                                        <v-menu
                                            v-model="menuDiscountStartDate"
                                            :close-on-content-click="true"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-model="discount.startDate"
                                                    label="Startdatum"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    outlined
                                                    v-on="on"
                                                    dense
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker 
                                                v-model="discount.startDate" 
                                                no-title 
                                                scrollable
                                                dense
                                                @input="menuDiscountStartDate = false;">
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col>
                                        <v-menu
                                            v-model="menuDiscountEndDate"
                                            :close-on-content-click="true"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-model="discount.endDate"
                                                    label="Einddatum"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    outlined
                                                    v-on="on"
                                                    dense
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker 
                                                v-model="discount.endDate" 
                                                no-title 
                                                scrollable
                                                dense
                                                @input="menuDiscountEndDate = false">
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-0">
                                    <v-col class="pt-0">
                                        <v-btn :disabled="!formValid" color="primary" @click="add()">AANMAKEN</v-btn>
                                    </v-col>
                                    <v-col cols="12" v-if="errorMessage !== null" class="pt-0">
                                        <span class="red--text">{{ errorMessage }}</span>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            Percentage
                        </v-col>
                        <v-col cols="12" md="6" v-for="(discount, index) in percentageDiscounts" :key="index">
                            <v-card>
                                <v-card-title>{{discount.name}}</v-card-title>
                                 <v-card-subtitle class="pb-0">Nummer: {{discount.uniqueNumber}}</v-card-subtitle>
                                 <v-card-subtitle class="py-0">begindatum: {{discount.startDate}}</v-card-subtitle>
                                 <v-card-subtitle class="py-0">Einddatum: {{discount.endDate}}</v-card-subtitle>
                                 <v-card-subtitle class="pt-0">Percentage: {{discount.percentage}}%</v-card-subtitle>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            Fixed Amount
                        </v-col>
                        <v-col cols="12" md="6" v-for="(discount, index) in fixedDiscounts" :key="index">
                            <v-card>
                                <v-card-title>{{discount.name}}</v-card-title>
                                 <v-card-subtitle class="pb-0">Nummer: {{discount.uniqueNumber}}</v-card-subtitle>
                                 <v-card-subtitle class="py-0">begindatum: {{discount.startDate}}</v-card-subtitle>
                                 <v-card-subtitle class="py-0">Einddatum: {{discount.endDate}}</v-card-subtitle>
                                 <v-card-subtitle class="pt-0">Bedrag: €{{discount.discount}}</v-card-subtitle>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
import DiscountService from '../../../services/DiscountService';
import UserMenuCard from '../../../components/UserMenuCard.vue';
import { mapGetters } from 'vuex';

  export default {
    data () {
      return {
        percentageDiscounts:[],
        fixedDiscounts:[],
        discount: { type:0 },
        loading: false,
        menuDiscountStartDate: false,
        menuDiscountEndDate: false,
        errorMessage: null
      }
    },
    async created(){
       await this.loadDiscounts();
    },
    computed: {
        formValid() {
            return (this.discount.name != null &&
                this.discount.uniqueNumber != null &&
                this.discount.startDate != null &&
                this.discount.endDate != null);
        },
    },
    methods:{
        ...mapGetters(['getAccessToken']),
       async loadDiscounts(){
           try{
                this.loading = true;
                let data = await DiscountService.getDiscounts(this.getAccessToken());
                this.percentageDiscounts = data.percentageDiscounts;
                this.fixedDiscounts = data.fixedDiscounts;
           }catch{}

           this.loading = false;
        },

        async add(){
           try{
                this.loading = true;
                this.errorMessage = null;
                var result = await DiscountService.isDiscountNumberUnique(this.getAccessToken(), this.discount.uniqueNumber);
                if(result.isUnique){
                    if(this.discount.type === 0){
                        let data = await DiscountService.addPrecentageDiscount(this.getAccessToken(), this.discount);
                        this.percentageDiscounts = data.percentage;
                    } else if(this.discount.type === 1){
                        let data = await DiscountService.addFixedAmountDiscount(this.getAccessToken(), this.discount);
                        this.fixedDiscounts = data.fixedAmount;
                    }
                } else{
                    this.errorMessage = 'Nummer bestaat al';
                }
                
            } catch{}

            this.loadDiscounts();
            
           this.loading = false;
        },
    },
    components:{
        UserMenuCard
    },
  }
</script>
