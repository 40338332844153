import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueSimpleAlert from "vue-simple-alert";

Vue.use(Vuetify)
Vue.use(Toast);
Vue.use(VueSimpleAlert);
Vue.use(require('vue-moment'));

const vuetify = new Vuetify({
  theme: {
    themes: {
      options: {
        customProperties: true
      },
      light: {
        defaultbackgroundcolor: '#F5F8FD',
        primary: '#644FAB',
        secondary: '#77EDCE',
        anchor: '#644FAB',
        remove: '#bb3030'
      },
    },
  },
})

export default vuetify