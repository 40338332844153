<template>
  <v-form>
    <v-container> 
        <v-row>
            <v-col cols="3" class="hidden-md-and-down pt-0">
                <UserMenuCard></UserMenuCard>   
            </v-col>
            <v-col class="pt-0">
                 <v-card flat v-if="(errorMessage) && !loading">
                    <v-card-text class="red--text">
                        {{errorMessage}}
                    </v-card-text>
                </v-card>
                <v-card flat v-if="loading" class="mt-3">
                    <v-row>
                        <v-col cols="6">
                        </v-col>
                        <v-col>
                            <v-progress-circular
                            indeterminate
                            color="primary">
                            </v-progress-circular>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card v-if="!errorMessage" flat class="pt-0"> 
                    <v-card-text>
                        <v-col>
                            <v-row justify="center">
                                <v-img
                                    id="accountBigAvatarImageId"
                                    contain 
                                    :src="imagePreview"
                                    max-width="250"/>
                            </v-row>
                        </v-col>
                        <v-row>
                            <v-col>
                                <v-row justify="center"> 
                                    <v-btn class="upload-button button primary ml-3" v-if="!imagePreview" type="button" @click="uploadImage">Upload JE FOTO</v-btn>
                                    <v-btn class="upload-button button primary ml-3" v-if="imagePreview" type="button" @click="removeImage">X</v-btn>
                                    <image-compressor
                                    :hidden=true 
                                    class="compressor" 
                                    :done="getFiles" 
                                    :scale="scale" 
                                    :quality="quality" 
                                    ref="compressor">
                                    </image-compressor>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row justify="center" class="pt-8" v-if="student!= null && student.wallet != null && student.wallet.amount != null">
                            <h3 class="font-weight-bold">Je hebt €{{parseFloat(student.wallet.amount).toFixed(2)}} in je wallet</h3>
                        </v-row>
                    </v-card-text>
                    <v-card-text v-if="!errorMessage">
                        <v-col>
                            <h3>Persoonlijke informatie</h3>
                        </v-col>
                        <v-col>
                            <v-text-field v-model="student.firstName"
                                label="Voornaam"
                                placeholder="Voornaam"
                                @keyup="studentNameChanged()"
                                outlined>
                            </v-text-field>
                            <v-text-field v-model="student.lastName"
                                label="Achternaam"
                                placeholder="Achternaam"
                                @keyup="studentSurnameChanged()"
                                outlined>
                            </v-text-field>
                            <v-text-field v-model="student.phone"
                                label="Telefoon"
                                placeholder="Telefoon"
                                outlined>
                            </v-text-field>
                            <v-menu
                                ref="menu"
                                v-model="student.menu"
                                :close-on-content-click="false"
                                :return-value.sync="student.birthDate"
                                transition="scale-transition"
                                offset-y
                                min-width="auto">

                                <v-date-picker
                                v-model="student.birthDate"
                                no-title
                                scrollable
                                >
                                <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="menu = false">
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.menu.save(student.birthDate)">
                                        OK
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>

                            <v-autocomplete
                                v-model="student.university"
                                :items="universities"
                                :item-text="'name'"
                                label="Onderwijsinstituut"
                                return-object
                                outlined>
                            </v-autocomplete>                       

                            <v-autocomplete
                                v-model="student.study"
                                :items="courses"
                                :item-text="'name'"
                                label="Studie"
                                return-object
                                outlined>
                            </v-autocomplete>                       
                        </v-col>
                
                        <v-col class="my-0 py-0">
                            <h3>Accountgegevens</h3>
                        </v-col>
                        <v-col>
                            <v-text-field v-model="student.userName"
                                :rules="[rules.required]"
                                label="Gebruikersnaam"
                                placeholder="Gebruikersnaam"
                                :disabled="true"
                                outlined>
                            </v-text-field>
                            <v-text-field v-model="student.email"
                                :rules="[rules.required, rules.email]"
                                :disabled="true"
                                label="Email"
                                placeholder="Email"
                                outlined>
                            </v-text-field>
                        </v-col>

                        <v-col class="my-0 py-0">
                            <h3>Lidmaatschap</h3>
                        </v-col>
                        <v-col>
                           <SubscriptionDetails ref="ref_subscriptionDetails"></SubscriptionDetails>
                        </v-col>

                        <v-col class="my-0 py-0">
                            <h3>Woonadres</h3>
                        </v-col>
                        <v-col>
                            <v-row class="my-0 py-0">
                                <v-col class="my-0 py-0" md="12">
                                    <v-row no-gutters>
                                        <v-col class="pr-2">
                                            <v-text-field 
                                                v-model="student.residentialAddress.postalCode"
                                                :rules="[rules.postalcode]"
                                                label="Postcode"
                                                maxlength="6"
                                                placeholder="bijv. 1234AB"
                                                outlined>
                                            </v-text-field>
                                        
                                        </v-col>
                                        <v-col class="pr-2">
                                            <v-text-field 
                                                v-model="student.residentialAddress.houseNumber"
                                                label="Huisnummer"
                                                placeholder="Huisnummer"
                                                outlined>
                                            </v-text-field>
                                        </v-col>
                                        <v-col>
                                            <v-text-field 
                                                v-model="student.residentialAddress.additionHouseNumber"
                                                label="Toevoeging"
                                                placeholder="bijv. a"
                                                outlined>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="my-0 py-0">
                                <v-col class="my-0 py-0">
                                    <v-row class="my-0 py-0" no-gutters>
                                            <v-col class="pr-2">
                                                <v-text-field 
                                                v-model="student.residentialAddress.city"
                                                label="Woonplaats"
                                                disabled
                                                outlined>
                                            </v-text-field>
                                        </v-col>
                                        <v-col class="py-0">
                                            <v-text-field 
                                                v-model="student.residentialAddress.street"
                                                disabled
                                                label="Straat"
                                                outlined>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>        
                            </v-row>
                            <v-row class="my-0 py-0">
                                <v-col class="my-0 py-0">
                                    <v-checkbox class="py-0" v-model="postalAddressSameAsResidential" label="Postadres is zelfde als woonadres"></v-checkbox>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col v-if="!postalAddressSameAsResidential">
                            <h3>Postadres</h3>
                        </v-col>
                        <v-col v-if="!postalAddressSameAsResidential">
                            <v-row>
                                <v-col>
                                    <v-row no-gutters>
                                        <v-col class="pr-2">
                                            <v-text-field v-model="student.postalAddress.postalCode"
                                                label="Postcode"
                                                :rules="[rules.postalcode]"
                                                placeholder="bijv. 1234AB"
                                                maxlength="6"
                                                outlined>
                                            </v-text-field>
                                            
                                        </v-col>
                                        <v-col class="pr-2">
                                            <v-text-field v-model="student.postalAddress.houseNumber"
                                                label="Huisnummer"
                                                placeholder="Huisnummer"
                                                outlined>
                                            </v-text-field>
                                        </v-col>
                                        <v-col>
                                            <v-text-field v-model="student.postalAddress.additionHouseNumber"
                                                label="Toevoeging"
                                                placeholder="bijv. a"
                                                outlined>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                                <v-row>
                                <v-col md="12">
                                    <v-row no-gutters>
                                        <v-col class="pr-2">
                                                <v-text-field v-model="student.postalAddress.city"
                                                label="Woonplaats"
                                                placeholder="Woonplaats"
                                                disabled
                                                outlined>
                                            </v-text-field>
                                        </v-col>
                                        <v-col>
                                        <v-text-field v-model="student.postalAddress.street"
                                                label="Street"
                                                disabled
                                                placeholder="Street"
                                                outlined>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col> 
                    
                        <v-col>
                            <h3>Bankgegevens</h3>
                        </v-col>
                        <v-col>
                            <v-text-field 
                                v-model="student.wallet.iban"
                                label="Banknummer (IBAN)"
                                placeholder="Banknummer (IBAN)"
                                :rules="[rules.iban]"
                                outlined>
                            </v-text-field>
                            <v-text-field
                                v-model="student.wallet.cardHolderName"
                                label="Tenaamstelling"
                                placeholder="Tenaamstelling"
                                outlined>
                            </v-text-field>
                        </v-col>
                        <v-col class="my-0 py-0">
                            <h3>Uitbetalingen</h3>
                        </v-col>
                        <v-col class="pa-0">
                            <v-col>
                                Je geld uitbetalen, de status of het overzicht van je uitbetalingen bekijken?  <router-link to="MyCashouts">
                                    Ga dan naar het overzicht.</router-link>
                            </v-col>
                            <v-col>
                               
                            </v-col>
                        </v-col>
                        <v-col class="my-0 py-0">
                            <h3>Account sluiten</h3>
                        </v-col>
                        <v-col class="pa-0">
                            <v-col>
                                <CloseAccount></CloseAccount>
                            </v-col>
                            <v-col>
                               
                            </v-col>
                        </v-col>
                    </v-card-text>    
                    <v-card-text>
                        <v-col>
                            <v-btn
                                color="primary"
                                :disabled="!formValid || loading"
                                :loading="loading"
                                @click="update">
                                Opslaan
                            </v-btn>
                            <label class="pl-2" v-if="updateSuccess">Je profiel is bijgewerkt.</label>
                        </v-col>
                    </v-card-text> 
                </v-card>
            </v-col> 
        </v-row>
    </v-container>
  </v-form>
</template>

<script>
import UserMenuCard from '../components/UserMenuCard.vue';
import StudentService from '../services/StudentService';
import NoUserImage from "../assets/img/no-user.png"
import UniversityService from "../services/UniversityService"
import CourseService from "../services/CourseService"
import AddressService from '../services/AddressService'
import AccountService from '../services/AccountService'
import SubscriptionDetails from '../components/SubscriptionDetails.vue'
import CloseAccount from '../components/CloseAccount.vue'
import { mapGetters, mapActions } from 'vuex'
import imageCompressor from 'vue-image-compressor';

 export default {
    data () {
      return {
        // BEGIN COMPRESSOR
        img: "",
        scale: Number(90),
        quality: Number(10),
        originalSize: true,
        original: {},
        compressed: {},
        // END COMPRESSOR
        student: {
                residentialAddress:{ street:'', postalCode:'', houseNumber:'', street:'', additionHouseNumber:''},
                postalAddress:{ street:'', postalCode:'', houseNumber:'', street:'', additionHouseNumber:''},
                wallet: {iban:null, cardHolderName:''}
            },
        universities: [],
        courses: [],
        confirmPassword: null,
        menu: false,
        showPassword: false,
        showConfirmPassword: false,
        defaultImagePreview: NoUserImage,
        selectedImageFile: null,
        imagePreview: null,
        loading: false,
        timeoutName: null,
        timeoutSurname: null,
        updateSuccess: false,
        errorMessage: null,
        disableAutoAddressFinder:false,
        postalAddressSameAsResidential: true,
        residentialAddressIsValid: false,
        postalAddressIsValid: false,
        rules: {
          required: value => !!value || 'Required.',
          min: v => v?.length >= 8 || 'Ten minste 8 tekens',
          email: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid',
          postalcode: v => /^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/i.test(v) || 'Niet geldige postcode',
          iban: value => (value && value.length===0) || this.isValidIBANNumber(value)
        },
      }
    },

    async mounted() {
        await this.loadStudentsAccount();
    },

    watch:{
        'student.residentialAddress.houseNumber': function () {
        	if(!this.disableAutoAddressFinder) {
                this.debouncedResidentialAddressChange();
            }
        },
        'student.residentialAddress.postalCode': function () {
            if(!this.disableAutoAddressFinder) {
               this.debouncedResidentialAddressChange();
            }
        },
        'student.postalAddress.postalCode': function () {
            if(!this.disableAutoAddressFinder){
                this.debouncedPostAddressChange();
            }
        },
        'student.postalAddress.houseNumber': function () {
           if(!this.disableAutoAddressFinder){
               this.debouncedPostAddressChange();
            }
        },
        postalAddressSameAsResidential(newValue, oldValue){
            if(!newValue){
                this.postalAddressIsValid = false;    
            }
        }
    },
    computed: {
        passwordConfirmationRule() {
            return () => (this.student.password === this.confirmPassword) || 'Wachtwoorden moeten overeenkomen'
        },
        formValid() {
            return this.student.userName &&
                this.student.email &&
                (this.postalAddressIsValid &&
                this.residentialAddressIsValid && 
                this.student.booksInPocession?.length > 0 ||
                this.student.booksInPocession?.length == 0);
        },
    },
    methods: {
        ...mapGetters(['getStudent']),
        ...mapGetters(['getAccessToken']),
        ...mapActions(['setStudentsAccount']),
        
        async loadStudentsAccount(){
            this.student = {
                residentialAddress:{ street:'', postalCode:'', houseNumber:'', street:'', additionHouseNumber:''},
                postalAddress:{ street:'', postalCode:'', houseNumber:'', street:'', additionHouseNumber:''},
                wallet: {iban:null, cardHolderName:''}
            };

            await this.loadAccount();

            if(this.student.photo){
                const base64Response = await fetch(this.student.photo);
                const blob = await base64Response.blob();
                this.imagePreview = URL.createObjectURL(blob);
            } else{
                this.imagePreview = NoUserImage;
            } 
        },

        async debouncedPostAddressChange() {
            clearTimeout(this.timeout); // Clear the existing timeout
            this.timeout = setTimeout(async () => {
                await this.postAddressChanged();
            }, 1000);
        },

        async debouncedResidentialAddressChange() {
            clearTimeout(this.timeout); // Clear the existing timeout
            this.timeout = setTimeout(async () => {
                await this.addressResidentialChanged();
            }, 1000);
        },
        
        async addressResidentialChanged(){
            try{
                var address = await AddressService.findAddress(
                this.getAccessToken(),
                this.student.residentialAddress.postalCode,
                this.student.residentialAddress.houseNumber,
                this.student.residentialAddress.additionHouseNumber);

                if(address && address.street){
                    this.student.residentialAddress.street = address.street;
                    this.student.residentialAddress.city = address.city;

                    this.residentialAddressIsValid = true;

                    if(this.postalAddressSameAsResidential){
                        this.postalAddressIsValid = true;
                    }
                }
                else{
                    this.student.residentialAddress.street = null;
                    this.student.residentialAddress.city = null;
                    this.residentialAddressIsValid = false;

                    if(this.postalAddressSameAsResidential){
                        this.postalAddressIsValid = false;
                    }
                }
            } catch(error){
                if(error.response.status == 404){
                    this.student.residentialAddress.street = null;
                    this.student.residentialAddress.city = null;
                }
                this.residentialAddressIsValid = false;
                
                if(this.postalAddressSameAsResidential){
                    this.postalAddressIsValid = false;
                }
            }       
        },

        uploadImage () {
            this.imagePreview = null;
            let compressor = this.$refs.compressor.$el;
            compressor.click();
        },

        async addressPostChanged(){      
            try{
                var address = await AddressService.findAddress(
                this.getAccessToken(),
                this.student.postalAddress.postalCode,
                this.student.postalAddress.houseNumber,
                this.student.postalAddress.additionHouseNumber);
            
                if(address && address.street){
                    this.student.postalAddress.street = address.street;
                    this.student.postalAddress.city = address.city;
                    this.postalAddressIsValid = true;
                }
                else{
                    this.student.postalAddress.street = null;
                    this.student.postalAddress.city = null;
                    this.postalAddressIsValid = false;
                }
            } catch(error){
                if(error.response.status == 404){
                    this.student.postalAddress.street = null;
                    this.student.postalAddress.city = null;
                }
                this.postalAddressIsValid = false;
            } 
        },
        removeImage(){
            this.imagePreview = null;
            if(this.student.photo){
                this.student.photo = null;
            }
        },
        
        async debouncedNameChange() {
            clearTimeout(this.timeoutName);
            this.timeoutName = setTimeout(async () => {
               await StudentService.updateStudentName(this.getAccessToken(), this.student.firstName);
            }, 1000);
        },

        async studentNameChanged(){
            await this.debouncedNameChange();
        },

        async debouncedSurnameChange() {
            clearTimeout(this.timeoutSurname);
            this.timeoutSurname = setTimeout(async () => {
               await StudentService.updateStudentSurname(this.getAccessToken(), this.student.lastName);
            }, 1000);
        },

        async studentSurnameChanged(){
            await this.debouncedSurnameChange();
        },

        residentialAddressChanged(){
            if(!this.disableAutoAddressFinder){
                if( this.student.residentialAddress.postalCode &&
                    this.student.residentialAddress.postalCode.length == 6 &&
                    this.student.residentialAddress.houseNumber && 
                    this.student.residentialAddress.houseNumber.length > 0 ){

                    this.disableAutoAddressFinder = true;
                    this.debouncedResidentialAddressChange();
                    this.disableAutoAddressFinder = false;
                }
                else{
                    this.student.residentialAddress.street = null;
                    this.student.residentialAddress.city = null;
                    this.residentialAddressIsValid = false;
                }
            }
        },

        async postAddressChanged(){
            if(!this.disableAutoAddressFinder){
                if( this.student?.postalAddress?.postalCode &&
                    this.student?.postalAddress?.postalCode.length == 6 && 
                    this.student?.postalAddress?.houseNumber && 
                    this.student?.postalAddress?.houseNumber.length > 0 ){

                    this.disableAutoAddressFinder = true;
                    this.addressPostChanged();
                    this.disableAutoAddressFinder = false;
                }
                else{
                    this.student.postalAddress.street = null;
                    this.student.postalAddress.city = null;
                    this.postalAddressIsValid = false;
                }
            }
        },

        async loadAccount() {
            try{
                this.errorMessage = null;
                this.loading = true;
                this.disableAutoAddressFinder = true;
                let account = await AccountService.getAccountOfStudent(this.getAccessToken());
                if(account){
                    this.student = account;
                    this.setStudentsAccount(this.student);

                    // tell the component to load the subscriptions, 
                    // since we have set the new student data in store
                    this.$refs.ref_subscriptionDetails.loadSubscriptions();
                }
                
                this.disableAutoAddressFinder = false;
                if (account){

                    this.loadUniversities();
                    this.loadCourses();

                    this.student = account;
        
                    if( this.student?.residentialAddress?.city == this.student?.postalAddress?.city &&
                        this.student?.residentialAddress?.houseNumber == this.student?.postalAddress?.houseNumber &&
                        this.student?.residentialAddress?.postalCode == this.student?.postalAddress?.postalCode &&
                        this.student?.residentialAddress?.additionHouseNumber == this.student?.postalAddress?.additionHouseNumber &&
                        this.student?.residentialAddress?.street == this.student?.postalAddress?.street){
                        
                        this.postalAddressSameAsResidential = true;
                    }
                    else{
                        this.postalAddressSameAsResidential = false;
                    }
                }
                else {
                    this.loadUniversities();
                    this.loadCourses();
                }

                this.postalAddressIsValid = this.postalAdressIsValid();
                this.residentialAddressIsValid = this.residentialAdressIsValid();

            }
            catch{
                this.errorMessage = 'Er is een fout opgetreden';
            }
            this.loading = false;
        },

        residentialAdressIsValid(){
            return  this.student.residentialAddress?.city != '' &&
                    this.student.residentialAddress?.street != '';
        },

        postalAdressIsValid(){
            return  this.student.postalAddress?.city != '' &&
                    this.student.postalAddress?.street != '';
        },

        async loadUniversities() {
            this.universities = await UniversityService.getUniversities();
        },
        async loadCourses() {
            this.courses = await CourseService.getCourses();
        },

        getFiles(obj){
            this.original = obj.original;
            this.compressed = obj.compressed;

            if(!this.imagePreview){
                if(this.original.file.size < 500000){
                    this.imagePreview = URL.createObjectURL(obj.original.file);
                    this.student.photo = obj.original.base64;
                    this.scale = 90;
                }
                else{
                    if(this.compressed.file.size < 100){
                        if(this.scale <= 90){
                            this.scale = this.scale + 5;
                        }

                        if(this.scale <= 0 || this.scale >= 95){
                            return;
                        }
                    }
                    else{
                        this.imagePreview = obj.compressed.blob;
                        this.student.photo = obj.compressed.base64;
                        this.scale = 90;
                    }
                }
            }
        },

        handleSameAddressAsPostAddress(){
            if(this.postalAddressSameAsResidential){
                this.student.postalAddress.city = this.student.residentialAddress.city;
                this.student.postalAddress.street = this.student.residentialAddress.street;
                this.student.postalAddress.houseNumber = this.student.residentialAddress.houseNumber;
                this.student.postalAddress.additionHouseNumber = this.student.residentialAddress.additionHouseNumber;
                this.student.postalAddress.postalCode = this.student.residentialAddress.postalCode;
            }
        },

        async update() {
            try{
                this.updateSuccess = false;
                this.errorMessage = null;
                this.loading = true;
                this.handleSameAddressAsPostAddress();
                var studentAccount = await StudentService.updateStudent(this.getAccessToken(), this.student);

                if(studentAccount){
                    this.setStudentsAccount(studentAccount);
                    this.updateSuccess = true;
                }

                await this.loadStudentsAccount();
            }
            catch{
                this.errorMessage = 'Er is een fout opgetreden';
            }
            this.loading = false;
      },

      isValidIBANNumber(input) {
            var CODE_LENGTHS = {
                AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
                CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
                FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
                HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
                LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
                MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
                RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26,   
                AL: 28, BY: 28, CR: 22, EG: 29, GE: 22, IQ: 23, LC: 32, SC: 31, ST: 25,
                SV: 28, TL: 23, UA: 29, VA: 22, VG: 24, XK: 20
            };
            var iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''), // keep only alphanumeric characters
                    code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
                    digits;
            // check syntax and length
            if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
                return false;
            }
            // rearrange country code and check digits, and convert chars to ints
            digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
                return letter.charCodeAt(0) - 55;
            });
            // final check
            return (this.mod97(digits) > 0);
        },
        mod97(string) {
            var checksum = string.slice(0, 2), fragment;
            for (var offset = 2; offset < string.length; offset += 7) {
                fragment = String(checksum) + string.substring(offset, offset + 7);
                checksum = parseInt(fragment, 10) % 97;
            }
            return checksum;
        }
    },
    components: {
         UserMenuCard,
         SubscriptionDetails,
         imageCompressor,
         CloseAccount
    }
  }
</script>
