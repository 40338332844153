<template>
    <v-row class="text-center">
            <v-col>
                <h1 class="display-2 font-weight-bold mb-3 title-bookshare">
                    <div>Top traders van deze week</div>
                </h1>
            </v-col>
       <stack 
            ref="stackRef"
            @images-loaded="imagesLoaded"
            :column-min-width="imageSize()"
            :gutter-width="imageGutter()"
            :gutter-height="imageGutter()">

            <div v-if="imagesLoaded">
                <ImageStack :item="imageStack" v-for="(imageStack, index) in imageStacks" :key="index"></ImageStack>
            </div>
        </stack>
    </v-row>
</template>

<script>
import BookService from '../../services/PublishedBookService'
import AdvertisementService from '../../services/AdvertisementService'

import { Stack } from "vue-stack-grid";
import ImageStack from "./ImageStack.vue"

export default {
    name: 'Home',
    cardHeights: [200, 300, 400],
    components: {
        Stack,
        ImageStack
    },
	data() {
		return {
            studentBooks: {},
            loading: null,
            cardHeights: [200, 300, 400],
            // pbImages:[],
            imagesLoaded: false,
            advertisements:[],
            imageStacks:[]
        }
	},
    watch:{
        imagesLoaded: function(newValue){
            return newValue;
        }
    },
    async mounted() {
        this.imagesLoaded = false;
        await this.loadTopViews()
        .then((books) => {
            this.studentBooks = books;
            setTimeout(() => {  
                this.$refs.stackRef.reflow(); }, 1000);
                this.imagesLoaded = true;
        });

        this.$refs.stackRef.reflow();
    },
    methods: {
        async loadTopViews() {
            var allBooks;
            await BookService.getPopularBooks(0,12).then((books)=> {
                allBooks = books;
            });

            allBooks.forEach(book => {
                this.imageStacks.push({id:book.id, title:book.title, imageFileName:book.imageFileName, isBook:true})
            });

            let data = await AdvertisementService.getAdvertisements(5);
            let advertisements = data.advertisements;
            
            let index = 2;
            advertisements.forEach(add => {
                this.imageStacks.splice(index,0, {
                        id:add.id, title:add.title, imageFileName:add.image, subtitle:add.subtitle, redirectUrl:add.redirectUrl, isBook:false
                    })
                    index = index+3;    
                });

            return allBooks;
        },
        imageSize(){
            if(this.$vuetify.breakpoint.xs) return 150;
            else if(this.$vuetify.breakpoint.sm) return 200;
            else if(this.$vuetify.breakpoint.md) return 250;
            else if(this.$vuetify.breakpoint.lg) return 250;
            else if(this.$vuetify.breakpoint.xl) return 250;
        },
        imageGutter(){
            if(this.$vuetify.breakpoint.xs) return 10;
            else if(this.$vuetify.breakpoint.sm) return 20;
            else if(this.$vuetify.breakpoint.md) return 40;
            else if(this.$vuetify.breakpoint.lg) return 40;
            else if(this.$vuetify.breakpoint.xl) return 40;
        }
    }
}
</script>

<style scoped>

.container {
  width: 80vw;
  margin: 0 auto;
}
.button-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}
.btn {
  font-size: 18px;
  background-color: #42b983;
  color: white;
  padding: 10px 20px;
}
.btn:not(:last-child) {
  margin-right: 10px;
}
img {
  width: 100%;
  height: auto;
  border-radius: 2px;
}
.img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: center;
  overflow: hidden;
  margin: 0;
  object-fit: fill;
  cursor: pointer;
}
.img-container img {
  display: block;
  margin: 0;
  width: 100%;
  height: auto;
}
.img-container figcaption {
  margin: 3px 0;
  text-align: center;
}
</style>