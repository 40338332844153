import axios from "../plugins/axios";

const baseUrl = `${process.env.VUE_APP_BOOKSHARE_URL}/companies/admin/`;

export default {

    async getCompanies(token, pageSize, pageNumber) {
        let result;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        let data = {
          "pageSize":  pageSize,
          "pageNumber": pageNumber
        }

        await axios.post(`${baseUrl}companies`, data, config)
                .then(response =>
                    result = response.data);

        return result;
    },

    async addCompany(token, name, address, about, numberOfEmployees, urlLogo, url) {
        let result;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        let data = { 
            company: {
                name:name,
                address:address,
                about, about,
                numberOfEmployees,
                urlLogo: urlLogo,
                url:url
            }
        }
        
        await axios.post(`${baseUrl}add-company`, data, config)
                .then(response =>
                    result = response.data);

        return result;
    },
}
