import axios from "../plugins/axios";

const baseUrl = process.env.VUE_APP_BOOKSHARE_URL+'/conversations';

export default {
    async createUserMessage(token, to, text, book){
        let result;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        let payload={to:to, text:text, book:book};
        await axios.post(`${baseUrl}/create-user-message`, payload, config)
            .then(response =>
                result = response.data);
        return result;
    },

    async getUserMessages(token, conversation, pageNumber, pageSize){
        let result;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        let payload={conversation:conversation, pageNumber:pageNumber, pageSize:pageSize};
        await axios.post(`${baseUrl}/get-user-messages`, payload, config)
                .then(response =>
                    result = response.data);
        return result;
    },

    async getMyConversations(token, pageNumber, pageSize){
        let result;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        let payload={pageNumber:pageNumber, pageSize:pageSize};
        await axios.post(`${baseUrl}/my-conversations`, payload, config)
                .then(response =>
                    result = response.data);
        return result;
    },

    async getConversation(token, to){
        let result;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        let payload={to:to};
        await axios.post(`${baseUrl}/get-conversation`, payload, config)
                .then(response =>
                    result = response.data);
        return result;
    },

    async getAdminConversations(token, pageNumber, pageSize){
        let result;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let payload = {
            "pageNumber":pageNumber, 
            "pageSize":pageSize
        };

        await axios.post(`${baseUrl}/admin/get-conversations`, payload, config)
                .then(response =>
                    result = response.data);
        return result;
    },

    async getNumberofUnreadMessages(token){
        let result;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        let payload={};
        await axios.post(`${baseUrl}/number-of-unread-messages`, payload, config)
                .then(response =>
                    result = response.data);
        return result;
    },

    async hasReadConversation(token, conversation){
        let result;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        let payload = { conversation:conversation };
        await axios.post(`${baseUrl}/has-readconversation`, payload, config)
                .then(response =>
                    result = response.data);
        return result;
    },
}
