<template>
    <v-form class="mb-4">
        <v-row align="center" justify="center">
            <v-col cols="11" md="4">
                <v-card v-if="isResultAvailable" class="pb-4" align="center" justify="center">
                    <v-row justify="center" align="center">
                        <v-card-title>Je wachtwoord resetten</v-card-title>
                        <v-card-text class="ma-2">{{resultText}}</v-card-text>
                        <v-card-text>Terug naar <router-link class="mx-1" to="/">Home</router-link></v-card-text>
                     </v-row>
                </v-card>
                <v-card v-if="!isResultAvailable" class="pb-4" align="center" justify="center">
                    <v-row justify="center" align="center">
                        <v-card-title>Je wachtwoord resetten</v-card-title>
                    </v-row>
                    <v-row justify="center" align="center">
                        <v-col cols="10" md="10">
                            <v-text-field 
                            v-model="username"
                            :rules="[rules.required]"
                            name="Gebruikersnaam"
                            label="Gebruikersnaam"
                            hint="Wat is je gebruikersnaam?"
                            outlined>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row justify="center" align="center" class="mt-n6">
                        <v-col cols="10" md="10">
                            <v-btn
                                    block
                                    color="primary"
                                    :loading="loading"
                                    :disabled="!formValid || loading"
                                    @click="resetPassword()">
                                    RESET WACHTWOORD
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-form>
</template>
<script>

import AccountService from "../services/AccountService"
export default {

    data () {
      return {
        rules: {
          required: value => !!value || 'Required.', 
        },
        username: null,
        resultAvailable: false,
        resultText: null,
        loading: false
      }
    },
    computed:{
        formValid() {
            return (this.username && this.username.length > 4);
        },
        passwordConfirmationRule() {
            return () => (this.password === this.confirmPassword) || 'Wachtwoorden moeten overeenkomen';
        },
        isResultAvailable(){
            return this.resultAvailable;
        }
    },
    methods:{
        async resetPassword() {
            try{
                this.loading = true;
                this.resultText = null;
                await AccountService.resetPasswordRequest(this.username)
                this.resultAvailable = true;
                this.resultText = "We hebben je een e-mail gestuurd met een link om je wachtwoord te resetten. " +
                "Gebruik deze link om een nieuw wachtwoord te maken.";
            }
            catch{
                this.resultText = 'Er is een fout opgetreden.';
            }
            this.loading = false;
        }
    }
}
</script>
