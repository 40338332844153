<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="12" class="px-0 md-0">
                <v-card class="pl-4 my-4" v-for="(job, index) in jobs" :key="index">
                    <v-row>
                        <v-col cols="4">
                            Name
                        </v-col>
                        <v-col>
                            {{job.name}}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="4">
                            Description
                        </v-col>
                         <v-col>
                            <span class="d-inline-block text-truncate" style="max-width: 150px;"> 
                                {{job.description}}
                            </span>
                        </v-col>
                    </v-row>
                    <v-row>
                         <v-col cols="4">
                            From
                        </v-col>
                        <v-col>
                            {{getFriendlyDate(job.from)}}
                        </v-col>
                    </v-row>
                    <v-row>
                         <v-col cols="4">
                            End
                        </v-col>
                        <v-col>
                            {{getFriendlyDate(job.end)}}
                        </v-col>
                    </v-row>
                    <v-row>
                         <v-col cols="4">
                            Type
                        </v-col>
                        <v-col>
                            {{getTypeOfWork(job.jobType)}}
                        </v-col>
                    </v-row>
                    <v-row>
                         <v-col cols="4">
                            Locatie
                        </v-col>
                        <v-col>
                            {{getWorkLocation(job.workLocation)}}
                        </v-col>
                    </v-row>
                     <v-row>
                         <v-col cols="4">
                            Referentie
                        </v-col>
                        <v-col>
                            {{job.reference}}
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-row justify="end" class="my-4">
                     <a>
                        <button class="mr-4" @click="loadMore()">meer laden <v-icon small color="primary">mdi-reload</v-icon></button>
                     </a>
                </v-row>
                <v-row justify="end">
                    <v-btn @click="showDialog = true" color="primary" class="mt-4">
                        TOEVOEGEN
                    </v-btn>
                </v-row>
                <v-row>
                     <v-dialog v-model="showDialog" width="600">
                        <template>
                            <v-card class="pa-8" >
                                <v-row justify="end" class="ma-2">
                                    <v-card-actions>
                                        <v-btn text @click="showDialog = false">
                                            <v-icon>
                                                mdi-close
                                            </v-icon>
                                        </v-btn>
                                    </v-card-actions>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-text-field v-model="name" placeholder="Name of the job"></v-text-field>
                                    </v-col>
                                     <v-col>
                                        <v-text-field v-model="reference" placeholder="Reference"></v-text-field>
                                    </v-col>
                                </v-row>
                                 <v-row>
                                    <v-col cols="12" md="6">
                                        <v-text-field v-model="nameContactPerson" placeholder="Naam contactpersoon"></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field v-model="surnameContactPerson" placeholder="Achternaam contactpersoon"></v-text-field>
                                    </v-col>
                                     <v-col cols="12">
                                        <v-text-field v-model="emailContactPerson" placeholder="Email contactpersoon"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                         <v-select
                                            v-model="selectedTypeOfJob"
                                            :items="typeOfJobs"
                                            item-text="name"
                                            item-value="id"
                                            label="Type werk"
                                        ></v-select>
                                    </v-col>
                                    <v-col>
                                         <v-select
                                            v-model="selectedTypeOfWorkLocation"
                                            :items="typeOfWorkLocation"
                                            item-text="name"
                                            item-value="id"
                                            label="Werklocatie"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-menu
                                            v-model="showmMenuStartDate"
                                            :close-on-content-click="true"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-model="from"
                                                    label="Startdatum"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    :rules="[rules.required]" 
                                                    v-on="on"
                                                    dense
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker 
                                                v-model="from" 
                                                no-title 
                                                scrollable
                                                dense
                                                @input="showmMenuStartDate = false;">
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-menu
                                            v-model="showmMenuEndDate"
                                            :close-on-content-click="true"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-model="end"
                                                    label="Eind datum"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    :rules="[rules.required]" 
                                                    v-on="on"
                                                    dense
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker 
                                                v-model="end" 
                                                no-title 
                                                scrollable
                                                dense
                                                @input="showmMenuEndDate = false;">
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-select class="mt-n1"
                                            v-model="selectedSkills"
                                            :items="skills"
                                            item-text="name"
                                            item-value="id"
                                            chips
                                            label="Skills"
                                            multiple
                                        ></v-select>
                                    </v-col>
                                    <v-col>
                                        <v-select
                                            v-model="selectedCompany"
                                            :items="companies"
                                            item-text="name"
                                            item-value="id"
                                            label="Company"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                     <v-col>
                                        <v-select
                                            v-model="selectedCategories"
                                            :items="categories"
                                            item-text="name"
                                            item-value="id"
                                            label="Werkgebied"
                                            multiple
                                            chips
                                        ></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <!-- <v-textarea v-model="description" placeholder="Description of the job"></v-textarea> -->
                                        <vue-editor placeholder="Write Something..." v-model="description"></vue-editor>
                                    </v-col>
                                </v-row>
                                <v-row justify="end">
                                    <v-btn block color="primary" @click="addJob()" :disabled="!formValid || loading" :loading="loading">
                                        ADD
                                    </v-btn>
                                </v-row>
                            </v-card>
                        </template>
                    </v-dialog>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import JobService from '../../../services/JobService'
import SkillService from '../../../services/SkillService'
import CompanyService from '../../../services/CompanyService'
import { VueEditor } from "vue2-editor";

export default {
    props:['loadedCompanies'],
    data () {
        return {
            loading:false,
            errorMessage: null,
            jobs:[],
            skills:[],
            companies:[],
            categories:[],
            name:null,
            description: null,
            from:null,
            end:null,
            nameContactPerson: '',
            surnameContactPerson: '',
            emailContactPerson: '',
            reference: '',
            selectedCompany:null,
            selectedSkills:null,
            selectedCategories:null,
            selectedTypeOfWorkLocation:null,
            selectedTypeOfJob:null,
            showmMenuStartDate: false,
            showmMenuEndDate: false,
            pageNumber: 0,
            pageSize:5,
            showDialog: false,
            typeOfJobs:[{id:0, name:"Parttime"}, {id:1, name:"Fulltime"}, {id:2, name:"Freelance"}],
            typeOfWorkLocation:[{id:0, name:"Remote"}, {id:1, name:"Location"}, {id:2, name:"Hybrid"}],
            rules: {
                required: value => !!value || 'Verplicht.',
            }
        }
    },
    async created() {
        await this.getSkills();
        await this.getCompanies();
        await this.getJobs();
        await this.getCategories();
    },

    watch: {
        loadedCompanies: {
            handler: function() {
                this.getCompanies();
            },
        },
    },

    computed: {
        formValid() {
            return (this.name != null && 
            this.selectedTypeOfJob != null && 
            this.selectedTypeOfWorkLocation != null &&
            this.from != null &&
            this.end != null &&
            this.selectedSkills != null && this.selectedSkills.length >0 &&
            this.selectedCompany != null&& 
            this.selectedCategories != null && this.selectedCategories.length > 0 &&
            this.nameContactPerson &&
            this.surnameContactPerson &&
            this.emailContactPerson &&
            this.description != null);
        }
    },

    components:{
        VueEditor
    },

    methods:{
        ...mapGetters(['getAccessToken']),
        async getSkills(){
            var result = await SkillService.getSkills(this.getAccessToken(), 100, 0);
            this.skills = result.skills;
        },

        async getCompanies(){
            var result = await CompanyService.getCompanies(this.getAccessToken(), 100, 0);
            this.companies = result.companies;
        },
        
        async getCategories(){
            var result = await JobService.getCategories(this.getAccessToken());
            this.categories = result.jobCategories;
        },

        async getJobs(){
            try{
                this.errorMessage = null;
                this.loading = true;

                var result = await JobService.getJobs(this.pageSize, this.pageNumber);
                this.jobs = result.jobs;

            } catch {
                this.errorMessage = 'Er is helaas een fout opgetreden.';
                console.error('getSkills failed.');
            }
            this.loading = false;
        },

        async loadMore(){
            var newPageSize = this.pageSize + 10;
            this.pageSize = newPageSize;
            await this.getJobs();
        },
        getWorkLocation(workLocation){
            if(workLocation === 0) return "Remote";
            else if(workLocation === 1) return "Op locatie";
            else if(workLocation === 2) return "Hybrid";
        },
        getTypeOfWork(type){
            if(type === 0) return "Parttime";
            else if(type === 1) return "Fulltime";
            else if(type === 2) return "Freelance";
        },
        getFriendlyDate(val){
            var date = new Date(val);
            return date.getDate()  + "-" + (date.getMonth()+1) + "-" + date.getFullYear();
        },
        async addJob(){
            try{
                this.errorMessage = null;
                this.loading = true;

                await JobService.addJob(this.getAccessToken(), 
                this.name, 
                this.description, 
                this.from, 
                this.end, 
                this.selectedCompany, 
                this.selectedSkills,
                this.selectedTypeOfJob,
                this.selectedCategories,
                this.selectedTypeOfWorkLocation,
                this.nameContactPerson,
                this.surnameContactPerson,
                this.emailContactPerson,
                this.reference);
                
                await this.getJobs();
                this.showDialog = false;

            } catch {
                this.errorMessage = 'Er is helaas een fout opgetreden.';
            }
            this.loading = false;
        },
    }
}
</script>

<style lang="css">
@import "~vue2-editor/dist/vue2-editor.css";

/* Import the Quill styles you want */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

</style>