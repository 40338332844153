<template>
    <v-container>
        <v-row>
            <v-col cols="3" class="hidden-sm-and-down">
                <UserMenuCard></UserMenuCard>
            </v-col>
            <v-col class="mt-3" md="9" cols="12">
                <v-row v-if="errorMessage"> 
                    <v-col cols="12" md="12" class="mt-0 pt-0">
                        <v-card flat class="ma-0 pa-0" color="secondary">
                            <v-card-text v-if="errorMessage">
                                {{errorMessage}}
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col class="my-8" v-if="loading">
                        <v-progress-circular
                        indeterminate
                        color="primary">
                        </v-progress-circular>
                    </v-col>
                </v-row>
                <v-row class="mb-4">
                    <v-col cols="12">
                        <SubsscriptionLimitsimport :onReload="numberOfMessagesSent"></SubsscriptionLimitsimport> 
                        <v-card flat>
                            <v-row>
                                <v-col cols="12" md="2">
                                    <v-card-title v-if="displayUser">
                                        <v-img :src="imagePreview" max-width="80">
                                        </v-img>
                                    </v-card-title>
                                </v-col>
                                <v-col>
                                    <v-row>
                                        <v-col>
                                            <v-card-subtitle>
                                                <v-row class="font-weight-bold">
                                                    <v-col v-if="displayUser" cols="8" class="py-0 headline">
                                                       {{displayUser.firstName}} {{displayUser.lastName}}
                                                    </v-col>
                                                </v-row>
                                            </v-card-subtitle>
                                        </v-col>
                                    </v-row>
                                    <v-row class="pt-0">
                                        <v-col class="pt-0">
                                            <v-card-text>
                                                <v-row>
                                                    <v-col cols="3" class="py-0 font-weight-medium">
                                                        Boek:
                                                    </v-col>
                                                    <v-col class="py-0">
                                                        {{book.publishedBook.title}}
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                            <v-card-text class="py-1">
                                                <v-row>
                                                    <v-col cols="3" class="py-0 font-weight-medium">
                                                        ISBN:
                                                    </v-col>
                                                    <v-col class="py-0">
                                                        {{book.publishedBook.isbn}}
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-card-text>
                        Voor je bescherming verloopt deze conversatie via onze server en worden e-mailadressen afgeschermd. 
                        Boekshare behoudt zich het recht om conversaties te filteren en te staken om de veiligheid 
                        van handelen te vergroten. 
                    </v-card-text>
                </v-row>
                <v-card class="my-8" flat v-if="conversationResult">
                    <v-row class="mb-4" v-for="(userMessage, index) in conversationResult.userMessages" :key="index">
                        <v-col>
                            <v-row class="py-0 my-0" v-if="getStudent().id === userMessage.to">
                                <v-col cols="11" md="6" class="py-0 my-0">
                                    <v-col class="py-0 my-0">
                                        <v-card class="py-0 mb-2 rounded-xl" outlined flat>
                                            <v-row>
                                                <v-col cols="12" md="7" class="py-0">
                                                    <v-card-text>
                                                        {{userMessage.text}}
                                                    </v-card-text>
                                                </v-col>
                                            </v-row>
                                            <v-row class="ma-0 pa-0" justify="end">
                                                <v-col cols="6" md="4" class="ma-0 pa-0">
                                                    <v-card-text class="ma-0 pa-0">
                                                        {{getFriendlyTime(userMessage.created)}}
                                                    </v-card-text>
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                    </v-col>
                                </v-col>
                                <v-col class="py-0 my-0 .d-none .d-sm-flex">
                                </v-col>
                            </v-row>
                            <v-row class="py-0 my-0 mr-1" v-if="getStudent().id !== userMessage.to">
                                <v-col class="py-0 my-0">
                                </v-col>
                                <v-col class="py-0 my-0" cols="11" md="6">
                                    <v-card color="secondary" class="py-0 my-0 rounded-xl" outlined flat>
                                        <v-row>
                                            <v-col class="py-0">
                                                <v-card-text>
                                                    {{userMessage.text}}
                                                </v-card-text>
                                            </v-col>
                                        </v-row>
                                        <v-row class="ma-0 pa-0">
                                            <v-col cols="6" md="8">
                                            </v-col>
                                            <v-col class="ma-0 pa-0">
                                                <v-card-text class="ma-0 pa-0">
                                                    {{getFriendlyTime(userMessage.created)}}
                                                </v-card-text>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <!-- </v-row> -->
                        </v-col>
                    </v-row>
                </v-card>
                 <v-row>
                    <v-col class="pt-0 mb-4">
                        <v-row>
                            <v-col>
                                <v-form flat class="pt-0">
                                    <v-card flat class="pt-0 mt-0">
                                    <v-card-title>Bericht:</v-card-title>
                                    <v-card-text>
                                        <v-textarea color="secondary" v-model="text" class="py-0 my-0" rows="4" auto-grow outlined></v-textarea>
                                    </v-card-text>
                                    </v-card>
                                </v-form>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-row justify="end">
                                    <v-btn color="primary" :loading="loading" :disabled="limitReached" class="mr-3" @click="sendMessage()">
                                        VERSTUREN
                                    </v-btn>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
import { mapActions, mapGetters } from 'vuex'
import UserMenuCard from '../components/UserMenuCard.vue'
import ConversationService from '../services/ConversationService'
import SubsscriptionLimitsimport from '../components/SubscriptionLimitsState.vue';
import NoAccount from "../assets/img/no-user.png";

export default {
    props: ['book', 'conversation', 'to'],
    data () {
        return {
            loading:false,
            errorMessage: null,
            messages: null,
            userMessages:null,
            conversationResult:null,
            displayUser:null,
            text:'',
            numberOfMessagesSent:0,
            imagePreview: null,
            limitReached: true
        }
    },
    async created() {
        await this.findConversation();
        await this.readConversation();
    },

    components:{
        UserMenuCard,
        SubsscriptionLimitsimport,
    },
    
    watch:{
        limitReached: function(val){
        }
    },

    methods: {
        ...mapGetters(['getAccessToken']),
        ...mapGetters(['getStudent']),
        ...mapGetters(['hasValidSubscription']),
        ...mapActions(['increaseNumberOfSendMessages']),
        async readConversation(){
           if(this.conversationResult) {
                try{
                    this.errorMessage = null;
                    this.loading = true;
                    await ConversationService.hasReadConversation(this.getAccessToken(), this.conversationResult.id);
                    
                    if(this.getStudent().maximumNumberOfMessagesAllowedToSend !== -1 && this.getStudent().maximumNumberOfMessagesAllowedToSend !== '-1'){
                        this.limitReached = (this.getStudent().maximumNumberOfMessagesAllowedToSend - this.getStudent().numerOfMessagesSentInPeriod) <= 0;
                    } else{
                        this.limitReached = false;
                    }
                } catch{
                    this.errorMessage = 'Er is helaas een fout opgetreden.';
                }
                this.loading = false;
            }
        },

        async findConversation(){
           try{
                this.errorMessage = null;
                this.loading = true;
                this.displayUser = this.findStudent();
                this.setPhotoOfUser();
                let result = await ConversationService.getConversation(this.getAccessToken(), this.displayUser.id);
                this.conversationResult = result.conversation;
                
                if(this.getStudent().maximumNumberOfMessagesAllowedToSend !== -1 && this.getStudent().maximumNumberOfMessagesAllowedToSend !== '-1'){
                    this.limitReached = (this.getStudent().maximumNumberOfMessagesAllowedToSend - this.getStudent().numerOfMessagesSentInPeriod) <= 0;
                } else{
                    this.limitReached = false;
                }

            } catch {
                this.errorMessage = 'Er is helaas een fout opgetreden.';
            }
            this.loading = false;
        },

         async setPhotoOfUser(){
            if(this.displayUser.photo){
                const base64Response = await fetch(this.displayUser.photo);
                const blob = await base64Response.blob();
                this.imagePreview = URL.createObjectURL(blob);
            } else{
                this.imagePreview = NoAccount;
            } 
        },

        getFriendlyTime(val){
            var date = new Date(val);
            return date.getDate()  + "-" + (date.getMonth()+1) + "-" + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes();
        },

        findStudent(){
            if(this.conversation){
                if(this.conversation.to.id === this.getStudent().id){
                    return this.conversation.from;
                } else{
                    return this.conversation.to;
                }
            } else{
                if(this.to){
                    return this.to;
                }
            }
        },

        async sendMessage(){
            try{
                this.errorMessage = null;
                this.loading = true;
                var student = this.findStudent();

                await ConversationService.createUserMessage(this.getAccessToken(), student.id, this.text, this.book.id);
                this.text = '';
                this.findConversation();
                this.increaseNumberOfSendMessages();
                this.numberOfMessagesSent = this.numberOfMessagesSent + 1;
                this.limitReached = (this.getStudent().maximumNumberOfMessagesAllowedToSend - this.getStudent().numberOfMessagesSent) <= 0;
                // the user with subscription will see the result of the message directly in the history
                if(!this.conversationResult){
                    this.errorMessage = 'Je bericht is verstuurd.';
                }
            } catch{
                this.errorMessage = 'Er is helaas een fout opgetreden.';
            }

            this.loading = false;
        }
    }
}
</script>