import axios from "../plugins/axios";

const baseUrl = process.env.VUE_APP_BOOKSHARE_URL;

export default {
    async findAddress(token, postalCode, houseNumber, addition) {
        let address;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        let data = {
            postalCode: postalCode,
            houseNumber: houseNumber,
            addition: addition
        };

        await axios.post(`${baseUrl}/address/find-address`, data, config)
                .then(response =>
                    address = response.data.address);
                    
        return address;
    },
}
