import axios from "../plugins/axios";

const baseUrl = `${process.env.VUE_APP_BOOKSHARE_URL}/shipments`;

export default {
  async updateShipmentStatus(token, shipmentId, phaseCode, statusCode, newTime) {
    let result;
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let data = { 
      shipmentId: shipmentId,
      statusCode: statusCode.id,
      statusDescription: statusCode.text,
      phaseCode: phaseCode.id,
      phaseDescription: phaseCode.text,
      newTime: newTime
    };
    await axios.post(`${baseUrl}/admin/change-status`, data, config)
            .then(response =>
              result = response.data);
    return result;
  },

  async changeShipmentTime(token, shipmentId, newTime) {
    let result;
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let data = { 
      shipmentId: shipmentId,
      newTime: newTime,
    };
    await axios.post(`${baseUrl}/admin/change-shipment-time`, data, config)
            .then(response =>
              result = response.data);
    return result;
  },
  
  async getShipmentById(token, shipmentId) {
    let result;
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let data = { id: shipmentId };
    await axios.post(`${baseUrl}/admin/get-shipment-by-id`, data, config)
            .then(response =>
              result = response.data);
    return result;
  },

  async getShipmentById(token, shipmentId) {
    let result;
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let data = { id: shipmentId };
    await axios.post(`${baseUrl}/admin/get-shipment-by-id`, data, config)
            .then(response =>
              result = response.data);
    return result;
  },

  async getShipments(token, from, to, pageNumber, pageSize) {
    let result;
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let data = { from: from, to:to, pageNumber:pageNumber, pageSize:pageSize };
    await axios.post(`${baseUrl}/admin/shipments`, data, config)
            .then(response =>
              result = response.data);
    return result;
  },

  async updateOpenShipments(token) {
    let result;
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let data = {};
    await axios.post(`${baseUrl}/admin/update-shipments`, data, config)
            .then(response =>
              result = response.data);
    return result;
  },

  async receivedShipment(token, shipmentId) {
    let result;
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let data = {shipmentId:shipmentId};
    await axios.post(`${baseUrl}/received-shipment`, data, config)
            .then(response =>
              result = response.data);
    return result;
  },      

  async getUnconfirmedShipments(token, from, to, pageNumber, pageSize){
    let result;
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let data = { from: from, to:to, pageNumber:pageNumber, pageSize:pageSize };
    await axios.post(`${baseUrl}/admin/get-unconfirmed-shipments`, data, config)
            .then(response =>
              result = response.data);
    return result;
  },      
}