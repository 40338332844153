<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" class="pa-4 ma-4">
                <v-row v-for="(skill, index) in skills" :key="index">
                    <v-col cols="4">
                        {{skill.name}}
                    </v-col>
                    <v-col class="mx-2">
                        {{skill.description}}
                    </v-col>
                </v-row>
                <v-row justify="end" class="mr-4">
                     <a>
                        <button @click="loadMore()">>> (meer laden)</button>
                     </a>
                </v-row>
                <v-row justify="end">
                    <v-btn @click="showDialog = true" color="primary" class="mt-4 mr-4">
                        TOEVOEGEN
                    </v-btn>
                </v-row>
                <v-row>
                    <v-dialog v-model="showDialog" width="500">
                        <template>
                            <v-card>
                                <v-row justify="end" class="ma-2">
                                    <v-card-actions>
                                        <v-btn text @click="showDialog = false">
                                            <v-icon>
                                                mdi-close
                                            </v-icon>
                                        </v-btn>
                                    </v-card-actions>
                                </v-row>
                                <v-col cols="12">
                                    <v-text-field v-model="skillName" placeholder="Name of the skill"></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field v-model="skillDescription" placeholder="Description of the skill"></v-text-field>
                                </v-col>
                                <v-col cols="2" md="12" class="mt-6 pr-6 pb-6">
                                    <v-row justify="end">
                                        <v-btn color="primary" @click="addSkill()">
                                            ADD
                                        </v-btn>
                                    </v-row>
                                </v-col>
                            </v-card>
                        </template>
                    </v-dialog>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import SkillService from '../../../services/SkillService'

export default {
    data () {
        return {
            loading:false,
            errorMessage: null,
            skills:[],
            skillName:'',
            skillDescription:'',
            pageNumber: 0,
            pageSize:5,
            showDialog:false
        }
    },
    async created() {

        await this.getSkills();
    },
    methods:{
        ...mapGetters(['getAccessToken']),
        async getSkills(){
            try{
                this.errorMessage = null;
                this.loading = true;

                var result = await SkillService.getSkills(this.getAccessToken(), this.pageSize, this.pageNumber);
                this.skills = result.skills;

            } catch {
                this.errorMessage = 'Er is helaas een fout opgetreden.';
                console.error('getSkills failed.');
            }
            this.loading = false;
        },

        async loadMore(){
            var newPageSize = this.pageSize + 10;
            this.pageSize = newPageSize;
            await this.getSkills();
        },

        async addSkill(){
            try{
                this.errorMessage = null;
                this.loading = true;

                await SkillService.addSkill(this.getAccessToken(), this.skillName, this.skillDescription);
                await this.getSkills();

                this.showDialog = false;

            } catch {
                this.errorMessage = 'Er is helaas een fout opgetreden.';
            }
            this.loading = false;
        },
    }
}
</script>