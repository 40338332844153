import Vue from 'vue';
import axios from 'axios';
import router from '../router'
import store from '../store'

axios.interceptors.request.use(
  (request) => {
    // eslint-disable-next-line no-param-reassign
    request.config = {
      showToast: false, // may be overwritten in next line
      ...(request.config || {}),
      start: Date.now(),
    };

    if (request.config.showToast) {
      // eslint-disable-next-line no-param-reassign
      request.config.requestToastId = Vue.$toast(
        request.config.requestToast.title,
      );
    }

    return request;
  },
);

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== "/login" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const baseUrlIAA = process.env.VUE_APP_IAA_BOOKSHARE_URL;
          const rs = await axios.post(`${baseUrlIAA}/User/refresh-token`, {
            refreshToken: store.getters.getRefreshToken,
          });
          const login = rs.data;
          if(login){
            store.dispatch('setSuccessfulLogin', login);
          } else{
            router.push('/login');
          }
          return axios(originalConfig);
        } catch (_error) {
          store.dispatch('logout');
          window.localStorage.removeItem('_secure__ls__metadata');
          window.localStorage.removeItem('vuex');
          router.push('/login');
          return Promise.reject(_error);
        }
      }
    } 
    return Promise.reject(err);
  }
);

export default axios;