<template>
    <v-container>
        <v-row>
            <v-breadcrumbs  divider="-">
                <v-breadcrumbs-item href="/">&lt; terug naar Home</v-breadcrumbs-item>
            </v-breadcrumbs>
        </v-row>
        <v-row>
            <v-row class="mb-3 ml-4 mt-4">
                <h2>Ik bied aan:</h2>
            </v-row>
        </v-row>
        <v-row>
            <v-row class="mb-4">
                <v-col cols="12" md="8" lg="8">
                    <v-row class="d-md-flex">
                        <v-col cols="12">
                            <v-card min-width="250" class="pa-6 mb-4" v-for="(book, index) in booksOfStudent" :key="index">
                                <v-row>
                                    <v-card-text>
                                        <v-img max-width="150" :src="book.publishedBook.imageFileName"></v-img>
                                    </v-card-text>
                                    <v-card-text>
                                        <v-row class="pb-0">
                                            <v-col class="pb-0">
                                                <v-card-text class="font-weight-bold pb-0">{{book.publishedBook.title}}</v-card-text>
                                            </v-col>      
                                        </v-row>
                                        <v-row class="py-0">
                                            <v-col class="py-0">
                                                <v-card-text class="py-0">ISBN:</v-card-text>
                                            </v-col>
                                            <v-col class="py-0">
                                                <v-card-text class="py-0">{{book.publishedBook.isbn}}</v-card-text>
                                            </v-col>
                                        </v-row>
                                         <v-row class="py-0">
                                            <v-col class="py-0">
                                                <v-card-text class="py-0">Auteur:</v-card-text>
                                            </v-col>
                                            <v-col class="py-0">
                                                <v-card-text class="py-0">{{book.publishedBook.edition}}</v-card-text>
                                            </v-col>
                                        </v-row>
                                        <v-row class="py-0">
                                            <v-col class="py-0">
                                                <v-card-text class="py-0">Druk:</v-card-text>
                                            </v-col>
                                            <v-col class="py-0">
                                                <v-card-text class="py-0">{{book.publishedBook.author}}</v-card-text>
                                            </v-col>
                                        </v-row>
                                        <v-row class="pt-0">
                                            <v-col class="pt-0">
                                                <v-card-text class="py-0">Prijs:</v-card-text>
                                            </v-col>
                                            <v-col class="pt-0">
                                                <v-card-text class="pt-0">€{{book.price}}</v-card-text>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-btn block color="primary" v-if="(book.state.state === 1) || (book.state.state === 0 && isReservationExpired(book))" @click="addToShoppingCart(book)">
                                        <div>IN WINKELWAGEN</div>
                                    </v-btn>
                                    <v-btn block color="primary" disabled="true" v-if="((book.state.state === 0) && !isReservationExpired(book))" @click="addToShoppingCart(book)">
                                        <div>GERESERVEERD</div>
                                    </v-btn>
                                    <v-btn block color="primary" outlined
                                        class="mt-2"
                                        @click="sendMessage(book, book.owner)">
                                        <div>BERICHT STUREN</div>
                                    </v-btn>
                                    <v-col class="ml-n2">
                                        
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="4" lg="4" class="after-sticky">
                    <v-card class="pa-8">
                        <v-row>
                            <v-col class="ml-4" cols="2" md="2">
                                <v-avatar size="40">
                                    <!-- <v-icon v-if="!book.owner.photo">mdi-account</v-icon> -->
                                    <v-img v-if="!student.photo" :src="getDefaultImageAvatar()"></v-img>
                                    <v-img v-if="student.photo" :src="student.photo"></v-img>
                                </v-avatar>
                            </v-col>
                            <v-col cols="12" class="pt-0 ml-1">
                                <label class="font-weight-bold">{{student.firstName}} {{student.lastName}}</label>
                            </v-col>
                            <v-col class="pa-0" cols="12" v-for="(study, index) in student.studies" :key="index">
                                <label class="primary--text">{{study.study.name}}</label>
                            </v-col>
                            <v-col>
                                <v-rating
                                    readonly
                                    v-model="student.reviewScore"
                                    color="yellow darken-3"
                                    background-color="grey darken-1"
                                    empty-icon="$ratingFull"
                                    half-increments
                                    dense
                                    hover>
                                </v-rating>
                            </v-col>
                            <v-col v-if="student.reviews.length == 1">
                                {{student.reviews.length}} review
                            </v-col>
                            <v-col v-if="student.reviews.length > 1">
                                {{student.reviews.length}} reviews
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-row>

    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BookService from '../services/BookService';
import moment from 'moment'
import NoAccount from "../assets/img/no-user.png";

export default {
    props: ['student'], 
    data() {
        return {
            booksOfStudent: []
        }
    },
    async created(){
        await this.loadBooksOfStudent();
    },
    methods:{
        ...mapGetters(['getAccessToken']),
        ...mapActions(['saveToCart']),
        async loadBooksOfStudent(){
            let result = await BookService.getBooksOfStudent(this.getAccessToken(), this.student.id, 1, 10, false);
            this.booksOfStudent = result;
        },

        getDefaultImageAvatar(){
            return NoAccount;
        },

        isReservationExpired(book){
            let releaseTime = moment(moment(String(book.state.claimIsValidUntil)).format('YYYY-MM-DD HH:mm:ss')); //2022-01-26T14:26:34.084181
            let now = moment();
            let difference = moment.duration(now.diff(releaseTime));
            return (difference.minutes() >= 0 );
        },

        async addToShoppingCart(book){
            this.saveToCart(book);
            await this.claimBook(book);

            if(book.owner.numberOfBooks > 1){
                let result = await BookService.getBooksOfStudent(this.getAccessToken(), book.owner.id, 1, 10, false);
                this.booksOfStudent = result;
            }else{
                await this.goToShoppingCart();
            }

           this.dialog = true;
        },
        
        async claimBook(book){
            let result = await BookService.claimBook(this.getAccessToken(), book.id);
        },

        sendMessage(book, to){
            this.$router.push({name:'SendMessage', params:{book:book, to:to}});
        },
    }  
}
</script>