import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: true });

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    authentication:{},
    student:{},
    loggedIn: false,
    shoppingCart:[],
    navigationSearch: null,
    numberOfUnreadMessages:0,
    viewPublishedBookOverview:{},
    pageSectionName: 'Books',
    money:0.0
  },
  getters: {
    getUserName: state => {
      return state.authentication.userName;
    },
    getAuthentication: state => {
      return state.authentication;
    },
    getNumberOfUnreadMessages: state => {
      return state.numberOfUnreadMessages;
    },
    getPageSection: state => {
      return state.pageSectionName;
    },
    getStudent: state => {
      return state.student;
    },
    getAccessToken: state => {
      return state.authentication.accessToken;
    },
    isAdmin: state => {
      return state.authentication.role === 'Admin';
    },
    getRefreshToken: state => {
      return state.authentication.refreshToken;
    },
    isUserLoggedIn: state => {
      return (state.authentication.accessToken != null);
    },
    hasValidSubscription: state => {
      return (state.student.hasValidSubscription != null && state.student.hasValidSubscription);
    },
    getMoney: state => {
      if(state.money){
        return state.money;
      }

      return 0.0;
    },
    getCart: state => {
      return (state.shoppingCart);
    },
    getNavigationSearch: state => {
      return (state.navigationSearch);
    },
    hasValidAddress: state => {
      return (
        state.student.postalAddress && 
        state.student.postalAddress.houseNumber &&
        state.student.postalAddress.street &&
        state.student.postalAddress.postalCode &&
        state.student.postalAddress.city &&
        state.student.postalAddress.street);
    },
    getStateOfPublishedBookOverview: state => {
      return (state.viewPublishedBookOverview);
    }
  },
  mutations: {
    saveAuthentication(state, authentication){
      state.authentication = authentication;
      Vue.set(state, 'loggedIn', true);
    },
    saveStudent(state, student){
      state.student = student;
      state.money = Number(student.wallet.amount);
    },
    addToCart(state, item){
      state.shoppingCart.push(item);
    },
    changeMoneyOfStudent(state, item){
      state.money = item;
    },
    removeFromCart(state, item){
      let index = state.shoppingCart.findIndex(x => x.id === item)
      state.shoppingCart.splice(index, 1);
    },
    changeNavigationSearchValue(state, item){
      state.navigationSearch = item;
    },
    flushCart(state, item){
      state.shoppingCart = [];
    },
    clearNavigationSearchValue(state, item){
      state.navigationSearch = null;
    },
    resetStore(state, item){
      state.authentication = [];
      state.student = [];
      state.loggedIn = false;
      state.shoppingCart = [];
      state.navigationSearch = null;
    },
    clearAccountInformation(state, item){
      state.student = [];
    },
    setNumberOfUnreadMessages(state, item){
      state.numberOfUnreadMessages = item;
    },
    setStateOfPublishedBookOverview(state, item){
      state.viewPublishedBookOverview = item;
    },
    setNumberOfSendMessages(state){
      state.student.numerOfMessagesSentInPeriod = state.student.numerOfMessagesSentInPeriod + 1;
    },
    savePageSection(state, item){
      state.pageSectionName = item;
    },
    setFirstName(state, item){
      state.student.firstName = item;
    },
    setLastName(state, item){
      state.student.lastName = item;
    },
  },
  actions: {
    setSuccessfulLogin(context, authentication) {
      context.commit('saveAuthentication', authentication);
    },
    setPageSection(context, pageSection) {
      context.commit('savePageSection', pageSection);
    },
    setStudentsAccount(context, student) {
      context.commit('saveStudent', student);
    },
    saveToCart(context, item) {
      context.commit('addToCart', item);
    },
    deleteFromCart(context, item) {
      context.commit('removeFromCart', item);
    },
    saveNavigationSearchValue(context, item) {
      context.commit('changeNavigationSearchValue', item);
    },
    saveMoneyOfStudent(context, item) {
      context.commit('changeMoneyOfStudent', item);
    },
    resetNavigationSearchValue(context, item) {
      context.commit('clearNavigationSearchValue');
    },
    emptyCart(context, item) {
      context.commit('flushCart', item);
    },
    logout(context, item) {
      context.commit('resetStore', item);
    },
    clearAccount(context, item) {
      context.commit('clearAccountInformation', item);
    },
    saveNumberOfUnreadMessages(context, item) {
      context.commit('setNumberOfUnreadMessages', item);
    },
    saveStateOfPublishedBookOverview(context, item){
      context.commit('setStateOfPublishedBookOverview', item);
    },
    increaseNumberOfSendMessages(context){
      context.commit('setNumberOfSendMessages');
    },
    saveFirstName(context, item){
      context.commit('setFirstName', item);
    },
    saveLastName(context, item){
      context.commit('setLastName', item);
    }
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});
