import axios from "../plugins/axios";

const baseUrl = `${process.env.VUE_APP_BOOKSHARE_URL}/transactions/`;

export default {
    async getTransactions(
        token, 
        pageNumber, 
        pageSize, 
        transactionState, 
        interveneState, 
        searchCriteria,
        dateFrom, 
        searchInMyIntervenes, 
        searchInBoekshareAccounts) {

      let result;
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      let data = {  "transactionState": transactionState,
                    "pageNumber": pageNumber,
                    "pageSize": pageSize,
                    "searchCriteria": searchCriteria,
                    "interveneState": interveneState,
                    "dateFrom": dateFrom,
                    "searchInMyIntervenes": searchInMyIntervenes,
                    "searchInBoekshareAccounts": searchInBoekshareAccounts,
                  };

      await axios.post(`${baseUrl}admin/transactions`, data, config)
              .then(response =>
                result = response.data);
                
      return result;
    },

    async getTransactionById(token, transactionId) {

      let result;
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      let data = {  transactionId: transactionId};

      await axios.post(`${baseUrl}get-transaction`, data, config)
              .then(response =>
                result = response.data);
                
      return result;
    },

    async getTopsSoldBooks(token, pageNumber, pageSize, from, state) {      
      let booksResponse;
      const config = {
          headers: { Authorization: `Bearer ${token}` }
      };

      let data = {
        "pageNumber":  pageNumber,
        "pageSize":  pageSize,
        "from":  from,
        "state": state
      }

      await axios.post(`${baseUrl}admin/top-sold-books`, data, config)
              .then(response =>
                booksResponse = response.data);

      return booksResponse;
    },

    async approveTransaction(token, transactionId) {
      let result;

      const config = {
          headers: { Authorization: `Bearer ${token}` }
      };
      let data = {
        "transactionId":  transactionId
      }
      await axios.post(`${baseUrl}admin/approve`, data, config)
              .then(response =>
                result = response.data);

      return result;
  },

  async rejectTransaction(token, transactionId) {
    let result;
    
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    let data = {
      "transactionId":  transactionId
    }
    await axios.post(`${baseUrl}reject`, data, config)
            .then(response =>
              result = response.data);

    return result;
  },

  async rejectTransactionAdmin(token, transactionId) {
    let result;
    
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    let data = {
      "transactionId":  transactionId
    }
    await axios.post(`${baseUrl}admin/reject`, data, config)
            .then(response =>
              result = response.data);

    return result;
  },
}
