<template>
   <v-card flat tile>
         <v-img src="../assets/img/footer.png" style="background-color: #F5F8FD;">            
            <v-container class="center">
             <v-list-item three-line>
               <v-list-item-content>
                     <v-row>
                     <v-col cols="1" class="hidden-sm-and-down">
                        <v-img src="../assets/ico/boekshare-white_100x120px.svg"
                              min-height="70"
                              min-width="60"
                              ></v-img>
                     </v-col>
                     <v-col cols="0" md="1"></v-col>                        
                     <v-col cols="6" md="2">
                        <v-card-text>
                           <v-row>
                                 <a class="title">Bedrijf</a>
                              </v-row>
                              <v-row class="details">
                                 <router-link class="link-footer" to="/about">Over ons</router-link>
                              </v-row>
                              <v-row class="details">
                                    <router-link class="link-footer" to="/partners">Onze partners</router-link>
                              </v-row>
                              <v-row class="details">
                                 <router-link class="link-footer" to="/subscriptions">Lidmaatschap</router-link>
                              </v-row>
                              <v-row class="details">
                                    <router-link class="link-footer" to="/faq">FAQ</router-link>
                              </v-row>
                              <v-row class="details">
                                    <a :href="`${publicPath}AlgemenevoorwaardenBoekshare.pdf`" class="link-footer">Algemene voorwaarden</a>
                              </v-row>
                              <v-row class="details">
                                 <router-link class="link-footer" to="/contact">Contact</router-link>
                              </v-row>
                        </v-card-text>
                     </v-col>
                        <v-col cols="6" md="2">
                        <v-card-text>
                           <v-row class="footerPadding">
                                    <a class="title">Ruilen</a>
                              </v-row>
                              <v-row class="details">
                                    <router-link class="link-footer" to="/rules">Regels</router-link>
                              </v-row>
                        </v-card-text>
                     </v-col>
                     <v-col cols="6" md="2">
                        <v-card-text>
                              <v-row>
                                 <a class="title">Volgen</a>
                              </v-row>
                              <v-row>
                                    <a href="https://www.linkedin.com/company/boekshare/" target="blank" class="link-footer">
                                       <v-btn icon>
                                    <v-icon class="ico-footer">mdi-linkedin</v-icon>
                                 </v-btn>
                                 </a>
                                 <a href="https://www.instagram.com/boekshare/" target="blank" class="link-footer">
                                    <v-btn icon>
                                       <v-icon class="ico-footer">
                                          mdi-instagram
                                       </v-icon>
                                    </v-btn>
                                 </a>
                              </v-row>
                        </v-card-text>
                     </v-col>
                  </v-row>
               </v-list-item-content>
            </v-list-item>
            </v-container>
      </v-img>
   </v-card>
</template>


<script>

export default {
   data () {
      return {
         publicPath: process.env.BASE_URL
      }
   }
}
</script>



<style scoped>
.center {
   padding: 16% 0 0 0;
}
.title {
   color: white;
   font-weight: bold;;
}
.ico-footer {
   color: white !important;
}
.link-footer {
   text-decoration: none !important;
}
@media(min-width:992px){
   .details {
      padding-top: 10%;
   }
}
@media(min-width:50px) and (max-width:680px){
   .footerPadding {
      padding-top: 45%;
   }
   .details {
      padding-top: 2%;
   }
}
.v-application a {
   color: white !important;
}
.details-two-columns {
   padding-top: 10%;
}
.v-application a:hover {
   color:#77EDCE !important;   
}
.v-application .title:hover {
   color: white !important;
}
.ico-footer:hover {
   color: #77EDCE !important;
}
</style>

