import axios from "../plugins/axios";

const baseUrl = process.env.VUE_APP_BOOKSHARE_URL+'/utilities';

export default {
    async sendContactForm(firstName, lastName, email, message) {
        let result;
        let data = { contactMessage: { 
            firstName: firstName,
            lastName: lastName,
            email: email,
            message: message 
        }};

        await axios.post(`${baseUrl}/contact-form`, data)
            .then(response =>
                result = response.data);
        return result;
    },
}