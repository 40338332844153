import axios from "../plugins/axios";

const baseUrl = `${process.env.VUE_APP_BOOKSHARE_URL}`;

export default {
    async register(token, typeOfSubscriptionRegistration, discountNumber) {
        let subscription;
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };
        
        let data = {
          typeOfSubscriptionRegistration: Number(typeOfSubscriptionRegistration),
          discountNumber: discountNumber
        }

        await axios.post(`${baseUrl}/subscriptions/register`, data, config)
                .then(response =>
                    subscription = response.data.subscription);

        return subscription;
    },
    async pay(token, subscriptionId) {
        let payment;
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };
        
        let data = {
          "id": Number(subscriptionId),
        }

        await axios.post(`${baseUrl}/subscriptions/pay`, data, config)
                .then(response =>
                    payment = response.data.payment);
                    
        return payment;
    },
    async update(account, subscription) {   
      let result;
      let data = {
        "account": account,
        "applicationId": subscription
      }

      await axios.post(`${baseUrl}/subscriptions/update`, data)
              .then(response =>
                  result = response.data);
                  
      return result;
  }
}
