<template>
    <v-container>
        <v-row>
            <v-col cols="3" class="hidden-sm-and-down pt-0">
                <UserMenuCard></UserMenuCard>
            </v-col>
            <v-col class="pt-0">
                  <v-card flat>
                      <v-tabs
                          color="#40414E"
                          slider-color="#77EDCE"
                          v-model="tab"
                          background-color="backgroundcolor"
                          centered
                          icons-and-text>
                          <v-tab href="#1" @click="loadStudentBooks">
                            Mijn boeken
                          </v-tab>

                          <v-tab-item value="1">
                            <v-row> 
                              <v-col cols="6">
                                <v-card-text v-if="errorMessage">
                                  {{errorMessage}}
                                </v-card-text>
                              </v-col>
                              <v-col class="mt-8">
                                <v-progress-circular
                                  v-if="loading"
                                  indeterminate
                                  color="primary">
                                </v-progress-circular>
                              </v-col>
                            </v-row>
                            <div v-if="books !== null && books.length > 0">
                              <div v-for="(book, index) in books" :key="index">
                                <BookDetails @onRemovedBook="onRemovedBook" :book="book" :searched="false" />
                              </div>
                            </div>
                            <div v-if="books !== null && books.length === 0">
                                <v-card-text>
                                  <div>Je hebt nog geen geregistreerde boeken.</div>
                                </v-card-text>
                            </div>
                          </v-tab-item>

                          <v-tab href="#2" id="tabUploadBookId">
                            Uploaden
                          </v-tab>
                          <v-tab-item value="2">
                            <UploadMyBook @bookUploaded="loadStudentBooks()" />
                          </v-tab-item>
                      </v-tabs>
                        <v-row>
                            <v-col cols="1">
                            </v-col>
                            <v-col>
                              <v-pagination
                                v-if="tab == 1 && totalPages >=0"
                                style="padding-bottom: 3%; padding-top: 3%"
                                v-model="pageNumber"
                                :length="totalPages"
                                @input="next"
                                :total-visible="7">
                              </v-pagination>
                            </v-col>
                        </v-row>
                    </v-card>
              </v-col>
        </v-row>
    </v-container>
</template>

<script>
import UserMenuCard from '../components/UserMenuCard.vue';
import BookDetails from '../components/BookDetails.vue';
import UploadMyBook from '../components/UploadMyBook.vue';
import BookService from "../services/BookService"
import { mapGetters } from 'vuex';

export default {
    data () {
      return {
        componentKey: 0,
        totalCount: 0,
        pageSize: 5,
        pageNumber: 1,
        totalPages:0,
        tab: null,
         items: [
          { tab: 'Mijn Boeken', content: 'Tab 1 Content' },
          { tab: 'Uploaden', content: 'Tab 2 Content' },
        ],
        books: null,
        loading: false,
        errorMessage:null,
      }
    },
    async created() {
        await this.loadStudentBooks();
    },
     methods: {
       ...mapGetters(['getAccessToken']),

        async loadStudentBooks() {
            try{
                this.tab = '#1';
                this.loading = true;
                this.errorMessage = null;
                const booksResponse = await BookService.getBooks(this.getAccessToken(), this.pageNumber, this.pageSize);
                this.books = booksResponse.books;
                this.totalCount = booksResponse.totalCount;
                this.totalPages = Math.floor((this.totalCount / this.pageSize) + 1);
                this.forceRerender();
            }
            catch {
                this.books = null;
                this.errorMessage = 'Er is een fout opgetreden';
            }   
            this.loading = false;
        },
        async onRemovedBook(){
          await this.loadStudentBooks();
        },
        async next(p) {
          try{
            const booksResponse = await BookService.getBooks(this.getAccessToken(), this.pageNumber, this.pageSize);
            this.books = booksResponse.books;
            this.totalCount = booksResponse.totalCount;
            this.totalPages = Math.floor((this.totalCount / this.pageSize) + 1);
          }
          catch{
            this.books = null;
            this.errorMessage = 'Er is een fout opgetreden';
          }
          this.loading = false;
        },
        async getStudentBooks() {
          await this.loadStudentBooks();
          this.forceRerender();
        },
        forceRerender() {
          // This is necessary to force the child component (StudentBookDetail) to re-render
          // the component, in order to display the image correctly (https://michaelnthiessen.com/force-re-render/);
            this.componentKey += 1;
        }
    },
    components: {
         UserMenuCard,
         UploadMyBook,
         BookDetails
    },
  }
</script>

<style scoped>
.tabs {
  padding-bottom: 10% !important;
}
</style>