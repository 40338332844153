import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home";
import Account from "@/views/Account";
import MyBooks from "@/views/MyBooks";
import RegisterStudent from "@/views/RegisterStudent";
import UpdateStudentBook from "@/views/UpdateStudentBook";
import About from "@/views/About";
import Contact from "@/views/Contact";
import FAQ from "@/views/FAQ";
import SearchedStudentBooks from "@/views/SearchedStudentBooks";
import Subscriptions from "@/views/Subscriptions";
import Rules from "@/views/Rules";
import LoginUser from "@/views/LoginUser";
import Partners from "@/views/Partners";
import store from '../store'
import PublishedBookOverview from "@/views/PublishedBookOverview";
import BooksOfStudentDetails from "@/views/BooksOfStudentDetails";
import ShoppingCart from "@/views/ShoppingCart";
import SubscriptionPaymentResult from "@/views/SubscriptionPaymentResult";
import CheckoutPaymentResult from "@/views/CheckoutPaymentResult";
import UpdateMyBook from "@/views/UpdateMyBook";
import Purchases from "@/views/Purchases";
import PublishedBooksOverview from "@/views/PublishedBooksOverview";
import MyPublishedBookAlerts from '@/views/MyPublishedBookAlerts'
import RegistrationResult from '@/views/RegistrationResult'
import SoldBooks from '@/views/SoldBooks';
import ActivateAccountResult from '@/views/ActivateAccountResult'
import ResetPassword from '@/views/ResetPassword'
import ForgotPassword from '@/views/ForgotPassword'
import ForgotUsername from '@/views/ForgotUsername'
import RequestCashouts from '@/views/RequestCashouts'
import MyCashouts from '@/views/MyCashouts'
import MyConversations from '@/views/MyConversations'
import SendMessage from '@/views/SendMessage'
import PublishedBooksAdminAddOrUpdate from '@/views/admin/publishedbooks/AddOrUpdate'
import StudentsOverview from '@/views/admin/students/StudentsOverview'
import DiscountsOverview from '@/views/admin/discounts/DiscountsOverview'
import Cashouts from '@/views/admin/cashouts/Cashouts'
import AllShipments from '@/views/admin/shipments/AllShipments'
import Shipments from '@/views/admin/shipments/Shipments'
import NotConfirmedShipments from '@/views/admin/shipments/NotConfirmedShipments'
import IntervenesOverview from '@/views/admin/intervene/IntervenesOverview'
import UpdateShipments from '@/views/admin/shipments/UpdateShipments'
import CheckoutsOverview from '@/views/admin/checkouts/CheckoutsOverview'
import MaintenanceMode from '@/views/MaintenanceMode'
import StatisticsOverview from '@/views/admin/statistics/StatisticsOverview'
import Conversations from '@/views/admin/conversations/ConversationsOverview'
import AdvertisementOverview from '@/views/admin/advertisements/AdvertisementsOverview'
import JobsOverview from '@/views/jobs/JobsOverview'
import JobsAdminOverview from '@/views/admin/jobs/JobsAdminOverview'
import GiveReview from '@/views/GiveReview'
import ReviewsOverview from '@/views/ReviewsOverview'
import historyPlugin from './historyPlugin';

Vue.use(VueRouter);
Vue.use(historyPlugin);

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isUserLoggedIn) {
    next();
    return;
  }
  next("/");
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isUserLoggedIn) {
    next();
    return;
  }
  next("/login");
};

const routes = [
    // PASSWORD PROTECTED VIEWS FOR ADMIN 
  {
    path: "/admin/shipments/notconfirmedshipments", 
    name: "NotConfirmedShipments", 
    component: NotConfirmedShipments,
    props: true,
    beforeEnter: ifAuthenticated
  },  
  {
    path: "/admin/shipments/shipments", 
    name: "Shipments", 
    component: Shipments,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/admin/conversations/conversationsoverview", 
    name: "Conversations", 
    component: Conversations,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/admin/statistics/statisticsoverview", 
    name: "StatisticsOverview", 
    component: StatisticsOverview,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/admin/advertisements/advertisementsoverview", 
    name: "AdvertisementOverview", 
    component: AdvertisementOverview,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/admin/shipments/allshipments", 
    name: "AllShipments", 
    component: AllShipments,
    props: true,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/admin/shipments/updateshipments", 
    name: "UpdateShipments", 
    component: UpdateShipments,
    props: true,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/admin/students/studentsoverview", 
    name: "StudentsOverview", 
    component: StudentsOverview,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/admin/discounts/discountsoverview", 
    name: "DiscountsOverview", 
    component: DiscountsOverview,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/admin/publishedbooks/addorupdate", 
    name: "PublishedBooksAdminAddOrUpdate", 
    component: PublishedBooksAdminAddOrUpdate,
    props:true,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/admin/cashouts/cashouts", 
    name: "Cashouts", 
    component: Cashouts,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/admin/checkouts/checkoutsoverview", 
    name: "CheckoutsOverview", 
    component: CheckoutsOverview,
    props:true,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/admin/jobs/jobsadminoverview", 
    name: "JobsAdminOverview", 
    component: JobsAdminOverview,
    beforeEnter: ifAuthenticated
  }, 
  {
    path: "/admin/intervene/intervenesoverview", 
    name: "InterveneOverview", 
    component: IntervenesOverview,
    beforeEnter: ifAuthenticated
  },
  // PASSWORD PROTECTED VIEWS FOR USER 
  {
    path: "/account", 
    name: "Account", 
    component: Account,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/mycashouts", 
    name: "MyCashouts", 
    component: MyCashouts,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/mybooks", 
    name: "MyBooks", 
    component: MyBooks,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/purchases", 
    name: "Purchases", 
    component: Purchases,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/myconversations", 
    name: "MyConversations", 
    component: MyConversations,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/updatestudentbook/:id", 
    name: "UpdateStudentBook", 
    component: UpdateStudentBook,
    props: true,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/updatemybook", 
    name: "UpdateMyBook", 
    component: UpdateMyBook,
    props: true,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/mypublishedbookalerts", 
    name: "MyPublishedBookAlerts", 
    component: MyPublishedBookAlerts,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/soldbooks", 
    name: "SoldBooks", 
    component: SoldBooks,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/sendmessage", 
    name: "SendMessage", 
    component: SendMessage,
    props: true,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/givereview", 
    name: "GiveReview", 
    component: GiveReview,
    beforeEnter: ifAuthenticated
  }, //
  {
    path: "/reviewsoverview", 
    name: "ReviewsOverview", 
    component: ReviewsOverview,
    beforeEnter: ifAuthenticated
  },
  // NON PROTECTED VIEWS FOR USER 
  {
    path: "/", 
    name: "", 
    component: Home,
  },
  {
    path: "/maintenancemode", 
    name: "MaintenanceMode", 
    component: MaintenanceMode,
  },  
  {
    path: "/requestcashouts", 
    name: "RequestCashouts", 
    component: RequestCashouts,
  },
  {
    path: "/registerstudent", 
    name: "RegisterStudent", 
    component: RegisterStudent,
  },
  {
    path: "/about", 
    name: "About", 
    component: About,
  },
  {
    path: "/contact", 
    name: "Contact", 
    component: Contact,
  },
  {
    path: "/faq", 
    name: "FAQ", 
    component: FAQ,
  },
  {
    path: "/subscriptionpaymentresult", 
    name: "SubscriptionPaymentResult", 
    component: SubscriptionPaymentResult,
  },
  {
    path: "/activateaccountresult", 
    name: "ActivateAccountResult", 
    component: ActivateAccountResult,
  },
  {
    path: "/checkoutpaymentresult",
    name: "CheckoutPaymentResult",
    component: CheckoutPaymentResult,
    props: true
  },
  // 
  {
    path: "/searchedStudentBooks/:searchedCriteria", 
    name: "SearchedStudentBooks", 
    component: SearchedStudentBooks,
  },
  {
    path: "/subscriptions", 
    name: "Subscriptions", 
    component: Subscriptions,
  },

  {
    path: "/rules", 
    name: "Rules", 
    component: Rules,
  },
  {
    path: "/resetpassword", 
    name: "ResetPassword", 
    component: ResetPassword,
  },
  {
    path: "/forgotpassword", 
    name: "ForgotPassword", 
    component: ForgotPassword,
  },
  {
    path: "/forgotusername", 
    name: "ForgotUsername", 
    component: ForgotUsername,
  },
  {
    path: "/publishedbooksoverview", 
    name: "PublishedBooksOverview", 
    component: PublishedBooksOverview,
    props: true
  },
  {
    path: "/publishedbookoverview", 
    name: "PublishedBookOverview", 
    component: PublishedBookOverview,
  },
  {
    path: "/booksofstudentdetails", 
    name: "BooksOfStudentDetails", 
    component: BooksOfStudentDetails,
    props: true
  },
  {
    path: "/jobsoverview", 
    name: "JobsOverview", 
    component: JobsOverview,
  },
  {
    path: "/shoppingcart", 
    name: "ShoppingCart", 
    component: ShoppingCart,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/partners", 
    name: "Partners", 
    component: Partners,
  },
  {
    path: "/login", 
    name: "LoginUser", 
    component: LoginUser,
  },
  {
    path: "/registrationresult",
    name: "RegistrationResult",
    component: RegistrationResult
  },
];

const router = new VueRouter({
  mode:'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
});

router.beforeEach((to, from, next) => {
  // Save the previous route in a global variable or in the route's meta
  if (from.name) {
    to.meta.previousRoute = from;
  }
  next();
});

export default router;
