<template>
    <v-row class="mt-3">
        <v-col cols="12" class="mb-4">
            <v-row>
                <v-col cols="10" md="8">
                    <v-text-field
                        id="findMyBookIsbnId"
                        label="ISBN"
                        placeholder="ISBN"
                        :rules="[rules.required]"
                        v-model="isbn"
                        dense
                        counter="13"
                        maxlength="13"
                        outlined>
                    </v-text-field>
                </v-col>
                <v-col cols="1" v-if="publishedBook != null && publishedBook.isbn">
                    <v-icon class="green--text" large>mdi-check</v-icon>
                </v-col>         
                <v-col v-if="isbn != null && isbn.length >=13 && !publishedBookNotFound && !loading" cols="12">
                    Oeps, we kennen deze ISBN niet. Controleer de ISBN op typefouten. <br />
                    Zie je geen typefouten? Dan hebben we dit boek niet in onze database. 
                    Stuur ons dan even een appje op 0644290322 of e-mail ons op support@boekshare.nl
                </v-col>
                <v-col>
                    <v-progress-circular
                        v-if="loading"
                        indeterminate
                        color="primary">
                    </v-progress-circular>
                </v-col>
            </v-row>
            <v-row v-if="publishedBook != null && publishedBook.title != null"> 
                <v-col class="py-0 ml-n3" cols="4" md="3">
                    <v-card-text class="py-0">
                        Titel:
                    </v-card-text>
                </v-col>
                <v-col class="py-0" cols="8">
                    <v-card-text class="py-0" >
                        {{publishedBook.title}}
                    </v-card-text>
                </v-col>
            </v-row>
            <v-row v-if="publishedBook != null && publishedBook.author != null">
                <v-col class="py-0 ml-n3" cols="4" md="3">
                    <v-card-text  class="py-0">
                        Auteur:
                    </v-card-text>
                </v-col>
                <v-col class="py-0" cols="8">
                    <v-card-text class="py-0" >
                        {{publishedBook.author}}
                    </v-card-text>
                </v-col>
            </v-row>
            <v-row v-if="publishedBook != null && publishedBook.edition != null">
                <v-col class="py-0 ml-n3" cols="4" md="3">
                    <v-card-text class="py-0">
                        Druk:
                    </v-card-text>
                </v-col>
                <v-col class="py-0" cols="8">
                        <v-card-text class="py-0">
                        {{publishedBook.edition}}
                    </v-card-text>
                </v-col>
            </v-row>
            <v-row v-if="publishedBook != null && publishedBook.price != null">
                <v-col class="py-0 ml-n3" cols="4" md="3">
                    <v-card-text class="pt-0">
                        Adviesprijs:
                    </v-card-text>
                </v-col>
                <v-col class="py-0" cols="8">
                        <v-card-text class="pt-0">
                        €{{parseFloat(publishedBook.price).toFixed(2)}}
                    </v-card-text>
                </v-col>
            </v-row>
            <v-row v-if="publishedBook != null">
                <v-col class="pt-0" cols="12" md="6">
                    <vue-upload-multiple-image
                        idUpload="image-step1"
                        @upload-success="uploadImageSuccess"
                        @before-remove="beforeRemove"
                        @edit-image="editImage"
                        :data-images="images"
                        editUpload="editUpload"
                        dragText="Niewe foto"
                        browseText="Add"
                        primaryText="Hoofdfoto"
                        @mark-is-primary="markAsPrimary"
                        markIsPrimaryText="Instellen als hoofdfoto"
                        popupText="Afbeeldingen van je boek">
                    </vue-upload-multiple-image>
                </v-col>
            </v-row>
                  
            <v-row v-if="publishedBook != null">      
                <v-col cols="12" md="3">
                    <v-btn 
                    id="uploadMyBookStep2Id"
                    color="primary"
                    block
                    :disabled="!publishedBook.id"
                    outlined 
                    @click="nextStepClicked()">
                        Volgende Stap
                    </v-btn>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import PublishedBookService from "../../services/PublishedBookService"
import VueUploadMultipleImage from 'vue-upload-multiple-image'
import { mapGetters } from 'vuex'

  export default {
    data () {
      return {
        rules: {
            publishedBook: null,
            required: value => !!value || 'Required.',
        },
        isbn: '',
        publishedBook: null,
        publishedBookNotFound: false,
        loading: false,
        images: [],
        imagesFormData: [],
      }
    },
    watch:{
        isbn:function(val){
            this.findBookByIsbn(val);
        },
        publishedBook:function(val){
            this.publishedBook = val;
        }
    },
    methods:{
        ...mapGetters(['getAccessToken']),
        async uploadImageSuccess(formData, index, fileList) {
            this.images = fileList;
            this.imagesFormData = formData;
        },

        resetPage(){
            this.isbn = '';
            this.publishedBook=null;
            this.publishedBookNotFound = false;
            this.loading = false;
            this.images = [];
            this.imagesFormData=[];  
        },

        nextStepClicked(){
            let foundPb = this.publishedBook;
            this.resetPage();
            this.$emit('publishedBookIsFound', { publishedBook: foundPb });
        },

        beforeRemove (index, done, fileList) {
            done();
            this.images = fileList;
        },
        editImage (formData, index, fileList) {
            this.images = fileList;
        },
        markAsPrimary(index, fileList) {
            this.images = fileList;
        },
        
        async findBookByIsbn(val) {
            
            this.loading = true;
            if(val.length === 13) {
                try{
                    let pb = await PublishedBookService.findPublishedBookByIsbn(val);
                    if(pb){
                        this.publishedBook = pb;
                        this.publishedBookNotFound = true;
                    } else{
                        this.publishedBook = null;
                        this.publishedBookNotFound = false;
                    }
                    this.loading = false;
                }
                catch{
                    this.publishedBook = null;
                    this.publishedBookNotFound = false;
                }
            }
            else{
                this.publishedBook = null;
                this.publishedBookNotFound = false;
            }

            this.loading = false;
        },
    },
    components:{
        VueUploadMultipleImage
    }
  }
  </script>