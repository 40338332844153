<template>
    <v-container>
        <v-row>
            <v-col cols="3" class="hidden-sm-and-down">
                <UserMenuCard></UserMenuCard>
            </v-col>
            <v-col>
                <v-card outlined>
                    <EditMyBook :bookToEdit="bookToEdit"/>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import EditMyBook from '../components/EditMyBook.vue';
import UserMenuCard from '../components/UserMenuCard.vue';
export default {
    props:['bookToEdit'],
    components:{
        EditMyBook,
        UserMenuCard
    }
  }
</script>

<style scoped>
.tabs {
  padding-bottom: 10% !important;
}
</style>