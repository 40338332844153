<template>
   <v-form>
        <v-container> 
            <v-row>
                <v-col cols="3" class="hidden-md-and-down">
                    <UserMenuCard></UserMenuCard>   
                </v-col>
                <v-col>
                   
                    <v-row>
                        <v-col>
                            <v-toolbar color="primary" dark flat>
                                <template v-slot:extension>
                                    <v-tabs v-model="tab" align-with-title>
                                        <v-tabs-slider color="secondary"></v-tabs-slider>
                                        <v-tab>Zoeken op datum</v-tab>
                                        <!-- <v-tab>Zoeken op id</v-tab> -->
                                    </v-tabs>
                                </template>
                            </v-toolbar>
                            <v-tabs-items v-model="tab">
                                <v-tab-item :value="0">
                                    <v-row class="px-4 pt-4">
                                        <!-- <v-row class="ma-0"> -->
                                            <v-col cols="12" md="4">
                                                <v-menu
                                                    v-model="menuDateFrom"
                                                    :close-on-content-click="true"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto">
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field
                                                            v-model="dateFrom"
                                                            label="Vanaf datum:"
                                                            prepend-icon="mdi-calendar"
                                                            readonly
                                                            outlined
                                                            dense
                                                            v-on="on"></v-text-field>
                                                    </template>
                                                    <v-date-picker 
                                                        v-model="dateFrom" 
                                                        no-title 
                                                        dense
                                                        scrollable
                                                        @input="menuDateFrom = false">
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col cols="12" md="4">
                                                <v-menu
                                                    v-model="menuDateTo"
                                                    :close-on-content-click="true"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto">
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field
                                                            v-model="dateTo"
                                                            label="Vanaf datum:"
                                                            prepend-icon="mdi-calendar"
                                                            readonly
                                                            outlined
                                                            dense
                                                            v-on="on"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker 
                                                        v-model="dateTo" 
                                                        no-title 
                                                        dense
                                                        scrollable
                                                        @input="menuDateFrom = false">
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col>
                                                <v-btn @click="load()" :loading="loading"  color="primary">
                                                    LOAD
                                                </v-btn>
                                            </v-col>
                                        <!-- </v-row> -->
                                    </v-row>
                                </v-tab-item>
                                <v-tab-item :value="1">
                                     <v-card flat>
                                        <v-col cols="12" md="4">
                                            <v-text-field placeholder="checkout id"></v-text-field>
                                        </v-col>
                                         <v-col>
                                            <v-btn @click="load()" :loading="loading" color="primary">
                                                LOAD
                                            </v-btn>
                                        </v-col>
                                    </v-card>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-col>
                    </v-row>
                     <v-row justify="center" v-if="loading">
                        <v-progress-circular
                        indeterminate
                        color="primary">
                        </v-progress-circular>
                    </v-row>
                    <v-row v-for="(checkout, index) in checkouts" :key="index">
                        <v-col>
                            <v-card v-if="checkout.checkoutBy">
                                <v-card-title>
                                    {{checkout.id}}
                                </v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col>
                                            Time: 
                                        </v-col>
                                        <v-col>
                                            {{getFriendlyDate(checkout.time) + " " +  getFriendlyTime(checkout.time)}}
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            Username: 
                                        </v-col>
                                        <v-col>
                                            {{checkout.checkoutBy.userName}}
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="checkout.payment != null">
                                        <v-col>
                                            Status: 
                                        </v-col>
                                        <v-col>
                                            {{checkout.payment.status}}
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="checkout.payment != null">
                                        <v-col>
                                            iDeal: 
                                        </v-col>
                                        <v-col>
                                            €{{checkout.payment.amount}}
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            Totaal (€): 
                                        </v-col>
                                        <v-col>
                                            €{{checkout.grandTotal}}
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="checkout.payment != null">
                                        <v-col>
                                            Payment time: 
                                        </v-col>
                                        <v-col>
                                            {{getFriendlyDate(checkout.payment.createdAt) + " " +  getFriendlyTime(checkout.payment.createdAt)}}
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="checkout.payment != null">
                                        <v-col>
                                            Payment update time: 
                                        </v-col>
                                        <v-col>
                                            {{getFriendlyDate(checkout.payment.updatedAt) + " " +  getFriendlyTime(checkout.payment.updatedAt)}}
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="checkout.payment != null && checkout.payment.status === 'open'">
                                        <v-col>
                                        </v-col>
                                        <v-col>
                                            <v-btn color="primary" @click="updatePaymentStatusOfCheckout(checkout.payment.paymentId)" class="mr-2">Ophalen status</v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <span>Transactions</span>
                                            <v-expansion-panels accordion>
                                                <v-expansion-panel v-for="(transaction, index) in checkout.transactions" :key="index">
                                                    <v-expansion-panel-header>Transaction id: {{transaction.id}}</v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                    <v-row>
                                                            <v-col>
                                                                Seller: 
                                                            </v-col>
                                                            <v-col>
                                                                {{transaction.seller.firstName}} {{transaction.seller.lastName}}
                                                            </v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col>
                                                                Buyer: 
                                                            </v-col>
                                                            <v-col>
                                                                {{transaction.buyer.firstName}} {{transaction.buyer.lastName}}
                                                            </v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col>
                                                                ISBN: 
                                                            </v-col>
                                                            <v-col>
                                                                {{transaction.book.publishedBook.isbn}} 
                                                            </v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col>
                                                                Boek Id: 
                                                            </v-col>
                                                            <v-col>
                                                                {{transaction.book.id}} 
                                                            </v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col>
                                                                Title: 
                                                            </v-col>
                                                            <v-col>
                                                                {{transaction.book.publishedBook.title}} 
                                                            </v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col>
                                                                Prijs: 
                                                            </v-col>
                                                            <v-col>
                                                                €{{parseFloat(transaction.book.price).toFixed(2)}} 
                                                            </v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col>
                                                                State: 
                                                            </v-col>
                                                            <v-col>
                                                                {{transaction.state}} 
                                                            </v-col>
                                                        </v-row>
                                                        <v-row v-if="checkout.totalMissingMoneyInWallet === 0.0 || (checkout.payment != null && (checkout.payment.status == 'paid' || checkout.payment.status == 'finished'))">
                                                            <v-col>
                                                            </v-col>
                                                            <v-col>
                                                                <v-btn 
                                                                v-if="transaction.state !== 'approved' && 
                                                                transaction.state !== 'rejected' &&
                                                                transaction.state !== 'confirmed' &&
                                                                transaction.state !== 'expired'" 
                                                                color="green" 
                                                                class="mr-2"
                                                                :loading = "transaction.id === acceptButtonPressed"
                                                                @click="approveTransaction(transaction.id)">Goedkeuren</v-btn>
                                                                <v-btn 
                                                                v-if="transaction.state !== 'rejected' && 
                                                                transaction.state !== 'approved' && 
                                                                transaction.state !== 'confirmed' && 
                                                                transaction.state !== 'expired'"
                                                                :loading = "transaction.id === rejectButtonPressed"
                                                                color="red"
                                                                @click="rejectTransaction(transaction.id)">Afkeuren</v-btn>
                                                            </v-col>
                                                        </v-row>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                            </v-expansion-panels>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <span>Shipments</span>
                                            <v-expansion-panels accordion>
                                                <v-expansion-panel v-for="(shipment, index) in checkout.shipments" :key="index">
                                                    <v-expansion-panel-header>Shipment id: {{shipment.id}}</v-expansion-panel-header>
                                                        <v-expansion-panel-content>
                                                            <v-row>
                                                                <v-col>
                                                                    Barcode: 
                                                                </v-col>
                                                                <v-col>
                                                                    {{shipment.barcode}}
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                 <v-col>
                                                                    Barcode: 
                                                                </v-col>
                                                                <v-col>
                                                                     {{getFriendlyDate(shipment.inserted) + " " +  getFriendlyTime(shipment.inserted)}}
                                                                </v-col>
                                                            </v-row>
                                                        </v-expansion-panel-content>
                                                </v-expansion-panel>
                                            </v-expansion-panels>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="3"></v-col>
                <v-col>
                    <v-row>
                        <v-pagination
                            v-if="totalPages >=0"
                            style="padding-bottom: 3%; padding-top: 3%"
                            v-model="pageNumber"
                            :length="totalPages"
                            @input="next"
                            :total-visible="7">
                        </v-pagination>
                    </v-row>
                </v-col>
            </v-row>
         </v-container>
    </v-form>
</template>

<script>
import { mapGetters } from 'vuex';
import UserMenuCard from '../../../components/UserMenuCard.vue';
import CheckoutService from '../../../services/CheckoutService';
import TransactionService from '../../../services/TransactionService';

export default {
    data () {
      return {
        loading: false,
        acceptButtonPressed:0,
        rejectButtonPressed: 0,
        pageSize: 5,
        pageNumber: 1,
        totalPages:0,
        dateFrom: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        dateTo: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menuDateFrom:false,
        menuDateTo:false,
        checkouts:[],
        tab: null,
      }
    },
    methods:{
        ...mapGetters(['getAccessToken']),
        async load(){
            if(this.tab == 0){
                this.loading =true;
                this.checkouts = [];
                let data = await CheckoutService.getCheckoutsAsAdmin(this.getAccessToken(), this.dateFrom, this.dateTo, this.pageNumber, this.pageSize);
                this.checkouts = data.checkouts;
                this.totalPages = Math.floor((data.totalCount /this.pageSize)+1);
                this.loading =false;
            } else if(this.tab == 1){

            }
        },

        async next(){
            if(this.tab == 0){
                this.loading = true;
                this.checkouts = [];
                let data = await CheckoutService.getCheckoutsAsAdmin(this.getAccessToken(), this.dateFrom, this.dateTo, this.pageNumber, this.pageSize);
                this.checkouts = data.checkouts;
                this.totalPages = Math.floor((data.totalCount /this.pageSize)+1);
                this.loading = false;
            } else if(this.tab == 1){

            }
        },

        async approveTransaction(transactionId){
            if(this.tab == 0){
                try {
                    this.acceptButtonPressed = transactionId;
                    let data = await TransactionService.approveTransaction(this.getAccessToken(), transactionId);
                } catch{}
                this.acceptButtonPressed = 0;
                this.load();
            } else if(this.tab == 1){
                
            }
        },

        async rejectTransaction(transactionId){
            if(this.tab == 0){
                try {
                    this.rejectButtonPressed = transactionId;
                    let data = await TransactionService.rejectTransactionAdmin(this.getAccessToken(), transactionId);
                } catch{ }
                this.rejectButtonPressed = 0;
                await this.load();

            } else if(this.tab == 1){
                
            }
        },

        async updatePaymentStatusOfCheckout(paymentId){
            if(this.tab == 0){
                let data = await CheckoutService.updatePaymentStatus(this.getAccessToken(), paymentId);
                this.load();
            } else if(this.tab == 1){
                
            }
        },

        getFriendlyDate(val){
            var date = new Date(val);
            return date.getDate()  + "-" + (date.getMonth()+1) + "-" + date.getFullYear();
        },

        getFriendlyTime(val){
            var date = new Date(val);
            return date.getHours() + ":" + String(date.getMinutes()).padStart(2, "0");
        }
    },
    components:{
        UserMenuCard
    },
}

</script>
                    