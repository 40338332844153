<template>
   <v-container class="mt-1">
        <v-row justify="end" v-if="!disableClosingDialog">
            <v-card-actions>
                <v-btn text @click="closeButton(true)" >
                    <v-icon>
                        mdi-close
                    </v-icon>
                </v-btn>
            </v-card-actions>
        </v-row>
 
        <v-row class="pt-0" v-if="reviews != null && reviews.length == 0">
            <v-col class="pl-0" cols="12">
                <v-card >
                    <v-card-text>
                        Je hebt nog geen reviews ontvangen.
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row justify="center" class="mt-3" v-if="loading">
            <v-progress-circular
                indeterminate
                color="primary">
            </v-progress-circular>
        </v-row>
        <v-row>
            <v-col cols="12" class="pl-0" v-if="error">
                 <v-card >
                    <v-card-text>
                        Er is iets mis gegaan.
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-card flat width="600" color="transparent">
                <v-card-text>
                   <v-row v-for="(review, index) in reviews" :key="index">
                        <v-col>
                            <v-card class="px-4 py-4"> 
                                <v-row>
                                    <v-col cols="2">
                                        <v-avatar size="40">
                                            <v-img v-if="!review.givenBy.photo" :src="getDefaultImageAvatar()"></v-img>
                                            <v-img v-if="review.givenBy.photo" :src="review.givenBy.photo"></v-img>
                                        </v-avatar>
                                    </v-col>
                                    <v-col class="mt-2">
                                        <label class="font-weight-bold">{{review.givenBy.firstName}} {{review.givenBy.lastName}}</label>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <div v-for="(givenRating, indexGivenRatings) in review.ratings" :key="indexGivenRatings">
                                        <v-col v-if="givenRating.givenNumber > 0" >
                                            <label class="font-weight-bold">{{givenRating.rating.name}}</label>
                                            <v-rating :value="givenRating.givenNumber" readonly></v-rating>
                                        </v-col>
                                    </div>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" v-if="review.additionalInformation">
                                        <label>{{review.additionalInformation}}</label>
                                    </v-col>
                                </v-row>
                                <v-row>
                                     <v-col cols="12" v-if="review.inserted">
                                        <label>{{getFriendlyDate(review.inserted)}}</label>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                   </v-row>
                </v-card-text>
            </v-card>
        </v-row>
   </v-container>
</template>

<script>
import NoAccount from "../assets/img/no-user.png";
import ReviewService from '../services/ReviewService';

export default {
    props:['sellerId', 'disableClosingDialog'],
    data(){
        return {
            reviews:[],
            loading:false,
            error:false,
        }
    },

    watch:{
        sellerId(newValue, oldValue) {
        if (newValue != null) {
          this.getReviewsOfSeller().then();
        }
      },
    },

    async created(){
        await this.getReviewsOfSeller();
    },

    methods:{
        async getReviewsOfSeller(){
            try{
                this.error = false;
                this.loading = true;
                let result = await ReviewService.getReviewsOfSeller(this.sellerId);
                this.reviews = result.reviews;
            }catch{
                this.error = true;
            }
            this.loading = false;
        },

        getFriendlyDate(val){
            var date = new Date(val);
            return date.getDate()  + "-" + (date.getMonth()+1) + "-" + date.getFullYear();
        },

        getFriendlyTime(val){
            var date = new Date(val);
            return date.getHours() + ":" + String(date.getMinutes()).padStart(2, "0");
        },

        getDefaultImageAvatar(){
            return NoAccount;
        },

        closeButton(success){
            this.$emit('closeDialog', success);
        },
    }
}
</script>