<template>
    <v-container>
        <v-row>
            <v-col cols="3" class="hidden-md-and-down">
                <UserMenuCard></UserMenuCard>   
            </v-col>
            <v-col cols="12" md="6"> 
                <v-row>
                    <v-col cols="8">
                        <v-card-text v-if="errorMessage">
                            {{errorMessage}}
                        </v-card-text>
                    </v-col>
                </v-row>
                <v-row justify="center" v-if="loading">
                    <v-progress-circular
                        indeterminate
                        color="primary">
                    </v-progress-circular>
                </v-row>
                <v-row>
                    <v-col>
                        <v-select outlined v-model="selectedStatus" :items="status" item-text="name" item-value="id" @change="loadCashouts()">
                        </v-select>
                    </v-col>
                </v-row>
                <v-card v-for="(student, index) in students" :key="index">
                    <v-card-title>
                        {{student.firstName}} {{student.lastName}}
                    </v-card-title>
                    <v-expansion-panels>
                        <v-expansion-panel
                        v-for="(cashout,i) in student.cashouts"
                        :key="i">
                            <v-expansion-panel-header>
                                <v-row>
                                    <v-col cols="12">
                                        {{getFriendlyDate(cashout.requestedAt)}} {{getFriendlyTime(cashout.requestedAt)}}
                                    </v-col>
                                    <v-col cols="12">
                                        Status: {{translateStatus(cashout.status)}}
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                            <v-row>
                                <v-col>
                                    <v-row>
                                        <v-col cols="7" class="py-0">
                                            Totaal cashout 
                                        </v-col>
                                        <v-col class="py-0">
                                            €{{parseFloat(cashout.cashedOutAmount).toFixed(2)}}
                                        </v-col>
                                    </v-row>
                                    <v-row class="py-0">
                                        <v-col cols="7" class="py-0">
                                            Administratiekosten
                                        </v-col>
                                        <v-col class="py-0">
                                            {{parseFloat(cashout.administrationCost).toFixed(2)}}%
                                        </v-col>
                                    </v-row>
                                    <v-row class="py-0">
                                        <v-col cols="7" class="py-0">
                                            Totaal administratiekosten
                                        </v-col>
                                        <v-col class="py-0">
                                            €{{parseFloat(Number(cashout.totalAdministrationCost)).toFixed(2)}}
                                        </v-col>
                                    </v-row>
                                    <v-divider class="my-5"></v-divider>
                                    <v-row>
                                        <v-col cols="7" class="py-0">
                                            Totaal ontvangen
                                        </v-col>
                                        <v-col class="py-0">
                                            €{{parseFloat(Number(cashout.cashedOutAmount) - Number(cashout.totalAdministrationCost)).toFixed(2)}}
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="7" class="pt-0">
                                            Datum
                                        </v-col>
                                        <v-col class="pt-0">
                                                {{getFriendlyDate(cashout.requestedAt)}} {{getFriendlyTime(cashout.requestedAt)}}
                                        </v-col>
                                    </v-row>
                                    <v-row class="mt-0">
                                        <v-col cols="7" class="pt-0">
                                            Status
                                        </v-col>
                                        <v-col class="pt-0">
                                            {{translateStatus(cashout.status)}}
                                        </v-col>
                                    </v-row>
                                    <v-row class="mt-0">
                                        <v-col>
                                            <v-btn 
                                                small 
                                                color="primary"
                                                :loading="loading && cashoutToUpdateStatus === cashout.id" 
                                                @click="changeStatus(4, 
                                                cashout.id)">
                                                    Afronden
                                            </v-btn>
                                        </v-col>
                                        <v-col>
                                            <v-btn 
                                            small 
                                            color="primary"
                                            :loading="loading && cashoutToUpdateStatus === cashout.id"  
                                            @click="changeStatus(2, cashout.id)">
                                                In verwerking
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="1">
            </v-col>
            <v-col>
                <v-pagination
                v-if="totalPages>0"
                style="padding-bottom: 3%; padding-top: 3%"
                v-model="pageNumber"
                :length="totalPages"
                @input="next"
                :total-visible="7">
                </v-pagination>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
import UserMenuCard from '../../../components/UserMenuCard.vue';
import CashoutService from '../../../services/CashoutsService'
import { mapGetters } from 'vuex';

  export default {
    data () {
      return {
        loading: false,
        pageSize: 10,
        pageNumber: 1,
        totalPages:0,
        students:[],
        cashoutToUpdateStatus: 0,
        selectedStatus:100,
        errorMessage:null,
        status:[
            {id:0, name:'Requested'}, 
            {id:2, name:'In Progress'},
            {id:4, name:'Finalized'},
            {id:100, name:'Alle statussen'}
        ]
      }
    },
    async created(){
        await this.loadCashouts();
    },
    methods:{
        ...mapGetters(['getAccessToken']),
        async loadCashouts(){
            this.loading = true;
            try{
                this.students = [];
                let data =  await CashoutService.getCashouts(
                    this.getAccessToken(), 
                    this.pageNumber, 
                    this.pageSize, 
                    this.selectedStatus);

                this.students = data.studentsCashouts;
                
            } catch {
                this.errorMessage = 'Er is helaas een fout opgetreden.'
            }
            this.loading = false;
        },
        translateStatus(status){
            if(status===0) return "Ingediend";
            else if(status===1) return "Ingediend";
            else if(status===2) return "Wordt verwerkt";
            else if(status===3) return "Afgewezen";
            else if(status===4) return "Afgerond";
            else return "Onbekend";
        },
        async changeStatus(status, cashoutId){
            this.errorMessage = null;
            this.loading = true;
            try{
                this.cashoutToUpdateStatus = cashoutId;
                let data =  await CashoutService.changeCashoutStatus(this.getAccessToken(), cashoutId, status);

                this.students = data.studentsCashouts;
                await this.loadCashouts();

            } catch {
                this.errorMessage = 'Er is helaas een fout opgetreden.'
            }
             this.loading = false;
        },
        getFriendlyDate(val){
            var date = new Date(val);
            return date.getDate()  + "-" + (date.getMonth()+1) + "-" + date.getFullYear();
        },
        getFriendlyTime(val){
            var date = new Date(val);
            return date.getHours() + ":" + String(date.getMinutes()).padStart(2, "0");
        },   
    },
    components:{
        UserMenuCard
    },
  }
</script>