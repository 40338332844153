<template>
   <v-form>
        <v-container>
            <v-row>
                <v-col cols="3" class="hidden-md-and-down">
                    <UserMenuCard></UserMenuCard>   
                </v-col>
                <v-col>
                    <v-tabs center-active>
                        <v-tab id="allShipmentTabId" ref="#allshipments">Shipments</v-tab>
                        <v-tab id="updateShipmentTabId" ref="#updateshipments">Update shipments</v-tab>
                        <v-tab id="notConfirmedShipmentTabId" ref="#notconfirmedshipments">Not confirmed shipments</v-tab>
                        <v-tab id="changeShipmentStatusTabId" ref="#changeShipmentStatusComponent">Shipment status</v-tab>
                        <v-tab ref="#changeShipmentStatusComponent">Shipment datetime</v-tab>
                        <v-tab-item key="allshipments">
                            <AllShipmentsComponent></AllShipmentsComponent>
                        </v-tab-item>
                        <v-tab-item key="updateshipments">
                            <UpdatehipmentsComponent></UpdatehipmentsComponent>
                        </v-tab-item>
                        <v-tab-item key="notconfirmedshipments">
                            <NotConfirmedShipmentsComponent></NotConfirmedShipmentsComponent>
                        </v-tab-item>
                        <v-tab-item key="changeShipmentStatusComponent">
                            <ChangeShipmentStatusComponent></ChangeShipmentStatusComponent>
                        </v-tab-item>
                         <v-tab-item key="changeShipmentDateTimeComponent">
                            <ChangeShipmentDateTimeComponent></ChangeShipmentDateTimeComponent>
                        </v-tab-item>
                    </v-tabs>
                </v-col>
            </v-row>
        </v-container>
   </v-form>
</template>

<script>
import UserMenuCard from '../../../components/UserMenuCard.vue';
import AllShipmentsComponent from '../../../components/admin/AllShipmentsComponent.vue';
import UpdatehipmentsComponent from '../../../components/admin/UpdateShipmentsComponent.vue';
import NotConfirmedShipmentsComponent from '../../../components/admin/NotConfirmedShipmentsComponent.vue';
import ChangeShipmentStatusComponent from '../../../components/admin/ChangeShipmentStatusComponent.vue';
import ChangeShipmentDateTimeComponent from '../../../components/admin/ChangeShipmentDateTimeComponent.vue'

export default {

    data () {
      return {
      }
    },
       
    components:{
        UserMenuCard,
        AllShipmentsComponent,
        UpdatehipmentsComponent,
        NotConfirmedShipmentsComponent,
        ChangeShipmentStatusComponent,
        ChangeShipmentDateTimeComponent
    },
}
</script>