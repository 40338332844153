import axios from "../plugins/axios";

const baseUrlIAA = process.env.VUE_APP_IAA_BOOKSHARE_URL;
const baseUrl = process.env.VUE_APP_BOOKSHARE_URL;

export default {
    async loginUser(username, password) {
        let user;
        let data = { username: username, password:password };
        await axios.post(`${baseUrlIAA}/User/login`,data)
                .then(response =>
                    user = response.data);
        return user;
    },
    async getAccountOfStudent(token) {
        let user;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        let data = {};
        await axios.post(`${baseUrl}/Students/account`, data, config)
                .then(response =>
                    user = response.data.student);
                    
        return user;
    },

    async updateAddress(token, residentialAddress, postalAddress) {
      let result;
      const config = {
          headers: { Authorization: `Bearer ${token}` }
      };

      let data = {
        postalAddress: postalAddress, 
        residentialAddress:residentialAddress
      };

      await axios.post(`${baseUrl}/students/update-address`, data, config)
              .then(response =>
                result = response.data);
                  
      return result;
    },

    async updateStudy(token, study, university) {
      let result;
      const config = {
          headers: { Authorization: `Bearer ${token}` }
      };

      let data = {
        study: study, 
        university:university
      };

      await axios.post(`${baseUrl}/Students/update-study`, data, config)
              .then(response =>
                result = response.data);
                  
      return result;
    },

    async updatePhoneNumber(token, phoneNumber) {
      let result;
      const config = {
          headers: { Authorization: `Bearer ${token}` }
      };

      let data = {
        phoneNumber: phoneNumber, 
      };

      await axios.post(`${baseUrl}/Students/update-phonenumber`, data, config)
              .then(response =>
                result = response.data);
                  
      return result;
    },

    async resetPasswordRequest(username) {
        let result;
        let data = { username:username };
        await axios.post(`${baseUrlIAA}/User/reset-password-request`, data)
                .then(response =>
                  result = response.data);
        return result;
      },

      async activateUser(token, accountId) {
        let result;
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };
        let data = { accountId:accountId };
        await axios.post(`${baseUrlIAA}/User/admin/activate-user`, data, config)
                .then(response =>
                  result = response.data);
        return result;
      },

      async setPassword(token, accountId) {
        let result;
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };
        let data = { accountId:accountId };
        await axios.post(`${baseUrlIAA}/User/admin/set-password`, data, config)
                .then(response =>
                  result = response.data);
        return result;
      },
  
      async resetPassword(email, token, newPassword) {
        let result;
        await axios.get(`${baseUrlIAA}/User/reset-password?token=${token}&email=${email}&newPassword=${newPassword}`)
                .then(response =>
                  result = response.data);
        return result;
      },
  
      async confirmEmail(token, email) {
        let result;
        // let data = { token:token, email:email};
        await axios.get(`${baseUrlIAA}/User/confirm-email?token=${token}&email=${email}`)
                .then(response =>
                  result = response.data);
        return result;
      },

      async register(firstName, lastName, userName, email, password, university, study) {
        let result;
        let data = { 
          Student: { 
            firstName:firstName,
            lastName: lastName,
            userName:userName, 
            email:email, 
            password:password,
            university:university,
            study:study }
        };
          
        await axios.post(`${baseUrl}/students/register`, data)
                .then(response =>
                  result = response.data);
        return result;
      },

      async close(token, username, password) {
        let result;
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };
        let data = { username:username,password: password};
        await axios.post(`${baseUrl}/students/close-account`, data, config)
                .then(response =>
                  result = response.data);
        return result;
      },

      async forgotUsername(email) {
        let result;
        let data = { email:email };
        await axios.post(`${baseUrlIAA}/User/forgot-username`, data)
                .then(response =>
                  result = response.data);
        return result;
      },

      async userNameExists(userName) {
        let result;
        let data = { userName:userName };
        await axios.post(`${baseUrlIAA}/User/user-exists`, data)
                .then(response =>
                  result = response.data);
        return result;
      },
      
      async emailExists(email) {
        let result;
        let data = { email:email };
        await axios.post(`${baseUrlIAA}/User/email-exists`, data)
                .then(response =>
                  result = response.data);
        return result;
      },
}
