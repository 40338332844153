<template>
    <v-row align="center" justify="center" class="mt-8">
        <v-col cols="12" sm="10" md="10" lg="6">
            <v-card class="pa-md-4 mb-4">
                <h1 class="display-1 font-weight-bold pa-4 text-center">
                    <div class="title-bookshare primary--text">Veelgestelde vragen</div>
                </h1>
                <v-list>
                    <v-list-group
                        v-for="(item, index) in items" :key="index"
                        no-action
                    >
                        <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title class="title-faq">{{item.title}}</v-list-item-title>
                        </v-list-item-content>
                        </template>

                        <v-list-item class="pl-4 wrap-text" v-for="child in item.items" :key="child.question">
                        <v-list-item-content>
                            <v-list-item-title style="white-space: normal;">{{child.question}}</v-list-item-title>
                            <v-spacer></v-spacer>
                            <v-list-item-action-text class="text-answer">{{child.answer}}</v-list-item-action-text>
                            <v-list-item-subtitle v-if="child.value1" class="text-wrap text-answer">{{child.value1}}</v-list-item-subtitle>
                            <v-list-item-subtitle v-if="child.value2" class="text-wrap text-answer">{{child.value2}}</v-list-item-subtitle>
                            <v-list-item-subtitle v-if="child.value3" class="text-wrap text-answer">{{child.value3}}</v-list-item-subtitle>
                            <v-list-item-subtitle v-if="child.value4" class="text-wrap text-answer">{{child.value4}}</v-list-item-subtitle>
                            <v-list-item-subtitle v-if="child.value5" class="text-wrap text-answer">{{child.value5}}</v-list-item-subtitle>
                            <v-list-item-subtitle v-if="child.value6" class="text-wrap text-answer">{{child.value6}}</v-list-item-subtitle>
                            <v-list-item-subtitle v-if="child.value7" class="text-wrap text-answer">{{child.value7}}</v-list-item-subtitle>
                            <v-list-item-subtitle v-if="child.value8" class="text-wrap text-answer">{{child.value8}}</v-list-item-subtitle>
                            <v-list-item-subtitle v-if="child.value9" class="text-wrap text-answer">{{child.value9}}</v-list-item-subtitle>
                            <v-list-item-subtitle v-if="child.value10" class="text-wrap text-answer">{{child.value10}}</v-list-item-subtitle>

                            <span v-if="child.linkToSubscriptionPage">
                                <v-list-item-action-text class="text-answer">{{child.textOneWithLink}}</v-list-item-action-text >
                                <a style="color: rgba(0, 0, 0, 0.6);" href="/subscriptions">hier</a>
                                <v-list-item-action-text class="text-answer">{{child.textTwoWithLink}}</v-list-item-action-text>
                            </span>

                        </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                </v-list>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
export default {
    data () {
      return {
          items:[
            {
                 title: 'Top 10 vragen',
                items: [
                    {
                        question: '1. Ik heb mijn bestelling nog niet ontvangen, wat moet ik doen?',
                        answer: 'De verkoper heeft 7 dagen om de bestelling te versturen. Als je op dag 8 na je bestelling je boek(en) nog niet hebt ontvangen, krijg je automatisch je geld terug of krijg je het boek zonder extra kosten van ons.'
                    },
                    {
                        question: '2. Wanneer krijg ik (de verkoper) mijn geld?',
                        answer: 'Je krijgt uitbetaald in je Boekshare wallet op het moment dat de koper de ontvangst bevestigt of 4 dagen nadat PostNL het boek heeft afgeleverd.'
                    },
                    {
                        question: '3. Hoe snel moet ik mijn boek verzenden?',
                        answer: 'Het liefst zo snel mogelijk, maar we geven je 7 dagen vanaf dat iemand je boek koopt de tijd om je boek te verzenden. Als binnen deze periode het boek niet opgestuurd is, wordt de koop geannuleerd en krijgt de koper zijn geld terug.'
                    },
                    {
                        question: '4.  Ik heb het boek niet meer, wat moet ik doen?',
                        answer: 'Geen probleem! Bij “Verkocht” in je Boekshare account kan je aangeven dat je het boek niet meer hebt via de knop: “ik heb dit boek niet meer”'
                    },
                    {
                        question: '5. Kan ik uitbetaald krijgen?',
                        answer: 'Jazeker! Je kan via mijn cashouts je Boekshare wallet legen en uitbetaald krijgen. Wij rekenen 10% administratiekosten bij basic leden voor het laten uitbetalen van de wallet. Premium leden betalen geen administratiekosten. Basic leden krijgen met binnen 3 dagen uitbetaald. Premium leden binnen 24 uur.'
                    },
                    {
                        question: '6. Heb ik een abonnement nodig?',
                        answer: 'Bij Boekshare hebben we een basic lidmaatschap waarmee je een aantal beperkingen hebt en betaalde lidmaatschappen. Voor de verschillen, kan je kijken op de lidmaatschap pagina.'
                    },
                    {
                        question: '7. Waarom kan ik mijn boek niet uploaden?',
                        answer: 'Niet ieder studieboek kun je uploaden op ons platform. Van sommige boeken weten wij bijvoorbeeld dat ze niet lang mee gaan en daarom accepteren we ze niet op ons platform; wettenbundels van voor 2021 zijn hier een mooi voorbeeld van. We voegen dagelijks boeken toe aan onze database. Het kan voorkomen dat jouw boek nog niet in onze database staat. Mocht je deze melding krijgen, stuur dan een mailtje naar support@boekshare.nl of app naar 06-44290322 en wij gaan meteen kijken wat we voor je kunnen doen.'
                    },
                    {
                        question: '8. Hoe duur is premium lidmaatschap?',
                        linkToSubscriptionPage: true,
                        textOneWithLink: 'Bij Boekshare hebben we verschillende soorten abonnementen. Klik ',
                        textTwoWithLink: ' om te zien hoeveel deze abonnementen kosten.'
                    },
                    {
                        question: '9. De informatie van mijn studieboek klopt niet?',
                        answer: 'Net als de medewerkers van Boekshare is onze database niet perfect. Het kan zijn dat de schrijver, druk of titel niet goed in ons systeem staan. Mocht dit het geval zijn als jij je boek uploadt, laat dit dan asjeblieft aan ons weten. Wij gaan dit dan zo snel mogelijk aanpassen.'
                    },
                    {
                        question: '10. Hoe kan ik mijn profielgegevens wijzigen?',
                        answer: 'Als je op een desktop zit, zie je rechtsboven iedere pagina zie je een account icoon. Als je hierop klikt, kom je op je eigen account pagina terecht. Hier kun je jouw gegevens wijzigen. Op de mobiele website zie je linksboven 3 streepjes. Als je hierop klikt kom je op de account pagina waar je je gegevens kunt wijzigen.'
                    },
                    {
                        question: '11. Wie betaalt de verzending?',
                        answer: 'De kopende partij betaalt de verzendkosten.'
                    },
                ]
            },
            {
                title: 'Account',
                items: [
                    { 
                        question: 'Hoe kan ik mijn profielgegevens wijzigen?', 
                        answer: 'Als je op een desktop zit, zie je rechtsboven iedere pagina zie je een account icoon. Als je hierop klikt, kom je op je eigen account pagina terecht. Hier kun je jouw gegevens wijzigen. Op de mobiele website zie je linksboven 3 streepjes. Als je hierop klikt kom je op de account pagina waar je je gegevens kunt wijzigen.' 
                    },
                ]
            },
            {
                title: 'Betalingen',
                items: [
                    { question: 'Hoe betaal ik en is dat veilig?', answer: 'Betalingen worden veilig via iDEAL gedaan.' },
                    { question: 'Kan ik uitbetaald krijgen?', answer: 'Jazeker! Je kan via mijn cashouts je Boekshare wallet legen en uitbetaald krijgen. Wij rekenen 10% administratiekosten bij basic leden voor het laten uitbetalen van de wallet. Premium leden betalen geen administratiekosten. Basic leden krijgen met binnen 3 dagen uitbetaald. Premium leden binnen 24 uur.' },
                ]
            },
             {
                title: 'Verzending',
                items: [
                    { question: 'Wie betaalt de verzending?', answer: 'De kopende partij betaalt de verzendkosten.' },
                    { question: 'Wat kost een boek verzenden?', answer: 'We werken met PostNL en hanteren zij de volgende tarieven: Brievenbuspakje 0-2 kg, max 38 x 25,5 x 3,2 cm, €4,15 Pakketje 0-10 kg max 100 x 50 x 50 cm, €6,95.' },
                ]
            },
              {
                title: 'Boeken',
                items: [
                    { 
                        question: 'Hoe snel moet ik mijn boek opsturen?', 
                        answer: 'Het liefst zo snel mogelijk, maar we geven je 7 dagen vanaf dat iemand je boek koopt de tijd om je boek te verzenden. Als binnen deze periode het boek niet opgestuurd is, wordt de koop geannuleerd en krijgt de koper zijn geld terug.' },
                    { 
                        question: 'Ik heb mijn bestelling nog niet ontvangen, wat moet ik doen?',
                        answer: 'De verkoper heeft 7 dagen om de bestelling te versturen. Als je op dag 8 na je bestelling je boek(en) nog niet hebt ontvangen, krijg je automatisch je geld terug of krijg je het boek zonder extra kosten van ons.'
                    },
                    { 
                        question: 'Ik heb het verkeerde boek ontvangen, wat nu?', 
                        answer: 'Als je het verkeerde boek hebt ontvangen, neem dan contact met ons op. De verkoper betaalt in dit geval de verzendkosten opnieuw.' 
                    },
                    { 
                        question: 'Waarom kan ik mijn boek niet uploaden?', 
                        answer: 'Niet ieder studieboek kun je uploaden op ons platform. Van sommige boeken weten wij bijvoorbeeld dat ze niet lang mee gaan en daarom accepteren we ze niet op ons platform; wettenbundels van voor 2021 zijn hier een mooi voorbeeld van. We voegen dagelijks boeken toe aan onze database. Het kan voorkomen dat jouw boek nog niet in onze database staat. Mocht je deze melding krijgen, stuur dan een mailtje naar support@boekshare.nl of app naar 06-44290322 en wij gaan meteen kijken wat we voor je kunnen doen.' 
                    },
                    { 
                        question: 'De informatie van mijn studieboek klopt niet?', 
                        answer: 'Net als de medewerkers van Boekshare is onze database niet perfect. Het kan zijn dat de schrijver, druk of titel niet goed in ons systeem staan. Mocht dit het geval zijn als jij je boek uploadt, laat dit dan asjeblieft aan ons weten. Wij gaan dit dan zo snel mogelijk aanpassen.' 
                    },
                    { 
                        question: 'Ik heb het boek niet meer, wat moet ik doen?', 
                        answer: 'Licht ons of de kopende partij dan in en haal het boek van de website.' 
                    },
                ]
            },
        ]}
    },
}
</script>

<style scoped>
.title-faq {
    font-weight: bold;;
}
.text-answer {
    font-size: 16px !important;
}
</style>