<template>
  <v-container>
    <Login navigate="true"></Login>  
  </v-container>
</template>

<script>

export default {
  name: 'LoginUser',
  components:{
    Login: () => import('@/components/Login.vue')
  },
}
</script>