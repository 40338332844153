<template>
   <v-form>
        <v-container> 
            <v-row>
                <v-col cols="3" class="hidden-md-and-down">
                    <UserMenuCard></UserMenuCard>   
                </v-col>
                <v-col>
                    <v-row>
                        <v-col cols="12" class="mb-4" md="12">
                            <v-card flat class="px-8 pt-8">
                            <v-row class="py-0">
                                <v-col cols="12" class="py-0">
                                    <v-text-field label="ISBN"
                                        placeholder="ISBN"
                                        :rules="[rules.required]"
                                        v-model="publishedBook.isbn"
                                        :disabled="publishedBookToEdit != null"
                                        dense
                                        counter="13"
                                        outlined
                                        class="py-0">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="py-0">
                                <v-col cols="12" class="py-0">
                                    <v-text-field label="Titel"
                                        placeholder="Titel"
                                        :rules="[rules.required]"
                                        v-model="publishedBook.title"
                                        dense
                                        outlined
                                        class="py-0">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="py-0">
                                <v-text-field label="Auteur"
                                        placeholder="Auteur"
                                        :rules="[rules.required]"
                                        v-model="publishedBook.author"
                                        dense
                                        outlined
                                        class="py-0">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="py-0">
                                <v-col cols="12" class="py-0">
                                    <v-text-field label="Druk"
                                        placeholder="Druk"
                                        :rules="[rules.required]"
                                        v-model="publishedBook.edition"
                                        dense
                                        outlined
                                        class="py-0">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="py-0">
                                <v-col cols="12" class="py-0">
                                    <v-text-field label="Prijs"
                                        placeholder="Prijs"
                                        :rules="[rules.required]"
                                        v-model="publishedBook.price"
                                        dense
                                        outlined
                                        class="py-0">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="py-0">
                                <v-col cols="12" class="py-0">
                                    <v-text-field label="Hoogte"
                                        placeholder="Hoogte"
                                        :rules="[rules.required]"
                                        v-model="publishedBook.physicalProperties.height"
                                        dense
                                        outlined
                                        class="py-0">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="py-0">
                                <v-col cols="12" class="py-0">
                                    <v-text-field label="Breedte"
                                        placeholder="Breedte"
                                        :rules="[rules.required]"
                                        v-model="publishedBook.physicalProperties.width"
                                        dense
                                        outlined
                                        class="py-0">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="py-0">
                                <v-col cols="12" class="py-0">
                                    <v-text-field label="Gewicht"
                                        placeholder="Gewicht"
                                        :rules="[rules.required]"
                                        v-model="publishedBook.physicalProperties.weight"
                                        dense
                                        outlined
                                        class="py-0">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="py-0">
                                <v-col cols="12" class="py-0">
                                    <v-text-field label="Adviesprijs"
                                        placeholder="Gewicht"
                                        :rules="[rules.required]"
                                        v-model="publishedBook.price"
                                        dense
                                        outlined
                                        class="py-0">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="py-0">
                                <v-col class="pt-0" cols="12">
                                    <v-file-input v-model="selectedImageFile" 
                                        outlined
                                        @change="selectedImageFileChanged"
                                        accept="image/*"
                                        label="Foto"/>
                                </v-col>
                            </v-row> 
                            <v-row>
                                <v-col cols="5" class="pt-0 mb-5">
                                    <v-img :src="imagePreview"
                                            max-width="150"/>
                                </v-col>
                            </v-row>           
                            <v-row class="py-0">     
                                <v-col cols="12" md="3">
                                    <v-btn 
                                    color="primary" 
                                    block
                                    :loading="loading"
                                    @click="addOrUpdatePubslihedBook()">
                                        <div v-if="publishedBookToEdit">Wijzigen</div>
                                        <div v-if="!publishedBookToEdit">Toevoegen</div>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
import upload from "../../../assets/img/upload.png"
import PublishedBookService from '../../../services/PublishedBookService';
import UserMenuCard from '../../../components/UserMenuCard.vue';
import { mapGetters } from 'vuex';

  export default {
    props: ['publishedBookToEdit'],
    data () {
      return {
        publishedBook: {isbn: '', title:'', author:'', edition:'', price:'', imageFileName:'', physicalProperties:{height:'', width:'', weight:'', price:''}},
        loading: false,
        selectedImageFile: null,
        imagePreview: null,
        rules: {
          required: value => !!value || 'Required.',
        },
      }
    },
    created(){
        if(this.publishedBookToEdit){
            this.publishedBook = this.publishedBookToEdit;
            this.imagePreview = this.publishedBookToEdit.imageFileName;
        }else{

        }
    },
    methods:{
        ...mapGetters(['getAccessToken']),
        async selectedImageFileChanged(){
            await this.createBase64Image(this.selectedImageFile);
        },
        async uploadImages(book){
            for(const image of this.images){
                await PublishedBookService.addImage(this.getAccessToken(), book.id, image.path);
            }
        }, 
        async createBase64Image(fileObject) {
            const reader = new FileReader();
            reader.onload = async (e) => {

                this.publishedBook.photo = e.target.result;
                if (!this.publishedBook.photo) return upload;

                this.imagePreview = URL.createObjectURL(this.selectedImageFile);
            };
            reader.readAsDataURL(fileObject);
        },  
        
        async addOrUpdatePubslihedBook(){
            if(this.publishedBookToEdit){
                this.updatePublishedBook();
            } else {
                this.addPublishedBook();
            }
        },
        async addPublishedBook() {
            try
            {
                this.errorMessage = null;
                this.loading = true;
                let pb = await PublishedBookService.addPublishedBook(this.getAccessToken(), this.publishedBook);
                this.loading = false;
                this.$router.push("/");
            }
            catch{
                this.errorMessage = 'Uploaden is mislukt.';
            }

            this.loading = false;
        },
        async updatePublishedBook() {
            try
            {
                this.errorMessage = null;
                this.loading = true;
                let pb = await PublishedBookService.updatePublishedBook(this.getAccessToken(), this.publishedBookToEdit);
                this.loading = false;
                this.$router.push("/admin/publishedbooks/overview");
            }
            catch{
                this.errorMessage = 'Uploaden is mislukt.';
            }

            this.loading = false;
        }
    },
    components:{
        UserMenuCard
    },
  }
</script>