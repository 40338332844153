<template>

  <v-container style="max-width:100%" id="homeContainerId">
    <Banner id="homeBannerId"></Banner>
    <TopTradersWeek class="pt-8"></TopTradersWeek>
    <Introduction class="mb-3"></Introduction>
      <v-row class="text-md-center block">
        <v-col cols="12">
          <h1 class="text-md-h3 font-weight-bold mb-3 title-bookshare title-description">
            <div>Waarom lid worden van onze community?</div>
          </h1>
        </v-col>      
        <v-row class="text-center" justify="center">
          <v-col cols="6" class="hidden-sm-and-down">
              <v-img src="../assets/img/community.svg"></v-img>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6">
            <v-row>
              <v-col cols="2" class="mt-4 icon-community">
                <v-img src="../assets/ico/coin.svg"
                  max-height="36"
                  max-width="36">
                </v-img>           
              </v-col>
              <v-col class="text-left">
                <h2>Bespaar geld</h2>
                  <span>Bespaar geld door studieboeken tweedehands te kopen. Ruil je oude boeken in en gebruik dat geld voor je nieuwe boeken of laat je geld uitbetalen.</span>
                </v-col>
            </v-row>
            <v-row>
              <v-col cols="2" class="mt-4 icon-community">
                <v-img src="../assets/ico/leaf.svg"
                  max-height="36"
                  max-width="36">
                </v-img>                
              </v-col>
              <v-col class="text-left">
                <h2>Beter voor het milieu</h2>
                <span>Door tweedehands boeken te ruilen in plaats van nieuw te kopen, ben je ook nog eens duurzaam bezig. Top!</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2" class="mt-4 icon-community">
                <v-img src="../assets/ico/book.svg"
                  max-height="36"
                  max-width="36">
                </v-img>             
              </v-col>
                <v-col class="text-left">
                  <h2>Kies zelf de kwaliteit van de boeken</h2>
                <span>Controleer de kwaliteit van de boeken die je wilt ruilen omdat bijna iedereen foto’s uploadt van zijn of haar boeken.</span>
              </v-col>
            </v-row>
            <v-row class="ml-1 mt-4 mb-0">
              <v-col md="8" sm="1" lg="7" xl="6">
                <router-link to="/account" class="text-decoration-none">
                <v-btn color="primary">AAN DE SLAG</v-btn>
                </router-link>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-row>      
  </v-container>
</template>

<script>
import Banner from '@/components/home/Banner.vue'
import TopTradersWeek from '@/components/home/TopTradersWeek.vue'
import Introduction from '@/components/home/Introduction.vue'
import { mapActions } from 'vuex'

export default {
	name: 'Home',
  components: {
    Banner,
    TopTradersWeek,
    Introduction
  },
	data() {
		return {

		}
	},
  async created(){
    this.setPageSection('JOBS');
  },
  methods:{
    ...mapActions(['setPageSection']),

  },
}
</script>

<style scoped>
.v-application a {
  color: white !important;
}
.title-description {
  padding-top: 5%;
}
.icon-community {
  padding-top: 0% !important;
}
</style>
