import axios from "../plugins/axios";

const baseUrl = process.env.VUE_APP_BOOKSHARE_URL;

export default {

    async getDiscount(token, number, applyTo) {
      let result;  
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };

      let data = { number: number, applyTo: applyTo };
      await axios.post(`${baseUrl}/discounts/get-discount`, data, config)
      .then(response =>
        result = response.data);
      return result;
    },

    async getDiscounts(token) {
      let result;  
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };

      let data = {};
      await axios.post(`${baseUrl}/discounts/admin/discounts`, data, config)
      .then(response =>
        result = response.data);
      return result;
    },

    async isDiscountNumberUnique(token, number) {
      let result;  
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };

      let data = {number:number};
      await axios.post(`${baseUrl}/discounts/admin/discount-number-is-unique`, data, config)
      .then(response =>
        result = response.data);
      return result;
    },

    async addPrecentageDiscount(token, discount) {
      let result;  
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };

      let data = {
        data:{
          name:discount.name, 
          uniqueNumber:discount.uniqueNumber, 
          precentage:discount.amount, 
          startDate:discount.startDate, 
          endDate:discount.endDate,
          applyTo:discount.applyTo}
        };

      await axios.post(`${baseUrl}/discounts/admin/add-percentage-discount`, data, config)
      .then(response =>
        result = response.data);
      return result;
    },

    async addFixedAmountDiscount(token, discount) {
      let result;  
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };

      let data = {
        data:{
          name:discount.name, 
          uniqueNumber:discount.uniqueNumber, 
          discount:discount.amount, 
          startDate:discount.startDate, 
          endDate:discount.endDate,
          applyTo:discount.applyTo}
        };

      await axios.post(`${baseUrl}/discounts/admin/add-fixed-discount`, data, config)
      .then(response =>
        result = response.data);
      return result;
    },
}