<template>
     <v-card class="mt-n3 mb-8" flat v-if="getStudent().maximumNumberOfMessagesAllowedToSend && getStudent().maximumNumberOfMessagesAllowedToSend !=='-1' && getStudent().maximumNumberOfMessagesAllowedToSend !==-1" >
        <v-row class="ma-0 pa-0">
           <v-card-title class="mb-0 pb-0">Berichten</v-card-title>
           <v-card-text>
                <v-row>
                    <v-col cols="12" md="8">
                        Je kunt met je lidmaatschap per maand maximaal {{getStudent().maximumNumberOfMessagesAllowedToSend}} berichten versturen
                    </v-col>
                    <v-col>                
                        <v-row justify="end" class="mt-0 mr-md-0">
                            <v-btn small color="secondary" class="ml-md-2 mt-2 mt-md-0 black--text" @click="this.$router.push('/Subscription')">
                                meer berichten versturen?
                            </v-btn>   
                        </v-row>
                    </v-col>
                </v-row>
           </v-card-text>
           
        </v-row>
        <v-row>
            <v-col>
                <v-progress-linear
                    height="10"
                    :value="progress"
                    color="primary"
                    >
                </v-progress-linear>
            </v-col>
        </v-row>
         <v-row class="px-8 pb-8">
             <v-col>
                <v-row justify="begin">
                    {{getStudent().numerOfMessagesSentInPeriod}} verstuurd
                    <v-row justify="end" class="mt-0">
                        Resterend {{this.numberOfMessageRemaining}} berichten
                    </v-row>
                </v-row>
             </v-col>
        </v-row>      
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'Nav',
    props:['onReload'],
    data: () => ({
        numberOfMessageRemaining:0,
        progress:0,
    }),
    created(){
        this.loadPage();
    },
    methods:{
        ...mapGetters(['getStudent']),
        getNumberOfRemainingMessages(){
            this.numberOfMessageRemaining = this.getStudent().maximumNumberOfMessagesAllowedToSend - this.getStudent().numerOfMessagesSentInPeriod;
        },

        calculateCurrentProgress(){
            this.progress =  (100/this.getStudent().maximumNumberOfMessagesAllowedToSend)* this.getStudent().numerOfMessagesSentInPeriod;
        },

        loadPage(){
            this.getNumberOfRemainingMessages();
            this.calculateCurrentProgress();
        }
    },
    watch:{
        onReload: function(newValue, oldValue){
            this.loadPage();
        }
    }
    
}
</script>