import axios from "../plugins/axios";

const baseUrl = process.env.VUE_APP_BOOKSHARE_URL;

export default {

    async getPopularBooks(index, maxPerPage){
        let books;
        let data = { pageNumber: index, pageSize:maxPerPage };
        await axios.post(`${baseUrl}/publishedbooks/popular-books`,data)
        .then(response =>
          books = response.data.books);

        return books;
    },
    async searchPublishedBook(pageNumber, pageSize, searchCriteria) {
        let booksResponse;
        let data = { searchCriteria: searchCriteria, pageNumber: pageNumber, pageSize:pageSize };
        await axios.post(`${baseUrl}/publishedbooks/search`,data)
        .then(response =>
          booksResponse = response.data);
        return booksResponse;
    },
    async findPublishedBookById(publishedBookId) {
      let bookResponse;
      let data = { publishedBookId: publishedBookId };
      await axios.post(`${baseUrl}/publishedbooks/published-book`, data)
      .then(response =>
        bookResponse = response.data);
      return bookResponse;
    },
    async findPublishedBookByIsbn(isbn) {
      let bookResponse;
      let data = { isbn: isbn };
      await axios.post(`${baseUrl}/publishedbooks/find-by-isbn`, data)
      .then(response =>
        bookResponse = response.data.publishedBook);
      return bookResponse;
    },

    async getPublishedBooks(pageNumber, pageSize) {
      let bookResponse;
      let data = {pageNumber: pageNumber, pageSize:pageSize};

      await axios.post(`${baseUrl}/publishedbooks/published-books`, data)
      .then(response =>
        bookResponse = response.data.books);
      return bookResponse;
    },

    async getAdminPublishedBooks(token, pageNumber, pageSize) {
      let result;
      let data = {pageNumber: pageNumber, pageSize:pageSize};

      const config = {
          headers: { Authorization: `Bearer ${token}` }
      };

      await axios.post(`${baseUrl}/publishedbooks/admin/popular-books`, data, config)
      .then(response =>
        result = response.data);
      return result;
    },

    async getPublishedBook(publishedBookId) {
      let bookResponse;
      let data = {publishedBookId: publishedBookId};

      await axios.post(`${baseUrl}/publishedbooks/published-book`, data)
      .then(response =>
        bookResponse = response.data.publishedBook);
      return bookResponse;
    },

    async addPublishedBook(token, publishedBook) {
      let publishedBookRequest = {};
      publishedBookRequest.isbn = publishedBook.isbn;
      publishedBookRequest.title = publishedBook.title;
      publishedBookRequest.edition = publishedBook.edition;
      publishedBookRequest.author = publishedBook.author;
      publishedBookRequest.price = publishedBook.price;
      publishedBookRequest.photo = publishedBook.photo;
      publishedBookRequest.price = publishedBookRequest.price;

      publishedBookRequest.physicalProperties = {
        height: publishedBook.physicalProperties.height,
        weight: publishedBook.physicalProperties.weight,
        width: publishedBook.physicalProperties.width
      };

      let result;
      const config = {
          headers: { Authorization: `Bearer ${token}` }
      };

      let data = {
        "publishedBook":  publishedBookRequest
      }

      await axios.post(`${baseUrl}/publishedbooks/admin/add`, data, config)
              .then(response =>
                result = response.data);

      return result;
    },
    async updatePublishedBook(token, publishedBook) {
      let publishedBookRequest = {};
      publishedBookRequest.id = publishedBook.id;
      publishedBookRequest.isbn = publishedBook.isbn;
      publishedBookRequest.title = publishedBook.title;
      publishedBookRequest.edition = publishedBook.edition;
      publishedBookRequest.author = publishedBook.author;
      publishedBookRequest.price = publishedBook.price;
      publishedBookRequest.photo = publishedBook.photo;
      publishedBookRequest.price = publishedBook.price;

      publishedBookRequest.physicalProperties = {
        height: publishedBook.physicalProperties.height,
        weight: publishedBook.physicalProperties.weight,
        width: publishedBook.physicalProperties.width
      };

      let result;
      const config = {
          headers: { Authorization: `Bearer ${token}` }
      };

      let data = {
        "publishedBook":  publishedBookRequest
      }

      await axios.post(`${baseUrl}/publishedbooks/admin/edit`, data, config)
              .then(response =>
                result = response.data);

      return result;
    },
}
