<template>
    <v-container>
        <v-row>
             <v-col cols="3" class="hidden-sm-and-down pt-0">
                <UserMenuCard></UserMenuCard>
            </v-col>
            <v-col cols="12" md="6" v-if="!error" class="pt-0 mt-n4">
                <OverviewStudentReviews :disableClosingDialog="true" :sellerId="getStudent().id"></OverviewStudentReviews>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import OverviewStudentReviews from '../components/OverviewStudentReviews.vue'
import UserMenuCard from '../components/UserMenuCard.vue';

export default {
    data() {
        return {
            transaction: null,
            error:false,
        }
    },

    methods: {
        ...mapGetters(['getAccessToken']),
        ...mapGetters(['getStudent']),
        async getTransaction(){
          
        }
    },

    components:{
        OverviewStudentReviews,
        UserMenuCard
    }
}
</script>