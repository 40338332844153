<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" md="8">
            <v-tabs>
                <v-tab ref="#jobs">JOBS</v-tab>
                <v-tab ref="#skills">Skills</v-tab>
                <v-tab ref="#companies">Companies</v-tab>
                <v-tab-item key="jobs">
                    <JobsConfiguration :loadedCompanies="companies" class="pa-6"></JobsConfiguration>
                </v-tab-item>
                <v-tab-item key="skills">
                    <SkillsConfiguration></SkillsConfiguration>
                </v-tab-item>
                <v-tab-item key="companies">
                    <CompaniesConfiguration @reloadedCompanies='reloadCompanies'></CompaniesConfiguration>
                </v-tab-item>
            </v-tabs>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import JobsConfiguration from '../../../components/admin/jobs/JobsConfiguration.vue'
import SkillsConfiguration from '../../../components/admin/jobs/SkillsConfiguration.vue'
import CompaniesConfiguration from '../../../components/admin/jobs/CompaniesConfiguration.vue'


export default {
    data () {
        return {
            companies:[]
        }
    },

    methods:{
        reloadCompanies({ companies }) {
            this.companies = companies;
        },
    },

    components:{
         JobsConfiguration,
         SkillsConfiguration,
         CompaniesConfiguration
    }
}
</script>
