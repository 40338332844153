import axios from "../plugins/axios";

const baseUrl = `${process.env.VUE_APP_BOOKSHARE_URL}/shoppingcart/`;

export default {

    async getCheckouts(token, pageNumber, pageSize) {
        let checkouts;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        let data = {
          pageNumber:  pageNumber,
          pageSize: pageSize
        }

        await axios.post(`${baseUrl}checkouts`, data, config)
                .then(response =>
                    checkouts = response.data);

        return checkouts;
    },

    async getCheckoutsAsAdmin(token, from, to, pageNumber, pageSize) {
        let result;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        let data = { from: from, to:to, pageNumber:pageNumber, pageSize:pageSize };
        await axios.post(`${baseUrl}admin/checkouts`, data, config)
                .then(response =>
                  result = response.data);
        return result;
    },

    async getSoldBooks(token, pageNumber, pageSize) {
        let checkouts;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        let data = {
          pageNumber:  pageNumber,
          pageSize: pageSize
        }

        await axios.post(`${baseUrl}sold-books`, data, config)
                .then(response =>
                    checkouts = response.data);

        return checkouts;
    },

    async checkout(token, books, discounts) {
        let checkout;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        let data = {
          "books":  books,
          "discounts": discounts
        }
        await axios.post(`${baseUrl}checkout`, data, config)
                .then(response =>
                    checkout = response.data);

        return checkout;
    },

    async pay(token, checkoutId) {
        let checkout;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        let data = {
          "checkoutId":  checkoutId
        }
        await axios.post(`${baseUrl}pay`, data, config)
                .then(response =>
                    checkout = response.data);

        return checkout;
    },

    async update(account, subscription) {
        let result;
        let data = {
          "account": account,
          "applicationId": subscription
        }
        
        await axios.post(`${baseUrl}update`, data)
                .then(response =>
                    result = response.data);
                    
        return result;
    },

    async updatePaymentStatus(token, paymentId) {
        let result;
        
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        let data = {
          "paymentId":  paymentId
        }
        await axios.post(`${baseUrl}admin/update-payment-status`, data, config)
                .then(response =>
                  result = response.data);
    
        return result;
      },
}
