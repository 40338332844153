<template>
<v-container id="loginDialogContainerId">
    <v-card flat width="500" height="620" class="mx-auto mt-5">
      <v-card-title primary-title class="justify-center title">
        <h2>Login</h2>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form justify="center">
          <v-row>
            <v-col cols="12">
              <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field 
                          id="usernameId"
                          v-model="userName"
                          :rules="[rules.required]"
                          label="Gebruikersnaam"
                          placeholder="Gebruikersnaam"
                          prepend-inner-icon="mdi-account-circle" 
                          dense
                          outlined>
                      </v-text-field>
                      <v-text-field 
                          id="passwordId"
                          v-model="password"
                          prepend-inner-icon="mdi-lock-outline" 
                          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                          :rules="[rules.required, rules.min]"
                          :type="showPassword ? 'text' : 'password'"
                          label="Wachtwoord"
                          hint="Ten minste 8 tekens"
                          dense
                          outlined
                          @click:append="showPassword = !showPassword">
                      </v-text-field>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12">
                            <router-link to="/forgotpassword" class="btn btn-link" @click.native="closeDialogs()"
                            style="float: right;"><span>Wachtwoord vergeten?</span></router-link>
                          </v-col>
                          <v-col cols="12">
                            <router-link  to="/forgotusername" @click.native="closeDialogs()"
                              class="btn btn-link" style="float: right;">Gebruikersnaam vergeten?</router-link>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-col>
                  </v-row>
              </v-card-text>
            </v-col>
            <v-col>
              <v-card-text class="py-0">
                  <v-row>
                    <v-col cols="12">
                      <v-btn
                        id="tryToLoginButtonId" 
                        block
                        :loading="loading"
                        color="primary"
                        :disabled="!formValid || loading"
                        @click="login()">
                        LOGIN
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" md="4"></v-col>
                    <v-col>
                     <h4 id="failedLoginMessageId" class="red--text ml-3" v-if="errorMessage">{{errorMessage}}</h4>
                    </v-col>
                  </v-row>
              </v-card-text>
            </v-col>
          </v-row>
        </v-form>    
      </v-card-text>

      <div class="hr-sect">of</div>

      <v-card-text>
        <v-row justify="center">
          <v-col cols="12" md="6">
            <a style="color: black;">Heb je nog geen account? </a> <br/>
            <router-link  to="/registerstudent" @click.native="closeDialogs()"
              class="btn btn-link">Maak direct een account aan</router-link>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
</v-container>
</template>

<script>
import AccountService from '@/services/AccountService'
import ConversationService from '../services/ConversationService'
import { mapActions } from 'vuex'

export default {
  name: 'Login',
  props:['navigate'],
	data() {
		return {
      password: '',
      userName: '',
			showPassword: false,
      loading: false,
      errorMessage: null,
			links: ['Home', 'Login'],
      rules: {
        required: value => !!value || 'Verplicht.',
        min: v => v?.length >= 8 || 'Ten minste 8 tekens',
        email: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail is niet geldig'
      },
		}
	},
  created() {
    this.authentication = {};
    this.student = {};
  },
   computed: {
      formValid() {
          return this.userName &&
              this.password && 
              this.password.length >= 8;
      }
    },
    methods:{
      ...mapActions(['setSuccessfulLogin']),
      ...mapActions(['setStudentsAccount']),
      ...mapActions(['saveNumberOfUnreadMessages']),
      async login(){
        try{
          this.errorMessage = null;
          this.loading = true;
          let authentication = await AccountService.loginUser(this.userName, this.password);
          if(authentication.accessToken != null){
              this.setSuccessfulLogin(authentication);
              this.$emit('close-dialog');
              
              let student = await AccountService.getAccountOfStudent(authentication.accessToken);
              this.setStudentsAccount(student);

              let result = await ConversationService.getNumberofUnreadMessages(authentication.accessToken);
              this.saveNumberOfUnreadMessages(result.totalUnreadMessages);

              if(this.navigate){
                this.$router.go(-1);
              }
          }else{
            this.errorMessage = "Inloggen is mislukt.";
          }
        }
        catch{
          this.errorMessage = "Inloggen is mislukt.";
        }
        this.loading = false;
      },
      register(){
        this.$emit('registerClicked');
      },
      closeDialogs(){
        this.$emit('closeDialogs');
      }
    }

}
</script>

<style scoped>
.title {
  padding-top: 5%;
  padding-bottom: 5%;
}
.button-ico {
  padding-right: 5%;
}
.hr-sect {
    display: flex;
    flex-basis: 100%;
    align-items: center;
    color: black !important;
    margin: 8px 0px;
}
.hr-sect:before,
.hr-sect:after {
    content: "";
    flex-grow: 1;
    background: rgba(0, 0, 0, 0.12);
    height: 1px;
    font-size: 0px;
    line-height: 0px;
    margin: 0px 8px;
}
.google-color {
  color: #4285F4;
}
.facebook-color {
  color: #3B5998;
}
</style>