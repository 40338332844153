<template>
  <v-row>
    <v-col cols="12">
      <v-card outlined>
        <v-list>
          <v-list-item-group>
            <v-list-item 
              class="menu" 
              v-for="(item, i) in items" 
              :key="i" 
              :to="item.path">
              <v-list-item-content>
                  <v-list-item-title :id="item.id" v-if="item.index===7 && getNumberOfUnreadMessages()>0" class="mt-n3">
                    {{item.text}}
                    <v-badge
                      :content="getNumberOfUnreadMessages()"
                      color="primary"
                      style="padding-left:12px; padding-bottom:10px"
                      overlap>
                    </v-badge>
                  </v-list-item-title>
                  <v-list-item-title v-if="item.index===7 && getNumberOfUnreadMessages()===0">
                    {{item.text}}
                  </v-list-item-title>
                <v-list-item-title :id="item.id" v-if="item.index!==7">{{item.text}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
          <v-list-group v-if="isAdmin()" no-action :value="true">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Admin</v-list-item-title>
              </v-list-item-content>
            </template>
            <div v-for="(item, i) in menuItemsAdmin" :key="i" class="ml-4">
              <!-- <v-list-group no-action sub-group v-if="item.subMenu.length == 0"> -->
                <v-list-item :to="item.path">
                  <v-list-item-content>
                    <v-list-item-title :id="item.id">{{item.text}}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              <!-- </v-list-group> -->
              <!-- <v-list-group no-action sub-group v-if="item.subMenu.length == 0">
                <v-list-item-title 
                  v-text="item.text"
                ></v-list-item-title>
              </v-list-group> -->
            </div>
          </v-list-group>
          <v-list-item-group>
            <v-list-item @click="handleMenuClick()" id="logoutMenuId">
              <v-list-item-content>
                <v-list-item-title>
                  Logout
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

  export default {
    data: () => ({
      unreadMessages:0,
      update: false,
      items: [
        {
          index: 1,
          text: 'Account',
          path: '/Account',
          id: 'accountMenuId'
        },
        {
          index: 2,
          text: 'Mijn boeken',
          path: '/MyBooks',
          id: 'myBooksMenuId'
        },
        {
          index: 3,
          text: 'Mijn aankopen',
          path: '/Purchases',
          id: 'purchasesMenuId'
        },
        {
          index: 4,
          text: 'Mijn verlanglijstje',
          path: '/MyPublishedBookAlerts',
          id: 'myPublishedBookAlertsMenuId'
        },
        {
          index: 5,
          text: 'Verkocht',
          path: '/SoldBooks',
          id: 'soldBooksMenuId'
        },
        {
          index: 6,
          text: 'Mijn cashouts',
          path: '/MyCashouts',
          id: 'myCashoutsMenuId'
        },
        {
          index: 7,
          text: 'Mijn berichten',
          path: '/MyConversations',
          id: 'myConversationsMenuId'
        },
        { 
          index: 8, 
          text: 'Jobs', 
          path: '/JobsOverview', 
          id: 'jobsMenuId'
        },
        { 
          index: 9, 
          text: 'Reviews', 
          path: '/ReviewsOverview', 
          id: 'reviewsMenuId'
        },
      ],
      menuItemsAdmin: [
        {
          index: 9,
          text: 'Published Book',
          path: '/admin/publishedbooks/addorupdate',
          isAdmin: true,
          subMenu: [],
          id: 'addorupdateId'
        },
        {
          index: 10,
          text: 'Users',
          path: '/admin/students/studentsoverview',
          isAdmin: true,
          subMenu: [],
          id: 'studentsoverviewId'
        },
        {
          index: 11,
          text: 'Discounts',
          path: '/admin/discounts/discountsoverview',
          isAdmin: true,
          subMenu: [],
          id: 'discountsoverviewId'
        },
        {
          index: 12,
          text: 'Cashouts',
          path: '/admin/cashouts/cashouts',
          isAdmin: true,
          subMenu: [],
          id: 'cashoutsId'
        },
        {
          index: 13,
          text: 'Shipments',
          path: '/admin/shipments/shipments',
          isAdmin: true,
          expanded:false,
          id: 'shipmentsId',
          subMenu: [{
                      index: 14,
                      text: 'Not confirmed',
                      path: '/admin/shipments/notconfirmedshipments',
                      isAdmin: true },
                    {
                      index: 15,
                      text: 'All',
                      path: '/admin/shipments/allshipments',
                      isAdmin: true },
                    {
                      index: 16,
                      text: 'Update',
                      path: '/admin/shipments/updateshipments',
                      isAdmin: true }]
        },
        {
          index: 17,
          text: 'Checkouts',
          path: '/admin/checkouts/checkoutsoverview',
          isAdmin: true,
          id: 'checkoutsoverviewId',
          subMenu: []
        },
        {
          index: 18,
          text: 'Statistieken',
          path: '/admin/statistics/statisticsoverview',
          isAdmin: true,
          id: 'statisticsoverviewId',
          subMenu: []
        },
        {
          index: 19,
          text: 'Conversaties',
          path: '/admin/conversations/conversationsoverview',
          isAdmin: true,
          id: 'conversationsoverviewId',
          subMenu: []
        },
        {
          index: 20,
          text: 'Advertisements',
          path: '/admin/advertisements/advertisementsoverview',
          id: 'advertisementsoverviewId',
          isAdmin: true,
          subMenu: []
        },
        {
          index: 21,
          text: 'Jobs',
          path: '/admin/jobs/jobsadminoverview',
          id: 'jobsadminoverviewId',
          isAdmin: true,
          subMenu: []
        },
        {
          index: 22,
          text: 'Intervenes',
          path: '/admin/intervene/intervenesoverview',
          id: 'interveneoverviewId',
          isAdmin: true,
          subMenu: []
        },
      ],
    }),
    computed: {
      ...mapState(['numberOfUnreadMessages']),
    },
    watch: {
      numberOfUnreadMessages(newValue, oldValue) {
        if (newValue) {
          this.unreadMessages = newValue;
        }
      },
    },
    methods:{
      ...mapActions(['logout']),
      ...mapGetters(['isAdmin']),
      ...mapGetters(['getNumberOfUnreadMessages']),
      
      handleMenuClick(){
          this.logout();
          this.$router.push('/');
          this.$router.go();
      },
      
      handleAddPBClicked(){
        this.$router.push("/admin/publishedbooks/addorupdate");
      },
      
      shouldExpand(item){
        if(this.update){
          if(item.expanded){
              item.expanded = false;
              this.update = false;
              return false;
          } else{
            item.expanded = true;
            this.update = false;
            return true;
          } 
        } else {
          return true;
        }

      }
    }
  }
</script>

<style scoped>
  .menu:hover {
    color: white !important;
    background-color: #77EDCE !important;
  }
  .menu:active[aria-selected="true"] {
    color: white !important;
    background-color: #77EDCE !important;
  }
  .v-list .v-list-item--active {
    color: white !important;
    background-color: #77EDCE !important;
  }
</style>