<template>
    <v-container>
        <v-row justify="center" v-if="!loading && (!jobs || jobs.length === 0)">
            <v-col cols="6">
                Het spijt ons dat we op dit moment geen vacatures hebben die passen bij jouw unieke talenten en aspiraties. We zijn voortdurend op zoek naar bruisende, nieuwe energie om ons team te versterken, dus houd ons vacaturebord nauwlettend in de gaten. Zodra een prikkelende functie verschijnt die je hart sneller doet kloppen, nodigen we je van harte uit om je enthousiasme met ons te delen en zo snel mogelijk te reageren. Jouw droomjob is wellicht slechts één klik verwijderd!
            </v-col>
        </v-row>
        <v-row justify="center" v-if="loading">
            <v-progress-circular
                indeterminate
                color="primary">
            </v-progress-circular>
        </v-row>
        <v-row>
            <v-col cols="12" md="3">
                <v-row>
                    <v-col cols="12" v-if="totalPages > 1 && jobs && jobs.length > 0">
                        <v-row>
                            <v-col>
                                <v-pagination
                                style="padding-bottom: 3%; padding-top: 3%"
                                v-model="pageNumber"
                                :length="totalPages"
                                @input="next"
                                :total-visible="7">
                                </v-pagination>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col>
                        <router-link to="" @click.native="selectJob(job)" v-for="(job, index) in jobs" :key="index" style="text-decoration: none;">
                            <!-- <v-card :style="selectedJob.id == job.id ? 'secondary' : 'white' 'border: 1px solid red;', 'border: 1px solid white;'" class="mb-2" :color="selectedJob.id == job.id ? 'secondary' : 'white'" outlined> -->
                                <v-card :style="selectedJob.id == job.id ? 'border: 2px solid #644fab;' : 'border: 1px solid white'" class="mb-2" outlined>
                                <v-card-subtitle class="font-weight-bold">{{job.name}}</v-card-subtitle>
                                <v-card-subtitle class="ma-0 p-0">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-img :src="job.company.urlLogo" width="100"></v-img>
                                        </v-col>
                                        <v-col class="py-0" cols="12">
                                            {{job.company.name}}
                                        </v-col>
                                    </v-row>
                                </v-card-subtitle>
                                <div class="hidden-md-and-up">
                                    <v-card-subtitle class="ma-0 pt-0">{{job.company.address}}</v-card-subtitle>
                                    <v-card-subtitle class="ma-0 pt-0" v-if="job.jobType">
                                        <v-row>
                                            <v-col>
                                                <v-icon class="mt-n2">
                                                    mdi-briefcase-variant
                                                </v-icon>
                                                {{getTypeOfWork(job.jobType)}}
                                            </v-col>
                                        </v-row>
                                    </v-card-subtitle> 
                                    <v-card-subtitle class="ma-0 pt-0" v-if="job.workLocation">
                                        <v-row>
                                            <v-col>
                                                <v-icon class="mt-n2">
                                                    mdi-map-marker
                                                </v-icon>
                                                {{getWorkLocation(job.workLocation)}}
                                            </v-col>
                                        </v-row>
                                    </v-card-subtitle> 
                                    <v-card-subtitle class="ma-0 pt-0">
                                        <v-row>
                                            <v-col>
                                                <v-icon class="mt-n1">
                                                    mdi-domain
                                                </v-icon>
                                                {{job.company.numberOfEmployees}}
                                            </v-col>
                                        </v-row>
                                    </v-card-subtitle> 
                                    <v-card-subtitle class="ma-0 pt-1" v-if="job.company.numberOfSubmits">
                                        <v-row>
                                            <v-col>
                                                <v-icon class="mt-n1">
                                                    mdi-lightbulb-on-outline
                                                </v-icon>
                                                <div v-if="job.company.numberOfSubmits>0">{{job.company.numberOfSubmits}} mensen hebben hierop gereageerd.</div>
                                                <div v-if="job.company.numberOfSubmits == 0">Wees de eerste om hierop te reageren</div>
                                            </v-col>
                                        </v-row>
                                    </v-card-subtitle>
                                    <v-card-subtitle class="ma-0 pt-0">
                                        <v-row>
                                            <v-col>
                                                <v-icon class="mt-n1">
                                                    mdi-order-bool-ascending-variant
                                                </v-icon>
                                                <label v-for="(skill, index) in job.skills" :key="index">
                                                    {{skill.name}}, 
                                                </label>
                                            </v-col>
                                        </v-row>
                                    </v-card-subtitle>
                                    <v-card-subtitle class="ma-0 pt-0">
                                        <v-row>
                                            <v-col>
                                                <v-btn color="primary" @click="apply()">
                                                    SOLLICITEREN
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card-subtitle>        
                                   <v-expansion-panels flat>
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>
                                            About the job
                                            <template v-slot:actions>
                                                <v-icon color="primary">
                                                $expand
                                                </v-icon>
                                            </template>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <div v-html="job.description"> </div>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </div>
                            </v-card>
                        </router-link>
                    </v-col>
                    <v-col cols="12" v-if="totalPages > 1 && jobs && jobs.length > 0">
                        <v-row>
                            <v-col>
                                <v-pagination
                                style="padding-bottom: 3%; padding-top: 3%"
                                v-model="pageNumber"
                                :length="totalPages"
                                @input="next"
                                :total-visible="7">
                                </v-pagination>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
            <v-col v-if="selectedJob && selectedJob.name">
                 <v-card flat>
                    <v-card-title>{{selectedJob.name}}</v-card-title>
                    <v-card-subtitle class="ma-0 p-0">
                        <v-row>
                            <v-col class="py-0" cols="12">
                                {{selectedJob.company.name}}
                            </v-col>
                        </v-row>
                    </v-card-subtitle>
                    <v-card-subtitle class="ma-0 pt-0">{{selectedJob.company.address}}</v-card-subtitle>                    
                    <v-card-subtitle class="ma-0 pt-0" v-if="selectedJob.jobType">
                        <v-row>
                            <v-col>
                                <v-icon class="mt-n2">
                                    mdi-briefcase-variant
                                 </v-icon>
                                {{getTypeOfWork(selectedJob.jobType)}}
                            </v-col>
                        </v-row>
                    </v-card-subtitle> 
                    <v-card-subtitle class="ma-0 pt-0" v-if="selectedJob.workLocation">
                        <v-row>
                            <v-col>
                                <v-icon class="mt-n2">
                                    mdi-map-marker
                                 </v-icon>
                                {{getWorkLocation(selectedJob.workLocation)}}
                            </v-col>
                        </v-row>
                    </v-card-subtitle> 
                     <v-card-subtitle class="ma-0 pt-0">
                        <v-row>
                            <v-col>
                                <v-icon class="mt-n1">
                                    mdi-domain
                                </v-icon>
                                {{selectedJob.company.numberOfEmployees}}
                            </v-col>
                        </v-row>
                    </v-card-subtitle> 
                    <v-card-subtitle class="ma-0 pt-1" v-if="selectedJob.company.numberOfSubmits">
                        <v-row>
                            <v-col>
                                <v-icon class="mt-n1">
                                    mdi-lightbulb-on-outline
                                </v-icon>
                                <div v-if="selectedJob.company.numberOfSubmits>0">{{selectedJob.company.numberOfSubmits}} mensen hebben hierop gereageerd.</div>
                                <div v-if="selectedJob.company.numberOfSubmits == 0">Wees de eerste om hierop te reageren</div>
                            </v-col>
                        </v-row>
                    </v-card-subtitle>
                    <v-card-subtitle class="ma-0 pt-0" v-if="selectedJob && selectedJob.skills && selectedJob.skills.length > 0">
                        <v-row>
                            <v-col>
                                <v-icon class="mt-n1">
                                    mdi-order-bool-ascending-variant
                                </v-icon>
                                <label v-for="(skill, index) in selectedJob.skills" :key="index">
                                    {{skill.name}}, 
                                </label>
                            </v-col>
                        </v-row>
                    </v-card-subtitle>
                    <v-card-subtitle class="ma-0 pt-0">
                        <v-row>
                            <v-col>
                                <v-btn color="primary" @click="apply()">
                                    SOLLICITEREN
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-subtitle>
                </v-card>
                <v-card flat>
                    <v-card-title>About the job</v-card-title>
                    <v-card-subtitle>{{selectedJob.name}}</v-card-subtitle>
                    <v-card-text>
                    <div v-html="selectedJob.description"> </div>
                    </v-card-text> 
                </v-card>
            </v-col>
        </v-row>
         <v-row>
            <v-dialog v-model="showDialog" max-width="600">
                <ApplyJob @closeDialog="closeApplyJobResult" :selectedJob="selectedJob"></ApplyJob>
            </v-dialog>
            <v-dialog v-model="showAppliedResult" max-width="600">
                 <v-card>
                    <v-card-text>
                        <div class="pt-12 px-6">
                            Je sollicitatie voor de functie van {{selectedJob.name}} is ingediend. 
                            Je ontvangt binnen enkele minuten meer informatie over je sollicitatie.
                        </div>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn
                            text
                            @click="showAppliedResult = false">Sluiten
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Resize } from 'vuetify/lib/directives';
import JobService from '../../services/JobService'
import ApplyJob from '../../components/ApplyJob.vue'

export default {
    data () {
        return {
            loading:false,
            errorMessage: null,
            jobs:[],
            name:'',
            address:'',
            about: '',
            numberOfEmployees: '',
            pageNumber: 1,
            pageSize:5,
            selectedJob:{},
            totalPages:0,
            showDialog:false,
            showAppliedResult:false,
        }
    },
    directives: {
        Resize
    },

    components:{
        ApplyJob
    },

    async created() {
        this.setPageSection('BOOKS');
        await this.getJobs();
    },

    mounted() {
        window.addEventListener('resize', this.checkScreenSize);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.checkScreenSize);
    },

    methods:{
        ...mapGetters(['getAccessToken']),
        ...mapActions(['setPageSection']),
        selectJob(job){
            if(this.$vuetify.breakpoint.name === 'sm' ||
                this.$vuetify.breakpoint.name === 'xs') {
            } else{
                this.selectedJob = job;
                this.scrollToTop();
            }
        },

        closeApplyJobResult(success){
            this.showDialog=false;
            if(!success) this.showAppliedResult = true;
        },

        getWorkLocation(workLocation){
            if(workLocation === 0) return "Remote";
            else if(workLocation === 1) return "Op locatie";
            else if(workLocation === 2) return "Hybrid";
        },

        getTypeOfWork(type){
            if(type === 0) return "Parttime";
            else if(type === 1) return "Fulltime";
            else if(type === 2) return "Freelance";
        },

        getFriendlyDate(val){
            var date = new Date(val);
            return date.getDate()  + "-" + (date.getMonth()+1) + "-" + date.getFullYear();
        },

        async apply(){
            this.showDialog = true;
        },

        async next(){
            await this.getJobs();
            this.scrollToTop();
        },

        scrollToTop() {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
        },

        async getJobs(){
            try {
                this.errorMessage = null;
                this.loading = true;

                var result = await JobService.getJobs(this.pageSize, (this.pageNumber - 1));
                this.jobs = result.jobs;

                if(this.$vuetify.breakpoint.name === 'sm' ||
                    this.$vuetify.breakpoint.name === 'xs') {
                    this.selectedJob = {};
                } 
                else {
                    if(this.jobs.length > 0){
                        this.selectedJob = this.jobs[0];
                    }
                }

                this.totalPages = Math.ceil((result.totalCount / this.pageSize));

            } catch {
                this.errorMessage = 'Er is helaas een fout opgetreden.';
                console.error('getJobs failed.');
            }
            this.loading = false;
        },
    }
}
</script>