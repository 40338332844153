<template>
    <v-container class="white">
        <v-row v-if="resultMessage">
            <v-col cols="2" md="3"></v-col>
            <v-col>
                <h4 class="red--text ml-3">{{resultMessage}}</h4>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="py-md-4 white" v-if="showModifyNameSurname">
            <v-col cols="12">
                <v-card flat class="py-0">
                    <v-card-text class="py-0">
                       <v-row class="py-0">
                            <v-col cols="12" md="6" class="py-0">
                                <v-text-field
                                    class="py-0" 
                                    id="nameId"
                                    dense 
                                    outlined 
                                    :rules="[rules.required]" 
                                    label="Naam"
                                    ref="nameRef"
                                    @keyup="studentNameChanged()"
                                    v-model="name">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" class="py-0">
                                <v-text-field class="py-0"
                                    id="surnameId"
                                    dense 
                                    outlined 
                                    :rules="[rules.required]" 
                                    label="Achternaam"
                                    ref="surnameRef"
                                    @keyup="studentSurnameChanged()"
                                    v-model="surname">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="pt-0 pb-4 white" v-if="!showModifyNameSurname">
            <v-col cols="12" md="6" class="py-0">
                <v-card flat class="py-0">
                    <v-card-text>
                        <v-col class="pa-0" cols="12">
                                {{ getStudent().firstName }} {{ getStudent().lastName }}
                            </v-col>
                            <v-col class="pa-0" cols="12">
                                <v-btn  x-small 
                                        @click="showModifyNameSurname = true" 
                                        color="secondary" 
                                        class="black--text">
                                    <v-icon x-small>mdi-pencil</v-icon>
                                </v-btn>
                            </v-col>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="residentialAddress && !showModifyAddresses" class="mb-4">
            <v-col cols="12" md="6" class="py-0">
                <v-card flat class="py-0">
                    <v-card-title class="py-0">Woonadres:</v-card-title>
                    <v-card-text>
                        <v-col class="pa-0" cols="12">
                            {{residentialAddress.street}} {{residentialAddress.houseNumber}} {{residentialAddress.additionHouseNumber}} 
                        </v-col>
                        <v-col class="pa-0" cols="12">
                            {{residentialAddress.postalCode}} {{residentialAddress.city}}
                        </v-col>
                        <v-col class="pa-0" cols="12">
                            <v-btn x-small @click="showModifyAddresses = true" color="secondary" class="black--text">
                                <v-icon x-small>mdi-pencil</v-icon>
                            </v-btn>
                        </v-col>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="6" class="pt-0">
                <v-card flat class="py-0">
                    <v-card-title class="py-0">Afleveradres:</v-card-title>
                    <v-card-text>
                        <v-col class="pa-0" cols="12">
                                {{postalAddress.street}} {{postalAddress.houseNumber}} {{postalAddress.additionHouseNumber}} 
                            </v-col>
                            <v-col class="pa-0" cols="12">
                                {{postalAddress.postalCode}} {{postalAddress.city}}
                            </v-col>
                            <v-col class="pa-0" cols="12">
                                <v-btn x-small @click="showModifyAddresses = true; showPostAddress = true;" color="secondary" class="black--text">
                                    <v-icon x-small>mdi-pencil</v-icon>
                                </v-btn>
                            </v-col>
                    </v-card-text>
                </v-card>
            </v-col>
           
        </v-row>  
        <v-row class="py-0 mb-0 mt-md-n5 white" v-if="showModifyAddresses">
            <v-col cols="12" class="py-0 mb-0">
                <v-card flat class="py-0 mb-0">
                    <v-card-text>
                       <v-row class="py-0">
                            <v-col cols="12" md="4" class="py-0">
                                <v-text-field 
                                    class="py-0"
                                    id="residentialAddressPostalCodeId"
                                    :loading="loading" 
                                    dense 
                                    outlined 
                                    @keyup="isValidResidentialAddress()"
                                    :rules="[rules.required, rules.postalcode]" 
                                    ref="residentialAddressPostalCodeRef"
                                    label="Postcode"
                                    maxlength="6" 
                                    v-model="residentialAddress.postalCode">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" class="py-0">
                                <v-text-field
                                    id="residentialAddressHouseNumberId"
                                    :loading="loading" 
                                    dense
                                    ref="residentialAddressHouseNumberRef"
                                    outlined
                                    @keyup="isValidResidentialAddress()"
                                    :rules="[rules.required]" 
                                    class="py-0" 
                                    label="Huisnummer" 
                                    :error-messages="residentialAddressNotFoundErrorMessage"
                                    v-model="residentialAddress.houseNumber">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" class="py-0">
                                <v-text-field
                                    id="residentialAddressAdditionalHouseNumberId" 
                                    :loading="loading" 
                                    dense 
                                    outlined 
                                    class="py-0"
                                    label="Toevoeging" 
                                    @keyup="isValidResidentialAddress()"
                                    v-model="residentialAddress.additionHouseNumber">
                                </v-text-field>
                            </v-col>
                        </v-row>

                        <v-row class="py-0">
                            <v-col class="py-0" cols="12" md="6">
                                <v-text-field
                                id="residentialAddressStreetId" 
                                disabled 
                                :loading="loading" 
                                dense outlined 
                                :rules="[rules.required]" 
                                label="Adres" 
                                v-model="residentialAddress.street">
                                </v-text-field>
                            </v-col>
                            <v-col class="py-0" cols="12" md="6">
                                <v-text-field
                                id="residentialAddressCityId" 
                                disabled
                                outlined 
                                dense 
                                class="pl-0" 
                                label="Plaats" 
                                :rules="[rules.required]" 
                                v-model="residentialAddress.city"
                                :loading="loading">
                                </v-text-field>
                            </v-col>
                        </v-row>

                        <v-row class="my-0">
                            <v-col class="ml-3 pt-0">
                                <v-checkbox class="py-0"
                                id="residentialAddressSameAsPostalAddressId"  
                                v-model="showPostAddress" 
                                label="Afleveradres is anders" 
                                @click="residentialSameAsPostalAddress()">
                                </v-checkbox>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" class="mt-n8 py-0 mb-3 mb-md-0">
                <v-card flat v-if="!postalAddress || showPostAddress" class="py-0">
                    <v-card-title class="mb-4">Afleveradres:</v-card-title>
                    <v-card-text class="py-0">
                        <v-row class="py-0"> 
                            <v-col cols="12" class="py-0">
                                <v-row class="py-0">
                                   <v-col cols="12" md="4" class="py-0">
                                        <v-text-field
                                            id="postalAddressPostalCodeId" 
                                            :loading="loading" 
                                            outlined 
                                            dense 
                                            class="pb-0" 
                                            label="Postcode"
                                            maxlength="6"
                                            ref="postalAddressPostalCodeRef" 
                                            :rules="[rules.required, rules.postalcode]"
                                            @keyup="isValidPostalAddress()" 
                                            v-model="postalAddress.postalCode">
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4" class="py-0">
                                        <v-text-field
                                            id="postalAddressHouseNumberId"  
                                            :loading="loading" 
                                            outlined 
                                            dense 
                                            class="py-0"
                                            ref="postalAddressHouseNumberRef"
                                            @keyup="isValidPostalAddress()"
                                            label="Huisnummer"
                                            :error-messages="postalAddressNotFoundErrorMessage" 
                                            v-model="postalAddress.houseNumber">
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4" class="pt-0">
                                        <v-text-field
                                            id="postalAddressaAditionHouseNumberId"   
                                            :loading="loading" 
                                            outlined 
                                            dense
                                            class="pt-0"
                                            @keyup="isValidPostalAddress()" 
                                            label="Toevoeging" 
                                            v-model="postalAddress.additionHouseNumber">
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="pb-md-3">
                            <v-col cols="12" md="6" class="py-0">
                                <v-text-field
                                    id="postalAddressaStreetId"
                                    :loading="loading" 
                                    dense
                                    disabled 
                                    outlined 
                                    class="py-0" 
                                    label="Adres" 
                                    v-model="postalAddress.street">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" class="pt-0">
                                <v-text-field
                                    id="postalAddressaCityId" 
                                    :loading="loading" 
                                    dense 
                                    outlined
                                    disabled 
                                    class="pt-0" 
                                    label="Plaats" 
                                    :rules="[rules.required]" 
                                    v-model="postalAddress.city">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="telephoneNumber != null && !showTelephoneNumber" class="mt-n9">
            <v-col cols="12" md="6" class="pt-0">
                <v-card flat class="pt-0">
                    <v-card-title class="mt-0">Telefoonnummer:</v-card-title>
                    <v-card-text>
                         <v-row>
                             <v-col>
                                 <v-row>
                                     <v-col class="py-0">
                                        {{telephoneNumber}}
                                     </v-col>
                                     <v-col cols="12" class="pt-0">
                                          <v-btn x-small @click="showTelephoneNumber = true" color="secondary" class="black--text">
                                            <v-icon x-small>mdi-pencil</v-icon>
                                        </v-btn>
                                     </v-col>
                                 </v-row>
                             </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="showTelephoneNumber" class="mt-2">
            <v-col cols="12" class="py-0">
                <v-card flat class="py-0">
                    <v-card-text class="py-0">
                        <v-row class="py-0">
                            <v-col cols="12" md="6" class="py-0">
                                <v-row class="py-0">
                                    <v-col class="py-0">
                                        <v-text-field 
                                        maxlength="10"  
                                        outlined
                                        placeholder="Telefoonnummer" 
                                        :rules="[rules.minTelephone]" 
                                        dense 
                                        v-model="telephoneNumber">
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AccountService from '../services/AccountService'
import AddressService from '../services/AddressService'
import StudentService from '../services/StudentService'

export default {
    data: () => ({
        name:'',
        surname: '',
        loading: false,
        resultMessage:null,
        showModifyAddresses: false,
        showModifyNameSurname: false,
        residentialAddress: {street:'', houseNumber:'', additionHouseNumber:'', postalCode:'', city:'', country:'Nederland'},
        postalAddress: {street:'', houseNumber:'', additionHouseNumber:'', postalCode:'', city:'', country:'Nederland'},
        showPostAddress: false,
        disableAutoAddressFinder:true,
        telephoneNumber: null,
        showTelephoneNumber: true,
        addressIsValid: true,
        nameIsValid: true,
        surnameIsValid: true,
        timeoutResidential: null,
        timeoutName: null,
        timeoutSurname:null,
        timeoutPostalAddress: null,
        residentialAddressNotFoundErrorMessage: null,
        postalAddressNotFoundErrorMessage: null,
        rules: {
            required: value => !!value || 'Verplicht.',
            postalcode: v => /^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/i.test(v) || 'Niet geldige postcode',
            min: v => v?.length >= 8 || 'Ten minste 8 tekens',
            minTelephone: v => v?.length === 10 || 'Telefoonnummer moet 10 cijfers hebben',
        },
    }),

    async mounted() {
        this.disableAutoAddressFinder = true;
        await this.loadAddressesFromStore();
        this.disableAutoAddressFinder = false;
    },

    computed: {
        formValid() {
            let valid = !this.showPostAddress;

            if(this.showPostAddress){
                valid = this.postalAddress &&
                this.postalAddress.street != '' &&
                this.postalAddress.postalCode != '' && 
                this.postalAddress.city != '' &&
                this.postalAddress.houseNumber != '' &&
                /^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/i.test(this.postalAddress.postalCode);

                if(!valid){
                    return false;
                }
            }
            
            valid = this.residentialAddress &&
                this.residentialAddress.street != '' &&
                this.residentialAddress.postalCode != '' && 
                this.residentialAddress.city != '' &&
                this.residentialAddress.houseNumber != '' &&
                /^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/i.test(this.residentialAddress.postalCode) &&
                validPostAddress;

            if(!valid){
                return false;
            }

            if( this.name != null && this.name != '' &&
                this.surname != null && this.surname != ''){
                return valid;
            }

            return false;
        },

        progress () {
            return Math.min(100, this.value.length * 10)
        },
        color () {
            return ['error', 'warning', 'success'][Math.floor(this.progress / 40)]
        },
    },
    watch:{
        telephoneNumber:function(){
            this.getAccountOfStudentFromRemote().then((account) => {
                this.saveTelephoneNumber(account).then(()=>{
                    this.saveUpdateStudent(account);
                });
            }); 
        }
    },
    methods:{
        ...mapGetters(['getStudent']),
        ...mapGetters(['getAccessToken']),
        ...mapActions(['setStudentsAccount']),
        ...mapActions(['saveFirstName']),
        ...mapActions(['saveLastName']),
        
        async saveTelephoneNumber(account){
            if(this.telephoneNumber && this.telephoneNumber.length === 10 && account.phone != this.telephoneNumber){
                await AccountService.updatePhoneNumber(this.getAccessToken(), this.telephoneNumber);   
                this.showTelephoneNumber = false;      
            }
        },

        async isValidResidentialAddress() {
            this.residentialAddressNotFoundErrorMessage = null;
            
            await this.residentialAddressChanged();

            if( (!this.$refs.residentialAddressPostalCodeRef.valid || 
                !this.$refs.residentialAddressHouseNumberRef.valid) && 
                this.addressIsValid){
                
                this.addressIsValid = false;
                this.$emit("onAddressFailedEvent");
            }

            this.addressIsValid = false;
            this.$emit("onAddressFailedEvent");
            return false;
        },

        async isValidPostalAddress() {
            this.postalAddressNotFoundErrorMessage = null;
            
            await this.postAddressChanged();

            return false;
        },

        saveUpdateStudent(account){
            account.phone = this.telephoneNumber;
            this.setStudentsAccount(account);
        },

        async getAccountOfStudentFromRemote(){
            return await AccountService.getAccountOfStudent(this.getAccessToken());
        },

        async saveAddress(){
            try{
                this.resultMessage = null;
                this.loading = true;
                if(!this.showPostAddress){
                    this.copyResidentialAddressWithPostAddress();
                }

                await AccountService.updateAddress(this.getAccessToken(), this.residentialAddress, this.postalAddress);
                
                let account = await this.getAccountOfStudentFromRemote();
                if(account != null){
                    await this.saveTelephoneNumber(account);

                    if(!this.showPostAddress){
                        account.postalAddress = this.residentialAddress;
                    } else{
                        account.postalAddress = this.postalAddress;
                    }

                    this.saveUpdateStudent(account);
                }

                this.$emit("onAddressSavedEvent");

            } catch(error) {
                if(error.response && error.response.status == 404){
                    // adres niet gevonden
                    this.$emit("onNoAddressFoundEvent");
                } else{
                    this.resultMessage = 'Er is helaas iets mis gegaan';
                }
                this.$emit("onAddressFailedEvent");
            }

            this.loading = false;
        },

        async debouncedResidentialAddressChange() {
            clearTimeout(this.timeoutResidential); // Clear the existing timeout
            this.timeoutResidential = setTimeout(async () => {
                await this.addressResidentialChanged();
                await this.saveAddress();
            }, 1000);
        },

        async addressResidentialChanged(){     
            try{
                if(this.residentialAddress.postalCode &&
                    this.residentialAddress.postalCode.length == 6 &&
                    this.residentialAddress.houseNumber != null && 
                    this.residentialAddress.houseNumber.length > 0 ){
                        var address = await AddressService.findAddress(
                        this.getAccessToken(),
                        this.residentialAddress.postalCode,
                        this.residentialAddress.houseNumber,
                        this.residentialAddress.additionHouseNumber);
                        
                        this.disableAutoAddressFinder = true;
                        if(address != null && (address.street != null || address.street == '')) {
                            this.residentialAddress.street = address.street;
                            this.residentialAddress.city = address.city;
                            this.addressIsValid = true;
                            this.$refs.residentialAddressHouseNumberRef.valid = true;
                        }
                        else{
                            this.residentialAddress.street = null;
                            this.residentialAddress.city = null;
                            this.addressIsValid = false;
                            this.$refs.residentialAddressHouseNumberRef.valid = false;
                            this.residentialAddressNotFoundErrorMessage = 'adres is niet gevonden';
                        }
                        this.disableAutoAddressFinder = false;
                    } else {
                    this.residentialAddress.street = null;
                    this.residentialAddress.city = null;
                    this.addressIsValid = false;
                    this.$refs.residentialAddressHouseNumberRef.valid = false;
                    this.residentialAddressNotFoundErrorMessage = 'adres is niet gevonden';
                }
            } catch(error){
                this.addressIsValid = false;
                this.residentialAddress.street = null;
                this.residentialAddress.city = null;
                this.disableAutoAddressFinder = false;
                this.$refs.residentialAddressHouseNumberRef.valid = false;
                this.residentialAddressNotFoundErrorMessage = 'adres is niet gevonden';
            }  
            
        },

        async residentialAddressChanged(){
            if(!this.disableAutoAddressFinder){
                await this.debouncedResidentialAddressChange();
            }
        },

        async debouncedPostAddressChange() {
            clearTimeout(this.timeoutPostalAddress); // Clear the existing timeout
            this.timeoutPostalAddress = setTimeout(async () => {
                await this.addressPostChanged();
                await this.saveAddress();
            }, 1000);
        },

        async debouncedNameChange() {
            clearTimeout(this.timeoutName);
            this.timeoutName = setTimeout(async () => {
               try { 
                    if(this.name == null || this.name == ''){
                        this.$emit("onNameFailedEvent");
                    } else{
                        await StudentService.updateStudentName(this.getAccessToken(), this.name);
                        this.saveFirstName(this.name);
                        this.nameIsValid = true;
                        this.$refs.nameRef.valid = true;
                        this.$emit("onNameSavedEvent");
                    }
                    
                    if(this.isControlValid()){
                        this.$emit('onControlValidEvent');
                    }
                } catch {
                    this.nameIsValid = false;
                    this.$refs.nameRef.valid = false;
                    this.$emit("onNameFailedEvent");
                }
            }, 1000);
        },

        async studentNameChanged(){
            await this.debouncedNameChange();
            return false;
        },

        async debouncedSurnameChange() {
            clearTimeout(this.timeoutSurname);
            this.timeoutSurname = setTimeout(async () => {
                try{
                    if(this.surname == null || this.surname == ''){
                        this.$emit("onSurnameFailedEvent");
                    } else{
                        await StudentService.updateStudentSurname(this.getAccessToken(), this.surname);
                        this.saveLastName(this.surname);
                        this.surnameIsValid = true;
                        this.$refs.surnameRef.valid = true;
                        this.$emit("onSurnameSavedEvent");
                    }

                    if(this.isControlValid()){
                        this.$emit('onControlValidEvent');
                    }
                } catch {
                    this.surnameIsValid = false;
                    this.$refs.surnameRef.valid = false;
                    this.$emit("onSurnameFailedEvent");
                }
            }, 1000);
        },

        async studentSurnameChanged(){
            await this.debouncedSurnameChange();
            return false;
        },

        async postAddressChanged(){
            if(!this.disableAutoAddressFinder){
                await this.debouncedPostAddressChange();
            }
        },

        async addressPostChanged(){   
            try {
                if(this.postalAddress.postalCode != null &&
                    this.postalAddress.postalCode.length == 6 && 
                    this.postalAddress.houseNumber != null && 
                    this.postalAddress.houseNumber.length > 0 ) {
                        var address = await AddressService.findAddress(
                            this.getAccessToken(),
                            this.postalAddress.postalCode,
                            this.postalAddress.houseNumber,
                            this.postalAddress.additionHouseNumber);

                            this.disableAutoAddressFinder = true;
                            
                        if(address != null 
                            && (address.street != null || address.street == '')) {
                            this.postalAddress.street = address.street;
                            this.postalAddress.city = address.city;
                            this.addressIsValid = true;
                            this.$refs.postalAddressHouseNumberRef.valid = true;
                        }
                        else {
                            this.postalAddress.street = null;
                            this.postalAddress.city = null;
                            this.addressIsValid = false;
                            this.$refs.postalAddressHouseNumberRef.valid = false;
                            this.postalAddressNotFoundErrorMessage = 'adres is niet gevonden';
                        } 
                        this.disableAutoAddressFinder = false;
                    }  else{
                        this.postalAddress.street = null;
                        this.postalAddress.city = null;
                        this.$refs.postalAddressHouseNumberRef.valid = false;
                        this.postalAddressNotFoundErrorMessage = 'adres is niet gevonden';
                    }
                } catch(error) {
                this.addressIsValid = false;
                this.postalAddress.street = null;
                this.postalAddress.city = null;
                this.disableAutoAddressFinder = false;
                this.$refs.postalAddressHouseNumberRef.valid = false;
                this.postalAddressNotFoundErrorMessage = 'adres is niet gevonden';
            }
        },

        async loadAddressesFromStore(){
            let student = this.getStudent();
            this.telephoneNumber = student.phone;
            
            if( student != null) {
                this.name = student.firstName;
                this.surname = student.lastName;

                if(this.name != null && this.name != ''){
                    this.$emit("onNameSavedEvent");
                }

                if(this.surname != null && this.surname != ''){
                    this.$emit("onSurnameSavedEvent");
                }

                if( (this.name == null || this.name == '' || 
                    this.surname == null || this.surname == '')){
                        this.showModifyNameSurname = true;
                } else{
                    this.showModifyNameSurname = false;
                }
                
            } else {
                this.showModifyNameSurname = true;
            }

            this.showTelephoneNumber = this.telephoneNumber === null ? true : false;

            if( student.postalAddress && 
                student.postalAddress.street != null && 
                student.postalAddress.street.length > 0 ){
                this.postalAddress = {
                    street:student.postalAddress.street, 
                    houseNumber:student.postalAddress.houseNumber, 
                    additionHouseNumber:student.postalAddress.additionHouseNumber, 
                    postalCode:student.postalAddress.postalCode, 
                    city:student.postalAddress.city, 
                    country:'Nederland'
                };
            }
            else {
                this.showModifyAddresses = true;
            }

            if( student.residentialAddress && 
                student.residentialAddress.street != null && 
                student.residentialAddress.street.length > 0 ){
                this.residentialAddress = {
                    street:student.residentialAddress.street, 
                    houseNumber:student.residentialAddress.houseNumber, 
                    additionHouseNumber:student.residentialAddress.additionHouseNumber, 
                    postalCode:student.residentialAddress.postalCode, 
                    city:student.residentialAddress.city, 
                    country:'Nederland'
                };

                this.$emit("onAddressSavedEvent");
            }
            else{
                this.showModifyAddresses = true;
            }

            if(this.isControlValid()){
                this.$emit('onControlValidEvent');
            }
        },

        isControlValid(){
            return  (this.residentialAddress?.houseNumber != null &&
                    this.postalAddress?.houseNumber != null && 
                    this.residentialAddress?.postalCode != null && 
                    this.postalAddress?.postalCode != null && 
                    this.name != null &&
                    this.name != '' &&
                    this.surname != null &&
                    this.surname != '');
        },


        residentialSameAsPostalAddress(){
           this.copyResidentialAddressWithPostAddress();
        },

        copyResidentialAddressWithPostAddress(){
            this.postalAddress = {
                street:this.residentialAddress.street, 
                houseNumber:this.residentialAddress?.houseNumber, 
                additionHouseNumber:this.residentialAddress?.additionHouseNumber, 
                postalCode:this.residentialAddress?.postalCode, 
                city:this.residentialAddress?.city, 
                country:'Nederland'
            };
        }
    }
}

</script>