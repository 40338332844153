<template>
    <v-container>
        <v-row>
            <v-col cols="3" class="hidden-sm-and-down pt-0">
                <UserMenuCard></UserMenuCard>
            </v-col>
            <v-col class="pt-0">
                <v-row v-if="!walletValid()"> 
                    <v-col>
                        <v-card-text>
                            We hebben je bankrekening nodig om je cashout verzoek te kunnen verwerken. Ga naar je 
                            <router-link  to="/account"
                                class="btn btn-link">
                                account</router-link>
                            pagina en vul je bankrekeningnummer in.
                        </v-card-text>
                    </v-col>
                </v-row>
                <v-row v-if="walletValid() && Number(getMoney()) == 0.0"> 
                    <v-col>
                        <v-card-text>
                            Je wallet is leeg om een cashout te doen.
                        </v-card-text>
                    </v-col>
                </v-row>
                <v-row v-if="loading"> 
                    <v-col cols="6">
                        <v-card-text v-if="errorMessage">
                            {{errorMessage}}
                        </v-card-text>
                    </v-col>
                    <v-col class="my-8">
                        <v-progress-circular
                        v-if="loading"
                        indeterminate
                        color="primary">
                        </v-progress-circular>
                    </v-col>
                </v-row>
                <v-row class="mt-0 pt-0" v-if="walletValid() && (Number(getMoney()) > 0.0)">  
                    <v-col cols="12" md="12" class="pt-0">
                        <v-card>
                            <v-card-title>Geld opnemen</v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" md="4">
                                        <v-card-text>
                                            <v-text-field
                                            v-model="cashoutAmount" 
                                            label="Opnemen bedrag (€)"
                                            maxlength="7"
                                            dense
                                            outlined>
                                            </v-text-field>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-0" v-if="calculateMoneyLeftInWallet() < 0.0 && cashoutAmount > 0.0">
                                    <v-col>
                                         <v-card-text class="red--text">
                                            Het ingevoerde bedrag overschrijdt het saldo ({{parseFloat(getMoney()).toFixed(2)}}) van je wallet. Verminder het bedrag en probeer het nogmaals. 
                                         </v-card-text>
                                    </v-col>
                                </v-row>
                                <v-row v-if="calculateMoneyLeftInWallet() >= 0.0 && cashoutAmount > 0.0" class="pt-0">
                                    <v-col class="pt-0">
                                        <v-card-text>
                                            <v-row>
                                                <v-col class="py-0">
                                                    Opnemen bedrag
                                                </v-col>
                                                <v-col class="py-0">
                                                    €{{parseFloat(cashoutAmount).toFixed(2)}}
                                                </v-col>
                                            </v-row>

                                            <v-row class="py-0">
                                                <v-col class="py-0">
                                                    Totaal administratiekosten ({{administrationCost}}%)
                                                </v-col>
                                                <v-col class="py-0">
                                                    €{{(Number(parseFloat(cashoutAmount) / 100) * administrationCost).toFixed(2)}}
                                                </v-col>
                                            </v-row>

                                            <v-divider class="my-5"></v-divider>

                                            <v-row>
                                                <v-col>
                                                    Totaal te ontvangen
                                                </v-col>
                                                <v-col>
                                                    €{{parseFloat(Number(cashoutAmount)-(Number(parseFloat(cashoutAmount) / 100) * administrationCost)).toFixed(2)}}
                                                </v-col>
                                            </v-row>

                                            <v-row>
                                                <v-col>
                                                    Dit bedrag wordt binnen {{payoutDays}} overgemaakt op je rekeningnummer.
                                                </v-col>
                                            </v-row>

                                            <v-row class="pt-0">
                                                <v-col class="pt-0">
                                                    Er blijft nog €{{calculateMoneyLeftInWallet()}} over in je Boekshare wallet.
                                                </v-col>
                                            </v-row>

                                            <v-row>
                                                <v-col>
                                                    <v-btn 
                                                        color="primary" 
                                                        class="mr-4"
                                                        @click="requestCashout()"
                                                        :disabled="!formValid || loading">
                                                        UITBETALEN
                                                    </v-btn>
                                                    <v-btn to="/MyCashouts">ANNULEREN</v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UserMenuCard from '../components/UserMenuCard.vue'
import CashoutService from '../services/CashoutsService'

  export default {
    props: { },
    data () {
        return {
            loading:false,
            errorMessage: null,
            administrationCost: 10.0,
            payoutDays: '3 dagen',
            cashoutAmount: null,
        }
    },

    components:{
        UserMenuCard
    },

    watch: {
        cashoutAmount: function(val){
            let trimmedVal = val.replace(',', '.');
            if(Number.isNaN(trimmedVal)){
                this.cashoutAmount = null;
            }

            if(Number(this.getMoney() - (Number(trimmedVal))) >= 0.0) {
                this.cashoutAmount = Number(trimmedVal);
            }
        }
    },

    computed: {
        formValid() {            
            return this.calculateMoneyLeftInWallet() >= 0.0;
        },
    },

    methods: {
        ...mapGetters(['getMoney']),
        ...mapGetters(['getAccessToken']),
        ...mapGetters(['getStudent']),
        ...mapActions(['saveMoneyOfStudent']),

        walletValid() {            
            let student = this.getStudent();
            student.activeSubscription?.limitations?.forEach(element => {
                if(element.name === 'AdministrationCosts'){
                    if(Number(element.value) === -1){
                        this.administrationCost = 0.0
                    } else{
                        this.administrationCost = Number(element.value);
                    }
                } else if(element.name === 'PayOuts'){
                    if(Number(element.value) === -1){
                        this.payoutDays = '24 uur';
                    } else{
                        if(element.limitation === 'Days'){
                            this.payoutDays = element.value + ' dagen';
                        } else if(element.limitation === 'Hours'){
                            this.payoutDays = element.value + ' uur';
                        }
                    }
                } 
            });

            return (student.wallet && student.wallet.iban);
        },

        calculateMoneyLeftInWallet(){
            return parseFloat(this.getMoney() - parseFloat(Number(this.cashoutAmount))).toFixed(2); 
        },

        async requestCashout(){
            this.loading = true;
            try{
                this.errorMessage = null;
                let requestCashout = await CashoutService.requestCashout(this.getAccessToken(), this.cashoutAmount);
                this.saveMoneyOfStudent(this.calculateMoneyLeftInWallet());
                this.$router.push({name:'MyCashouts'});

            } catch{
                this.errorMessage = 'Er is een fout opgetreden.';
            }

            this.loading = false;
        }
     }
  }
</script>