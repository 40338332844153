// historyPlugin.js
export default {
    install(Vue) {
      Vue.prototype.$navigationHistory = {
        historyLength: 0
      };
  
      Vue.mixin({
        beforeCreate() {
          if (this.$router) {
            this.$router.beforeEach((to, from, next) => {
              if (from.name) {
                this.$navigationHistory.historyLength++;
              }
              next();
            });
          }
        }
      });
    }
  };
  