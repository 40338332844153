<template>
   <v-form>
        <v-container>
            <v-row>
                <v-col cols="3" class="hidden-md-and-down">
                    <UserMenuCard></UserMenuCard>   
                </v-col>
                <v-col>
                    <v-row>
                        <UpdateShipmentsComponent></UpdateShipmentsComponent>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
   </v-form>
</template>

<script>
import UserMenuCard from '../../../components/UserMenuCard.vue';
import UpdateShipmentsComponent from '../../../components/admin/UpdateShipmentsComponent.vue';

export default {

    data () {
      return {        
      }
    },

    components:{
        UserMenuCard,
        UpdateShipmentsComponent
    },
}
</script>