import axios from "../plugins/axios";

const baseUrl = `${process.env.VUE_APP_BOOKSHARE_URL}/invoices/`;

export default {
    async getInvoice(token, checkoutId) {
        let result;

        const config = {
        headers: { Authorization: `Bearer ${token}` }
        };

        let data = {
            checkoutId:checkoutId
        }

        await axios.post(`${baseUrl}get-invoice`, data, config)
            .then(response =>
                result = response.data);

        return result;
    },
}