<template>
<v-form>
    <v-row ref="form">
        <v-col>
            <v-card class="pa-4">
                <v-row>
                    <v-col cols="12" md="12">
                        <v-text-field v-model="advertisement.title" ref="title" :rules="[rules.required]" label="Titel"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                            <v-text-field v-model="advertisement.subtitle" ref="subtitle" :rules="[rules.required]" label="Subtitle"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-text-field v-model="advertisement.redirectUrl" ref="redirectUrl" :rules="[rules.required]" label="Redirect URL"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-text-field v-model="advertisement.customerName" ref="customerName" :rules="[rules.required]"  label="Naam van de klant"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-checkbox v-model="advertisement.active" label="Is actief"> </v-checkbox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-img
                        contain 
                        :src="imagePreview"
                        max-width="450"/>
                    </v-col>
                    <v-col>
                        <image-compressor
                        :hidden=true 
                        class="compressor" 
                        :done="getFiles" 
                        :scale="scale" 
                        :quality="quality" 
                        ref="compressor">
                        </image-compressor>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-btn outlined color="primary" class="ml-3" v-if="!imagePreview" type="button" @click="uploadImage">Upload foto</v-btn>
                    </v-col>
                </v-row>
                    <v-row>
                    <v-col cols="12" md="6">
                        <v-menu
                            v-model="menuAdvertisementStartDate"
                            :close-on-content-click="true"
                            transition="scale-transition"
                            offset-y
                            min-width="auto">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="advertisement.startDate"
                                    label="Startdatum"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    :rules="[rules.required]" 
                                    outlined
                                    ref="startDate"
                                    v-on="on"
                                    dense
                                ></v-text-field>
                            </template>
                            <v-date-picker 
                                v-model="advertisement.startDate" 
                                no-title 
                                scrollable
                                dense
                                @input="menuAdvertisementStartDate = false;">
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col>
                        <v-menu
                            v-model="menuAdvertisementEndDate"
                            :close-on-content-click="true"
                            transition="scale-transition"
                            offset-y
                            min-width="auto">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="advertisement.endDate"
                                    label="Einddatum"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    ref="endDate"
                                    outlined
                                    :rules="[rules.required]" 
                                    v-on="on"
                                    dense
                                ></v-text-field>
                            </template>
                            <v-date-picker 
                                v-model="advertisement.endDate" 
                                no-title 
                                scrollable
                                dense
                                @input="menuAdvertisementEndDate = false">
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
                <v-row class="mt-0">
                    <v-col class="pt-0">
                        <v-btn :disabled="!formValid" color="primary" @click="add()">AANMAKEN</v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
</v-form>
</template>


<script>
import { mapGetters } from 'vuex';
import AdvertisementService from '../../services/AdvertisementService';
import imageCompressor from 'vue-image-compressor';

export default {
    data () {
      return {
        loading: false,
        pageSize: 5,
        pageNumber: 1,
        totalPages:0,
        dateFrom: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        dateTo: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menuDateFrom:false,
        menuDateTo:false,
        tab: null,
        advertisement: {},
        menuAdvertisementStartDate: false,
        menuAdvertisementEndDate: false,
        imagePreview: null,
        scale: Number(90),
        quality: Number(10),
        rules: {
            required: value => !!value || 'Verplicht.',
        }
      }
    },
    computed: {
        formValid() {
            return (this.advertisement.title != null &&
                this.advertisement.customerName != null &&
                this.advertisement.subtitle != null &&
                this.advertisement.redirectUrl != null &&
                this.advertisement.startDate != null &&
                this.advertisement.endDate != null);
        },
    },
    methods:{
        ...mapGetters(['getAccessToken']),
        async add(){
           try{
                this.loading = true;
                let data = await AdvertisementService.addAdvertisement(this.getAccessToken(), this.advertisement);
                
                this.$refs.title.reset();
                this.$refs.customerName.reset();
                this.$refs.subtitle.reset();
                this.$refs.redirectUrl.reset();
                this.$refs.startDate.reset();
                this.$refs.endDate.reset();

           }catch{}
           this.loading = false;
        },

        uploadImage () {
            this.imagePreview = null;
            let compressor = this.$refs.compressor.$el;
            compressor.click();
        },

        getFiles(obj){

            this.original = obj.original;
            this.compressed = obj.compressed;

            if(!this.imagePreview){
                if(this.original.file.size < 500000){
                    
                    this.imagePreview = URL.createObjectURL(obj.original.file);
                    this.advertisement.image = obj.original.base64;
                    this.scale = 90;
                }
                else{
                    if(this.compressed.file.size < 100){
                        if(this.scale <= 90){
                            this.scale = this.scale + 5;
                        }

                        if(this.scale <= 0 || this.scale >= 95){
                            return;
                        }
                    }
                    else{
                        this.imagePreview = obj.compressed.blob;
                        this.advertisement.image = obj.compressed.base64;
                        this.scale = 90;
                    }
                }
            }
        },
    },
    components:{
        imageCompressor
    },
}
</script>