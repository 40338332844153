<template>
    <v-container>
        <v-row>
            <v-col>
                <v-row>
                    <v-dialog
                        transition="dialog-top-transition"
                        max-width="600"
                        v-model="dialog"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                color="primary"
                                outlined>
                                Opzeggen account
                            </v-btn>
                        </template>
                        <template>
                            <v-card width="600">
                                <v-card-title primary-title class="justify-center title">
                                    <h2>Beëindigen account</h2>
                                </v-card-title>
                                <v-divider></v-divider>
                                <v-card-text class="mt-8">
                                    <div class="red--text" v-if="getMoney() > 0">Je hebt nog geld in je wallet. Zorg ervoor dat je wallet leeg is voordat je je account verwijderd.</div>
                                    Voer je wachtwoord in om je account te verwijderen. Na het verwijderen van je account is het niet meer mogelijk om deze te herstellen.
                                </v-card-text>
                                <v-card-text>
                                    <v-form>
                                        <v-row>
                                            <v-col cols="12" md="8">
                                                <v-text-field v-model="password"
                                                        prepend-inner-icon="mdi-lock-outline" 
                                                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                                        :rules="[rules.required, rules.min]"
                                                        :type="showPassword ? 'text' : 'password'"
                                                        label="Wachtwoord"
                                                        hint="Ten minste 8 tekens"
                                                        dense
                                                        outlined
                                                        @click:append="showPassword = !showPassword">
                                                    </v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-btn 
                                                :loading="loading" 
                                                color="primary" 
                                                :disabled="!formValid || loading"
                                                @click="closeAccount()">ACCOUNT OPZEGGEN</v-btn>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="3" md="4"></v-col>
                                            <v-col>
                                                <h4 class="red--text ml-3" v-if="errorMessage">{{errorMessage}}</h4>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card-text>
                            </v-card>
                        </template>
                    </v-dialog>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import AccountService from '../services/AccountService'

export default {
    data: () => ({
      dialog: false,
      password: '',
      showPassword: false,
      loading:false,
      errorMessage: '',
      rules: {
        required: value => !!value || 'Required.',
        min: v => v?.length >= 8 || 'Ten minste 8 tekens',
      },
    }),
    methods:{
        ...mapGetters(['getAccessToken']),
        ...mapGetters(['getAuthentication']),
        ...mapGetters(['getMoney']),
        ...mapActions(['logout']),
        async closeAccount(){
            this.loading = true;
            try{
                let result = await AccountService.close(this.getAccessToken(), this.getAuthentication().authenticatedUser.userName, this.password);
                this.dialog = false;
                this.logout();
                this.$router.push({name: 'Home'});
                this.$router.go();
            }
            catch{
                this.errorMessage = 'Je account kon helaas niet verwijderd worden.';
            }

            this.loading = false;
        }
    },
    computed: {
      formValid() {
          return this.password && 
              this.password.length >= 8;
      }
    },
}

</script>