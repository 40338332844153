<template>
    <v-form class="mb-4">
        <v-row align="center" justify="center">
            <v-col cols="11" md="4">
                <v-card v-if="isResultAvailable" class="pb-4" align="center" justify="center">
                    <v-row justify="center" align="center">
                        <v-card-title>Je gebruikersnaam opvragen</v-card-title>
                        <v-card-text class="ma-2">{{resultText}}</v-card-text>
                        <v-card-text>Terug naar <router-link class="mx-1" to="/">Home</router-link></v-card-text>
                     </v-row>
                </v-card>
                <v-card v-if="!isResultAvailable" class="pb-4" align="center" justify="center">
                    <v-row justify="center" align="center">
                        <v-card-title>Je gebruikersnaam opvragen</v-card-title>
                    </v-row>
                    <v-row justify="center" align="center">
                        <v-col cols="10" md="10">
                            <v-text-field 
                            v-model="email"
                            :rules="[rules.required, rules.email]"
                            name="E-mail"
                            label="E-mail"
                            hint="Wat is je e-mail?"
                            outlined>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row justify="center" align="center" class="mt-n6">
                        <v-col cols="10" md="10">
                            <v-btn
                                    block
                                    color="primary"
                                    :loading="loading"
                                    :disabled="!formValid || loading"
                                    @click="forgotUsername()">
                                    OPVRAGEN
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-form>
</template>
<script>

import AccountService from "../services/AccountService"

export default {
    data () {
      return {
        rules: {
          required: value => !!value || 'Required.',
          email: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
        },
        email: null,
        resultAvailable: false,
        loading: false,
        resultText: null,
      }
    },
    computed:{
        formValid() {
            return (this.email && this.email.length > 3);
        },
        isResultAvailable(){
            return this.resultAvailable;
        }
    },
    methods:{
        async forgotUsername() {
            try {
                this.loading = true;
                this.resultText = null;
                await AccountService.forgotUsername(this.email);
                this.resultAvailable = true;
                this.resultText = "We hebben je een email gestuurd met je gebruikersnaam. " + 
                                "Gebruik je gebruikersnaam en je wachtwoord om succesvol in te loggen.";
            }
            catch{
                this.resultText = 'Er is een fout opgetreden.';
            }

            this.loading = false;
        }
    }
}
</script>
