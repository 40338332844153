<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" md="6" v-if="!error && transaction != null && transaction.review == null">
                <GiveReviewComponent :disableClosingDialog="true"></GiveReviewComponent>
            </v-col>
            <v-col cols="12" md="6" v-if="error">
                <label class="red--text">Er is helaas iets mis gegaan.</label>
            </v-col>
            <v-col cols="12" md="6" v-if="transaction != null && transaction.review != null && !error">
                <label>Je hebt al een review gegeven.</label> <router-link class="mx-1" to="/">Home</router-link>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import GiveReviewComponent from '../components/GiveReviewComponent.vue'
import TransactionService from '../services/TransactionService'
import { EventBus } from '../services/EventBus'

export default {
    data() {
        return {
            transaction: null,
            error:false,
        }
    },

    async created(){
        await this.getTransaction();
        EventBus.$emit('loadRatings', this.transaction.id);
        EventBus.$emit('loadTransactionById', this.transaction.id);
    },

    methods: {
        ...mapGetters(['getAccessToken']),
        async getTransaction(){
            try{
                this.error = false;
                var result = await TransactionService.getTransactionById(this.getAccessToken(), this.$route.query.transaction);
                this.transaction = result.transaction;
            } catch {
                this.error = true;
            }
        }
    },

    components:{
        GiveReviewComponent
    }
}
</script>