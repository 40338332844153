import axios from "../plugins/axios";

const baseUrl = `${process.env.VUE_APP_BOOKSHARE_URL}/cashouts/`;

export default {
    async getMyCashouts(token, pageNumber, pageSize) {
        let result;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let data = {pageNumber:pageNumber, pageSize:pageSize }
        await axios.post(`${baseUrl}my-cashouts`, data, config)
                .then(response =>
                    result = response.data);

        return result;
    },
    async requestCashout(token, cashoutAmount) {
        let result;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let data = {cashoutAmount:cashoutAmount}
        await axios.post(`${baseUrl}request-cashout`, data, config)
                .then(response =>
                    result = response.data);

        return result;
    },
    async getCashouts(token, pageNumber, pageSize, statusFilter) {
        let result;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let data = {pageNumber:pageNumber, pageSize:pageSize, status:statusFilter}
        await axios.post(`${baseUrl}admin/cashouts`, data, config)
                .then(response =>
                    result = response.data);

        return result;
    },
    async changeCashoutStatus(token, cashoutId, status) {
        let result;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let data = {cashoutId:cashoutId, status:status}
        await axios.post(`${baseUrl}admin/change-cashout-status`, data, config)
                .then(response =>
                    result = response.data);

        return result;
    },
}
