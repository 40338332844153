<template>
    <v-container class="px-0">
        <v-card class="px-0" v-if="!subscriptions || subscriptions.length === 0 || !getStudent().hasValidSubscription" outlined>
            <v-col>
                <v-row>
                    <v-card-text>
                        Onbeperkt berichten versturen of geen administratiekosten betalen? Kijk dan naar de premium lidmaatschappen.
                        <router-link to="/subscriptions">
                            <v-btn color="primary" class="my-3">
                                BEKIJK LIDMAATSCHAP
                            </v-btn>                    
                        </router-link>
                    </v-card-text>
                </v-row>
            </v-col>
        </v-card>
        <div v-for="(subscription, index) in subscriptions" :key="index" >
            <v-card class="px-0" v-if="subscription.payment && subscription.payment.status === 'finished'" outlined>
                <v-col>
                    <v-row>
                        <v-col cols="5">
                            Type:
                        </v-col>
                        <v-col>
                            {{getTypeOfSubscription(subscription.type)}}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="5">
                            Datum start:
                        </v-col>
                        <v-col>
                            {{getFriendlyDate(subscription.createdAt)}}
                            {{getFriendlyTime(subscription.createdAt)}}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="5">
                            Datum eind:
                        </v-col>
                        <v-col>
                            {{getFriendlyDate(subscription.endDate)}}
                            {{getFriendlyTime(subscription.endDate)}}
                        </v-col>
                    </v-row>
                    <v-row v-for="(limitation,index) in subscription.limitations" :key="index">
                        <v-col cols="5">
                            {{translateLimitation(limitation)}}
                        </v-col>
                        <v-col>
                            {{translateLimitationName(limitation)}}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="5">
                        </v-col>
                        <v-col>
                        <v-btn :disabled="true" color="primary" small>
                            VERLENGEN
                        </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-card>
        </div>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    data () {
      return {
          subscriptions:[],
      }
    },
    created(){
        this.loadSubscriptions();
    },
    methods:{
        ...mapGetters(['getStudent']),

        translateLimitation(limitation){
            if(limitation.name === 'MaxMessages'){
                return "Maximaal aantal berichten ";
            } else if(limitation.name === 'AdministrationCosts'){
                return "Administratiekosten";
            } else if(limitation.name === 'PayOuts'){
                return "Uitbetaald in dagen";
            }
        },
        translateLimitationName(limitation){
            if(limitation.name === 'MaxMessages' && limitation.value === '-1'){
                return "Onbeperkt";
            } else if(limitation.name === 'AdministrationCosts' && limitation.value === '-1'){
                 return "Geen";
            } else if(limitation.name === 'PayOuts' && limitation.value === '-1'){
                 return "Binnen 24 uur";
            }

            return limitation.value;
        },
        loadSubscriptions(){
            this.subscriptions = this.getSubscriptions();
        },
        getSubscriptions(){
            let student = this.getStudent();
            if(student){
                return student.subscriptions;
            }
            return null;
        },
        getFriendlyDate(val){
            var date = new Date(val);
            return date.getDate()  + "-" + (date.getMonth()+1) + "-" + date.getFullYear();
        },
        getFriendlyTime(val){
            var date = new Date(val);
            return date.getHours() + ":" + String(date.getMinutes()).padStart(2, "0");
        },
        getTypeOfSubscription(type){
            if(type == 1) return "Proef";
            else if(type == 2) return "Studietijd";
            else if(type == 3) return "Twee jaar";
            else if(type == 4) return "Jaar";
            else if(type == 5) return "6 maanden";
            else if(type == 5) return "3 maanden";
        }
    }
}
</script>