<template>
   <v-form>
        <v-container>
            <v-row>
                <v-col class="mt-3" cols="10">
                    <v-row>
                        <v-col>
                        <v-card flat width="100%" class="ma-4">
                            <v-card-text>
                                <v-row> Number of registration: {{studentStatistics.numberOfRegistration}}</v-row>
                                <v-row> Number of subscriptions: {{studentStatistics.numberOfSubscriptions}}</v-row>
                                <v-row> Number of valid subscriptions: {{studentStatistics.numberOfValidSubscriptions}}</v-row>
                                <v-row> Number of expired subscriptions: {{studentStatistics.numberOfExpiredSubscriptions}}</v-row>
                                <v-row> Total money in wallets: €{{studentStatistics.moneyInWallets}}</v-row>
                            </v-card-text>
                        </v-card>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row class="mt-2">
                        <v-col>
                            <v-card flat width="100%" class="ml-4" v-for="(subscription, index) in studentStatistics.subscriptions" :key="index">
                                <v-card-text>
                                    <v-row>Type of subscription: {{translateTypeOfSubscription(subscription.typeOfSubscription)}}</v-row>
                                    <v-row>Number of expired subscriptions: {{subscription.numberOfExpiredSubscriptions}}</v-row>
                                    <v-row>Number of valid subscriptions: {{subscription.numberOfValidSubscriptions}}</v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="5">
                            <v-card flat width="100%" class="ml-4" v-for="(validSubscription, index) in studentStatistics.validSubscriptions" :key="index">
                                <v-card-text>
                                    <v-row>Months before expire: {{validSubscription.monthsBeforeExpire}}
                                        <div v-if="validSubscription.monthsBeforeExpire===1" class="ml-1">maand</div>
                                        <div v-if="validSubscription.monthsBeforeExpire > 1" class="ml-1">maanden</div>
                                    </v-row>
                                    <v-row>Total before expire: {{validSubscription.total}}</v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row justify="center" align="center" v-if="errorMessage || loading"> 
                <v-col cols="6" v-if="errorMessage">
                    <v-card-text>
                    {{errorMessage}}
                    </v-card-text>
                </v-col>
                <v-col class="my-8" cols="6">
                    <v-progress-circular
                    v-if="loading"
                    indeterminate
                    color="primary">
                    </v-progress-circular>
                </v-col>
            </v-row>
        </v-container>
   </v-form>
</template>

<script>
import StudentService from '../../services/StudentService';
import { mapGetters } from 'vuex';

export default {
    data () {
      return {
        loading: false,
        errorMessage: null,
        studentStatistics: {}
      }
    },
    async created(){
        await this.load();
    },
    methods:{
        ...mapGetters(['getAccessToken']),
        translateTypeOfSubscription(type) {
            if(type === 2){
                return 'studententijd';
            }
            else if(type === 3){
                return 'twee jaar';
            }
            else if(type === 4){
                return 'jaar';
            }
            else if(type === 5){
                return 'semester';
            }
            else if(type === 6){
                return 'drie maanden';
            }
        },
        async load(){
            try{
                this.errorMessage = null;
                this.loading = true;
                let data = await StudentService.getStudentStatistics(this.getAccessToken());
                this.studentStatistics = data.studentStatistics;
                this.loading = false;
            }catch{
                this.loading = false;
                this.errorMessage = 'Er is helaas een fout opgetreden.'
            }
        },
    }
}
</script>