<template>
   <v-container>
        <v-row v-if="shipment == null">
            <v-col cols="12" md="6">
                <v-card-title>
                    <v-text-field v-model="shipmentId" placeholder="Zoeken op id"></v-text-field>
                </v-card-title>
            </v-col>
            <v-col class="mt-md-2">
                <v-card-title>
                    <v-btn color="primary" @click="getShipmentById()">LADEN</v-btn>
                </v-card-title>
            </v-col>
        </v-row>
        <v-row v-if="shipment != null">
            <v-col>
                <v-card flat>
                    <v-card-title>
                        Shipment Id: {{shipment.id}}
                    </v-card-title>
                    <v-card-title>
                        {{shipment.barcode}}
                    </v-card-title>
                    <v-card-subtitle>
                        {{getFriendlyDate(shipment.inserted)}} {{getFriendlyTime(shipment.inserted)}}
                    </v-card-subtitle>
                    <v-card-subtitle v-if="!shipment.status || shipment.status.length == 0">
                        Geen status bekend
                    </v-card-subtitle>
                    <v-card-subtitle>
                        <v-row v-for="(status, indexStatus) in shipment.status" :key="indexStatus">
                            <v-col class="my-0 py-0">
                                {{status.phaseDescription}} - {{status.statusDescription}} - {{getFriendlyDate(status.timeStamp)}} {{getFriendlyTime(status.timeStamp)}}
                            </v-col>
                        </v-row>
                    </v-card-subtitle>
                    <v-card-text class="mt-2" v-for="(shipmentLabel, shipmentLabelIndex) in shipment.labels" :key="shipmentLabelIndex">
                            <a :href="'data:application/pdf;base64,'+shipmentLabel.content" download="verzendbrief.pdf">
                            download verzend label
                            </a>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="shipment != null">
            <v-col cols="12" md="6" class="ml-4 pr-11">
               <v-menu
                    v-model="menuChangeDate"
                    :close-on-content-click="true"
                    transition="scale-transition"
                    offset-y
                    min-width="auto">
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="selectedDate"
                            label="Veranderen naar datum:"
                            prepend-icon="mdi-calendar"
                            readonly
                            outlined
                            v-on="on"
                            dense
                        ></v-text-field>
                    </template>
                    <v-date-picker 
                        v-model="selectedDate" 
                        no-title 
                        scrollable
                        dense
                        @input="menuChangeDate = false;">
                    </v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" class="mt-n4">
                 <v-card-text>
                     <v-btn @click="changeShipmentTime()" outlined class="secondary mr-4" :loading="loading">Toevoegen</v-btn>
                      <v-btn @click="shipment = null;" outlined :loading="loading">Opnieuw</v-btn>
                 </v-card-text>
            </v-col>
        </v-row>
        <v-row v-if="error">
            <v-col cols="12" class="pl-0">
                 <v-card flat>
                    <v-card-text>
                        <div class="red--text">Er is iets mis gegaan.</div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
   </v-container>
</template>

<script>
import ShipmentService from '../../services/ShipmentService';
import { mapGetters } from 'vuex';

export default {
    data () {
      return {
            menuChangeDate:false,
            selectedDate:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            loading: false,
            shipment:null,
            shipmentId: '',
            selectedStatus: {},
            selectedPhase: null,
            error:false,
            statusCodesBasedOnPhaseCode:[],
            phaseCodes:[
                {id:1, value:"1, Collectie", text:"Collectie"},
                {id:2, value:"2, Sortering", text:"Sortering"},
                {id:3, value:"3, Distributie", text:"Distributie"},
                {id:4, value:"4, Afgeleverd", text:"Afgeleverd"},
                {id:5, value:"99, Niet van toepassing", text:"Niet van toepassing"},
            ],
            statusCodes:[
                {id:1, value:"1, Zending voorgemeld", text:"Zending voorgemeld"},
                {id:2, value:"2, Zending in ontvangst genomen", text:"Zending in ontvangst genomen"},
                {id:3, value:"3, Zending afgehaald", text:"Zending afgehaald"},
                {id:4, value:"4, Zending niet afgehaald", text:"Zending niet afgehaald"},
                {id:13, value:"13, Voorgemeld: nog niet aangenomen", text:"Voorgemeld: nog niet aangenomen"},
                {id:14, value:"14, Voorgemeld: definitief niet aangenomen", text:"Voorgemeld: definitief niet aangenomen"},
                {id:15, value:"15, Manco collectie", text:"Manco collectie"},
                {id:18, value:"18, Definitief manco", text:"Definitief manco"},
                {id:19, value:"19, Zending afgekeurd", text:"Zending afgekeurd"},
                {id:5, value:"5, Zending gesorteerd", text:"Zending gesorteerd"},
                {id:6, value:"6, Zending niet gesorteerd", text:"Zending niet gesorteerd"},
                {id:16, value:"16, Manco sortering", text:"Manco sortering"},
                {id:21, value:"21, Zending in voorraad", text:"Zending in voorraad"},
                {id:7, value:"7, Zending in distributieproces", text:"Zending in distributieproces"},
                {id:8, value:"8, Zending niet afgeleverd", text:"Zending niet afgeleverd"},
                {id:9, value:"9, Zending bij douane", text:"Zending bij douane"},
                {id:17, value:"17, Manco distributie", text:"Manco distributie"},
                {id:20, value:"20, Zending in inklaringsproces", text:"Zending in inklaringsproces"},
                {id:11, value:"11, Zending afgeleverd", text:"Zending afgeleverd"},
                {id:12, value:"12, Zending beschikbaar op afhaallocatie", text:"Zending beschikbaar op afhaallocatie"},
                {id:22, value:"22, Zending afgehaald van Postkantoor", text:"Zending afgehaald van Postkantoor"},
                {id:23, value:"20, Afhaalopdracht gecollecteerd", text:"Afhaalopdracht gecollecteerd"},
                {id:99, value:"20, Niet van toepassing", text:"Niet van toepassing"},
            ],
        }
    },
    methods:{
        ...mapGetters(['getAccessToken']),
        getFriendlyDate(val){
            var date = new Date(val);
            return date.getDate()  + "-" + (date.getMonth()+1) + "-" + date.getFullYear();
        },
        getFriendlyTime(val){
            var date = new Date(val);
            return date.getHours() + ":" + String(date.getMinutes()).padStart(2, "0");
        },
        async getShipmentById() {
            this.error = false;
            this.loading = true;
            try{
                let data = await ShipmentService.getShipmentById(this.getAccessToken(), this.shipmentId);
                this.shipment = data.shipment;
            } catch{
                this.error = true;
                this.shipment = null;
            }
            this.loading = false;
        },
        
        async changeShipmentTime() {
            this.error = false;
            this.loading = true;
            try{
                let data = await ShipmentService.changeShipmentTime(this.getAccessToken(), 
                                                                      this.shipmentId, 
                                                                      this.selectedDate);
                this.shipment = data.shipment;
            } catch{
                this.shipment = null;
                this.error = true;
            }
            this.loading = false;
        },
    },
}
</script>