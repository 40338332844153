<template>
    <v-container>
        <v-row>
            <v-col class="pt-0" v-if="!showModifyStudy && selectedUniversity != null">
                <v-card flat width="350">
                    <v-card-subtitle>Studiegegevens:</v-card-subtitle>
                    <v-card-text>
                        <v-row class="py-0">
                            <v-col cols="12">
                                {{selectedUniversity.name}}
                            </v-col>
                        </v-row>
                        <v-row class="my-0 py-0">
                            <v-col cols="12" class="py-0">
                                {{selectedStudy.name}}
                            </v-col>
                            <v-col class="pt-0" cols="12">
                                <v-btn x-small @click="showModifyStudy = true; save = false;" color="secondary" class="black--text">
                                    <v-icon x-small>mdi-pencil</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="mt-md-4">
               <v-card flat v-if="!selectedUniversity || !selectedStudy || showModifyStudy || !save" class="py-0">
                    <v-card-text class="py-0">
                        <v-row class="py-0">
                            <v-col cols="12" class="py-0">
                                <v-autocomplete
                                    v-model="selectedUniversity"
                                    dense
                                    :items="universities"
                                    :item-text="'name'"
                                    :loading="loadingUniversities"
                                    label="Onderwijsinstituut"
                                    return-object
                                    outlined
                                    class="py-0"
                                    :rules="[rules.required]">
                                </v-autocomplete> 
                            </v-col>
                            <v-col cols="12" class="py-0">
                               <v-autocomplete
                                    v-model="selectedStudy"
                                    :items="studies"
                                    :item-text="'name'"
                                    :loading="loadingStudies"
                                    label="Studie"
                                    dense
                                    class="py-0"
                                    return-object
                                    outlined 
                                    :rules="[rules.required]">
                                </v-autocomplete>  
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import UniversityService from "../services/UniversityService"
import CourseService from "../services/CourseService"
import AccountService from '../services/AccountService'

export default {
    data: () => ({
        loading: false,
        resultMessage:null,
        showModifyStudy: false,
        universities: [],
        studies: [],
        selectedUniversity:null,
        selectedStudy:null,
        loadingStudies:false,
        loadingUniversities: false,
        loadingStudy: false,
        save: false,
        rules: {
            required: value => !!value || 'Verplicht.',
        },
    }),

    computed: {
      formValid() {
          return this.name != null &&
            this.name != '' &&
            this.surname != null && 
            this.surname != '' && 
            this.selectedUniversity != null &&
            this.selectedUniversity.name != '' &&
            this.selectedStudy != null &&
            this.selectedStudy.name != '';
      }
    },

    watch:{
        selectedUniversity:function(){
            if(this.formValid){
                this.saveStudy();
            }
        },

        selectedStudy:function(){
            if(this.formValid){
                this.saveStudy();
            }
        }

    },

    async created(){
        await this.loadUniversities();
        await this.loadCourses();
        await this.setCurrentStudyAndUniversity();
    },

    methods:{
        ...mapGetters(['getStudent']),
        ...mapGetters(['getAccessToken']),
        ...mapActions(['setStudentsAccount']),

        async loadUniversities() {
            this.loadingUniversities = true;
            try{
                this.universities = await UniversityService.getUniversities();
            } catch{
                
            }
            this.loadingUniversities = false;
        },
        
        async setCurrentStudyAndUniversity(){
            this.save = true;
            let student = this.getStudent();
            if(student.university != null && student.university.name != ''){
                this.selectedUniversity = student.university;
            }
            else{
                this.showModifyStudy = true;
            }
            
            if(student.study != null && student.study.name != ''){
                this.selectedStudy = student.study;
            }
            else{
                this.showModifyStudy = true;
            }
        },
        
        async loadCourses() {
            this.loadingStudies = true;
            try{
                this.studies = await CourseService.getCourses();
            } catch{
                
            }
            this.loadingStudies = false;
        },

        async saveStudy(){
            this.loadingStudy = true;
            try{
                await AccountService.updateStudy(this.getAccessToken(), this.selectedStudy?.id, this.selectedUniversity?.id);
                let account = await AccountService.getAccountOfStudent(this.getAccessToken());
                if(account){
                    let student = account;
                    this.setStudentsAccount(student);
                }

                this.save = true;
                this.$emit("onSaveStudyClickedEvent");
            } catch (ex){ }

            this.showModifyStudy = false;
            this.loadingStudy = false;
        }
    }
}

</script>