<template>
  <v-app style="background-color:#F5F8FD" app class="bookshare">
    <Nav v-if="!maintenanceMode"></Nav>
    <!-- <NavMobile class="hidden-md-and-up"></NavMobile> -->
    <v-main class="pt-2 pt-md-0">
        <router-view v-if="!maintenanceMode"></router-view>
        <MaintenanceMode v-if="maintenanceMode"></MaintenanceMode>
    </v-main>
    <BookShareDetailsFooter></BookShareDetailsFooter>
    <Footer></Footer>
  </v-app>
</template>

<script>
import Nav from "./components/Nav.vue";
// import NavMobile from "./components/NavMobile.vue";
import Footer from "./components/Footer.vue";
import MaintenanceMode from "./views/MaintenanceMode.vue";
import BookShareDetailsFooter from "./components/BookShareDetailsFooter.vue";
import {ApplicationInsights} from '@microsoft/applicationinsights-web'

const appInsights = new ApplicationInsights({ config: {
  connectionString: 'InstrumentationKey=923fbd14-6d0e-4b19-a7db-f536efe91fdf;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/'
} });

appInsights.loadAppInsights();
appInsights.trackPageView();

export default {
  name: "App",
  components: {
    Nav,
    BookShareDetailsFooter,
    Footer,
    MaintenanceMode,
    // NavMobile
  },
  data: () => ({
    maintenanceMode: false
  }),
};
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swa');
  @import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
  $font-family: 'IBM Plex Sans', sans-serif;
  .bookshare {
    .headline,
    [class*='display-'],
    [class*='text-'] {
      color: #40414E;
      font-family: $font-family, sans-serif !important;
    }
    [class*='title-bookshare'] {
      color: #40414E;
      font-family: 'Varela Round', sans-serif !important;
    }
     [class*='v-btn'] {
       font-family: $font-family, sans-serif !important;
    }
    font-family: $font-family, sans-serif !important;
  }

html, body {
    max-width: 100%;
    overflow-x: hidden;
}

</style>