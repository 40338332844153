<template>
    <v-container>
        <v-dialog v-model="showDialog">
            <v-card max-width="450" class="pa-2">
                <v-row>
                    <v-col cols="12">
                        <v-card-subtitle>👋 Klaar om je boek te delen met de wereld? Geweldig! 
                            Voordat je je boek uploadt, hebben we nog wat basisgegevens van je nodig, 
                            zoals je adres en telefoonnummer. Deze informatie helpt ons om alles soepel 
                            te laten verlopen. Vul ze in 
                            en je boek is binnen no-time beschikbaar voor verkoop. Laten we beginnen!
                        </v-card-subtitle> 
                         <v-card-actions>
                             <v-btn id="noAddressToUploadMyBookDialogId" color="primary" @click="showDialog = false">
                                 SLUITEN
                             </v-btn>
                         </v-card-actions>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <v-card flat v-if="(!hasValidAddress() && !addressSaved) || !finished" class="mx-8 mt-4">
            <v-row>
                <v-col class="pl-0 pb-0 mb-0" cols="12">
                    <StudentAddressComponent @onAddressSavedEvent="onAddressSaved()"
                    @onSurnameSavedEvent="onSurnameSavedEvent()"
                    @onSurnameFailedEvent="onSurnameFailedEvent()"
                    @onNameSavedEvent="onNameSavedEvent()"
                    @onNameFailedEvent="onNameFailedEvent()"></StudentAddressComponent>
                </v-col>
                <v-col class="pl-0 pt-0 mt-0">
                    <StudentStudyComponent @terug="terug()" @onSaveStudyClickedEvent="onSaveStudyClicked()"></StudentStudyComponent>
                </v-col>
            </v-row>
            <v-row class="my-0">
                <v-col class="ml-4">
                    <v-btn id="uploadMyBookFinishedSettingsId" color="primary" @click="finishedInsertingAddress()" :disabled="!isControlValid()">
                        KLAAR
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>
        <v-card flat v-if="hasValidAddress() && finished">
            <v-responsive :aspect-ratio="16/9">
                <v-stepper v-model="step">
                    <v-stepper-items>
                        <v-responsive :aspect-ratio="16/9">
                            <v-stepper-content step="1" class="px-0 px-md-4">
                                <FindMyBook @publishedBookIsFound="foundPublishedBook"></FindMyBook>
                            </v-stepper-content>
                            <v-stepper-content step="2" class="px-0 px-md-4">
                                <MyBookSettings @bookUploaded="bookUploaded" @cancel="cancelBookDetails" :publishedBook="publishedBook"></MyBookSettings>
                            </v-stepper-content>
                        </v-responsive>
                    </v-stepper-items>
                </v-stepper>
            </v-responsive>
        </v-card>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import FindMyBook from './books/FindMyBook.vue'
import MyBookSettings from './books/MyBookSettings.vue'
import StudentAddressComponent from "../components/StudentAddressComponent.vue"
import StudentStudyComponent from "../components/StudentStudyComponent.vue"

export default {
    data () {
        return {
            //book:{},
            publishedBook:null,
            step:1,
            showAddressDialogComponent: false,
            stepMissingAddress: 1,
            finished: false,
            addressSaved: false,
            showDialog: true,
            surnameSuccessful: false,
            nameSuccessful: false
        }  
    },
    
    created(){
        this.finished = this.hasValidAddress() 
        && this.getStudent().firstName != null 
        && this.getStudent().firstName != '' 
        && this.getStudent().lastName != null
        && this.getStudent().lastName != '';

        this.showDialog = !this.hasValidAddress();
    },

    methods: {
        ...mapGetters(['hasValidAddress']),
        ...mapGetters(['getStudent']),

        isControlValid(){
            return  (this.surnameSuccessful
            && this.nameSuccessful
            && this.addressSaved);
        },

        onNameSavedEvent(){
            this.nameSuccessful = true;
        },

        onNameFailedEvent(){
            this.nameSuccessful = false;
        },

        onSurnameSavedEvent(){
            this.surnameSuccessful = true;
        },

        onSurnameFailedEvent(){
            this.surnameSuccessful = false;
        },

        onAddressSaved(){
            this.addressSaved = true;
        },

        finishedInsertingAddress(){
            this.finished = true;
        },

        onSaveStudyClicked(){
            this.showAddressDialogComponent = false;
        },

        next(){
            this.stepMissingAddress = 2;
        },

        terug(){
            this.stepMissingAddress = 1;
        },

        foundPublishedBook(event){
            this.publishedBook = event.publishedBook;
            this.step = 2;
        },
        
        async created(){
            this.loadItemsFromCart();
            this.handleStudyAvailable();
            this.showAddressDialogComponent = this.shouldShowAddressDialog();
        },

        shouldShowAddressDialog(){
            return !(this.getStudent().postalAddress != null &&
                   this.getStudent().postalAddress != null && 
                   this.getStudent().postalAddress.street != null && 
                   this.getStudent().postalAddress.street.length > 0 &&
                   this.getStudent().residentialAddress != null && 
                   this.getStudent().residentialAddress.street && 
                   this.getStudent().residentialAddress.street.length > 0);

        },

        cancelBookDetails(){
            this.step = 1;
        },

        bookUploaded(){
            this.step = 1;
            this.$emit('bookUploaded');
        }
    },

    components: {
        FindMyBook, 
        MyBookSettings,
        StudentAddressComponent,
        StudentStudyComponent
    }
}
</script>