<template>
    <v-row align="center" justify="center" class="mt-8">
        <v-col xs="12" md="8" sm="12" lg="6">
            <v-card class="pa-md-4 mb-4">
                <h1 class="display-1 font-weight-bold pa-4 text-center">
                    <div class="title-bookshare primary--text">Say hello to us</div>
                </h1>
                <v-card flat v-if="resultMessage" class="py-4" align="center" justify="center">
                    <v-row class="ma-0 pa-0 pt-5" justify="center" align="center">
                        <v-card-text class="ma-2">{{resultMessage}}</v-card-text>
                        <v-card-text>Terug naar <router-link class="mx-1" to="/">Home</router-link></v-card-text>
                    </v-row>
                </v-card>
                <div v-if="!resultMessage">
                    <v-row class="ma-0 pa-0 pt-5"> 
                        <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="text-h2 ma-0 pb-0 pt-1">
                            <v-card-text class="picture mt-0 py-0">
                                <v-text-field 
                                    v-model="firstName"
                                    label="Voornaam"
                                    outlined>
                                </v-text-field>
                            </v-card-text>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="text-h2 ma-0 pb-0 pt-1">
                            <v-card-text class="picture mt-0 py-0">
                                <v-text-field 
                                    v-model="lastName"
                                    label="Achternaam"
                                    outlined>
                                </v-text-field>
                            </v-card-text>
                        </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0">
                        <v-col class="text-h2 py-0" >
                        <v-card-text class="picture mt-0 py-0">
                                <v-text-field 
                                    v-model="email"
                                    label="E-mail"
                                    outlined>
                                </v-text-field>
                            </v-card-text>
                        </v-col>
                    </v-row>
                    <v-row class="ma-0">
                        <v-col class="text-h2 mx-4 py-0" >
                            <v-textarea
                                outlined
                                v-model="message"
                                name="input-7-4"
                                label="Uw beschrijving">
                            </v-textarea>
                        </v-col>
                    </v-row>
                    <v-row align="right" justify="right">
                        <v-col class="text-h2 text-right mx-4 py-0" >
                            <v-btn 
                            color="primary"
                            :loading="loading"
                            @click="sendContactForm()"
                            class="mb-8 mr-4">VERSTUREN</v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
            <h1 class="display-1 font-weight-bold pa-4">
                <div class="title-bookshare primary--text text-center">Get in touch</div>
            </h1>
            <v-card align="center" justify="center" flat color="transparent">
                <v-row>
                    <v-col cols="12" md="4" class="justify-center">
                        <v-img src="../assets/ico/address.svg" class="ma-md-6" max-width="30"></v-img>
                        <h4>Adress:</h4>
                        <h6>Hesselink van suchtelenweg 6</h6>
                        <h6>Wageningen</h6>
                    </v-col>
                    <v-col cols="12" md="4" class="justify-center">
                        <v-img src="../assets/ico/Phone.svg" class="ma-md-6" max-width="45"></v-img>
                        <h4>Whatsapp:</h4>
                        <h6>+31 6 44290322</h6>
                    </v-col>
                    <v-col cols="12" md="4" class="justify-center email-info">
                        <v-img src="../assets/ico/msg.svg" class="ma-md-6" max-width="45"></v-img>
                        <h4>Email:</h4>
                        <h6>info@boekshare.nl</h6>
                        <h6>Account vragen:</h6>
                        <h6>support@boekshare.nl</h6>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>

import UtilitiesService from '../services/UtilityService';

export default {
    data () {
      return {
          loading:false,
          resultMessage: null,
          email:'',
          firstName:'',
          lastName:'',
          message: '',
      }
    },
    methods:{
        async sendContactForm(){
            try{
                this.loading = true;
                this.resultMessage = null;
                await UtilitiesService.sendContactForm(this.firstName, this.lastName, this.email, this.message);

                this.resultMessage = 'Je bericht is succesvol verstuurd. Je ontvangt binnenkort een bevestigingsmail.';
            }
            catch{
                this.resultMessage = 'Er is een fout opgetreden.';
            }
            this.loading = false;
        }
    }
}
</script>

<style scoped>
.email-info {
    padding-top: 2% !important;
}
</style>