<template>
    <v-container>
        <v-row>
            <v-col cols="3" class="hidden-sm-and-down pt-0">
                <UserMenuCard></UserMenuCard>
            </v-col>
            <v-col md="9" cols="12" class="pl-0">
                <Conversations></Conversations>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
import UserMenuCard from '../components/UserMenuCard.vue'
import Conversations from '../components/Conversations.vue'

export default {
    components:{
        UserMenuCard,
        Conversations
    },
}
</script>