<template>
   <v-form>
        <v-container>
            <v-row>
                <v-col cols="3" class="hidden-md-and-down">
                    <UserMenuCard></UserMenuCard>   
                </v-col>
                <v-col>
                    <v-tabs center-active>
                        <v-tab ref="#booksoverview">Books Overview</v-tab>
                        <v-tab ref="#topviewedbooks">Top Viewed Books</v-tab>
                        <v-tab ref="#topsold">Top Sold Books</v-tab>
                        <v-tab ref="#users">Users</v-tab>
                        <v-tab-item key="booksoverview">
                            <BooksOverviewComponent></BooksOverviewComponent>
                        </v-tab-item>
                        <v-tab-item key="topviewedbooks">
                            <MostViewedPublishedBook></MostViewedPublishedBook>
                        </v-tab-item>
                        <v-tab-item key="topsold">
                            <TopSoldBooks></TopSoldBooks>
                        </v-tab-item>
                        <v-tab-item key="users">
                            <StudentStatistics></StudentStatistics>
                        </v-tab-item>
                    </v-tabs>
                </v-col>
            </v-row>
        </v-container>
   </v-form>
</template>

<script>
import UserMenuCard from '../../../components/UserMenuCard.vue';
import BooksOverviewComponent from '../../../components/admin/BooksOverview.vue';
import TopSoldBooks from '../../../components/admin/TopSoldBooks.vue'
import StudentStatistics from '../../../components/admin/StudentStatistics.vue'
import MostViewedPublishedBook from '../../../components/admin/MostViewedPublishedBook.vue'

export default {

    data () {
      return {
      }
    },
       
    components:{
        UserMenuCard,
        BooksOverviewComponent,
        TopSoldBooks,
        StudentStatistics,
        MostViewedPublishedBook
    },
}
</script>